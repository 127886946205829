import { Box, Button, Card, Chip, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";
import { routes } from "src/data/Routes";
import { MARKETPLACES, REPORT_TYPES } from "./Data";
import ISPReportTable from "../ISPReportsTable";
import { ISPREPORTSTYLE } from "../ISPReportStyle";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { callBrassAPI, fetchISPReports } from "../ISPReportHelper";
import { ERROR_MESSAGE, MARKETPLACE_ID, MERCHANT_ID, MONTH, MTR_REQUEST, REPORT, YEAR, ISPREPORTS } from "../ISPReportsConstants";
import { encryptMerchantId } from "src/helpers/MerchantIdsHelper";
import { isLoadingAction } from "src/views/HomeActions";
import { handleApiError } from "src/utils/CommonHelper";


const MTRReports = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const dispatch = useDispatch();

    const [merchantIds, setMerchantIds] = useState<Array<string>>([]);
    const [reportType, setReportType] = useState<Array<string>>([]);
    const [month, setMonth] = useState<string>("");
    const baseUrl = useSelector(
      (state: CombineReducerState) => state.navbar.baseUrl
    );
    const url = baseUrl + routes.isp.reports.mtr;
    const {isLoading, brassResponse} = useSelector(
      (state: CombineReducerState) => state.home
    );
    const [mtrPayload, setMtrPayload] =
    useState(MTR_REQUEST);
    const [reports, setReports] = useState<Array<Object>>([]);
    const [responseLoaded, setResponseLoaded] = useState<boolean>(false);
    const [apiError, setApiError] = useState<boolean>(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<String>("");

    useEffect(() => {
      setResponseLoaded(true);
    }, [reports]);

    useEffect(() => {
      const encryptIds: Array<string> = merchantIds.map((id: any) => {
        let encryptedId = isNaN(id);
        return encryptedId ? id : encryptMerchantId(id);
      });

      setMtrPayload({
      ...mtrPayload,
      [MERCHANT_ID]: encryptIds.join(","),
      [REPORT]: reportType.join(","),
      [MONTH]:month,
    });
  }, [merchantIds, reportType, month]);

  const isInputEmpty = () => {
    return !Object.keys(mtrPayload).every((k) => mtrPayload[k]);
  };

    const handleReportTypeChange = (event: SelectChangeEvent<typeof reportType>) => {
        const { target: { value } } = event;
        setReportType(typeof value === 'string' ? value.split(',') : value);
    };

    const handleMonthChange = (event: SelectChangeEvent) => {
        setMonth(event.target.value);
    };

    
    const clearErrors = () => {
      setApiError(false);
      setApiErrorMessage("");
    };

    const renderReports = (report: any) => {
        return (          
          <Grid>
            <Card sx={{ marginTop: "15px", marginBottom: "15px" }}>
              <div style={ISPREPORTSTYLE.bgColor}>
                <div style={ISPREPORTSTYLE.reportsContainer}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Typography>Reference Id - {report.referenceId}</Typography>
                    </Box>
                    <Box>
                      <Typography>Document Id - {report.documentId}</Typography>
                    </Box>
                  </Grid>
                  <div style={ISPREPORTSTYLE.downloadButton}>
                    <a
                      href={report.urlForDownload}
                      target="_blank"
                      className="btn btn-link btn-lg"
                    >
                      DOWNLOAD
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        );
      
    };
    
    const handleSubmit = async() => {
      dispatch(isLoadingAction(true));
      clearErrors();

      let currentBrassResponse;
      if (!brassResponse.isBrassAuthorized) {
          currentBrassResponse = await callBrassAPI(baseUrl, dispatch);
      }
      if (brassResponse.isBrassAuthorized || currentBrassResponse.payload.isBrassAuthorized) {
          try {
              const data = await fetchISPReports({ISPReportPayload: mtrPayload, reportType, url});
              if (data.message) {
                  throw new Error(ERROR_MESSAGE.ServerError);
              } else {
                  setApiError(false);
                  setReports(data);
              }
          } catch (err : any) {
              handleApiError(err.message, setApiError, setApiErrorMessage);
          } finally {
              dispatch(isLoadingAction(false));
          }
      } else {
          handleApiError(brassResponse.error, setApiError, setApiErrorMessage, dispatch, isLoadingAction);
      }
  };
  
    return (
        <Grid container maxWidth="xl" sx={{ padding: 0 }}>
            <PageTitle title="MTR Reports" />

            <Grid item sm={4}>
                <Paper elevation={4} sx={{ paddingY: 2 }}>
                     <MerchantIdsInput
                        label="Merchant IDs"
                        inputId="merchantId"
                        inputPlaceholder="Comma / Line separated by Merchant Ids"
                        rows="10"
                        value={merchantIds}
                        handler={setMerchantIds}
                    />
                    <Box sx={{ paddingX: 3, paddingY: 1 }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="marketplace">Marketplace</InputLabel>
                            <Select
                                labelId="marketplace"
                                label="Marketplace"
                                name="marketplaceId"
                                value={mtrPayload?.marketplaceId}
                                sx={{ textAlign: "left" }}
                                onChange={(e) =>
                                setMtrPayload({
                                 ...mtrPayload,
                                [MARKETPLACE_ID]: e.target.value,
                                 })
                                 }
                                  >
                                {MARKETPLACES.map(marketplace => (
                                    <MenuItem key={marketplace} value={marketplace}>{marketplace}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingX: 3, paddingY: 1 }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="report-type">Report Type</InputLabel>
                            <Select
                                labelId="report-type"
                                label="Report Type"
                                sx={{ textAlign: "left" }}
                                value={reportType}
                                renderValue={(selected) => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selected.map((value) => (<Chip key={value} label={value} />))}
                                    </Box>
                                )}
                                onChange={handleReportTypeChange}
                                multiple
                            >
                                {REPORT_TYPES.map(reportType => (
                                    <MenuItem key={reportType} value={reportType}>{reportType}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingX: 3, paddingY: 1 }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="month">Month</InputLabel>
                            <Select
                                labelId="month"
                                label="Month"
                                value={month}
                                onChange={handleMonthChange}
                                sx={{ textAlign: "left" }}>
                                {ISPREPORTS.MONTH.map((month) => (
                  <MenuItem key={month.id} value={month.id}>
                    {month.name}
                  </MenuItem>
                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingX: 3, paddingY: 1 }}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="year">Year</InputLabel>
                            <Select
                                labelId="year"
                                label="year"
                                value={mtrPayload.year}
                                onChange={(e) =>
                                  setMtrPayload({
                                    ...mtrPayload,
                                    [YEAR]: e.target.value,
                                  })
                                }
                                sx={{ textAlign: "left" }}>
                                {ISPREPORTS.YEAR.map((year) => (
                  <MenuItem key={year.id} value={year.id}>
                    {year.name}
                  </MenuItem>
                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingX: 3, paddingY: 1 }}>
                        <Button 
                        variant="contained" 
                        size="small"
                        disabled={isInputEmpty() || isLoading}
                        onClick={handleSubmit} 
                        fullWidth>Submit</Button>
                    </Box>
                    {isLoading && <LinearProgress />}
                </Paper>
            </Grid>
            <Grid item sm={8}>
            <ISPReportTable
              type="mtr"
              renderReports={renderReports}
              reports={reports}
              apiError={apiError}
              apiErrorMessage={apiErrorMessage}
              responseLoaded={responseLoaded}
        />
            </Grid>
        </Grid>
    )
}

export default MTRReports;