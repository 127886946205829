import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import UserPhoto from '../../assets/pikachu.png';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {stageAction, isDarkThemeAction, fetchBaseUrlAction} from "src/components/navbar/NavbarActions";
import { styled, alpha, lighten, darken } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MenuItem, menuItems } from 'src/data/MenuItems';
import { isSideBarActiveAction, selectedMenuItemAction } from '../sidebar/SidebarActions';
import { CombineReducerState } from 'src/combineReducers';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from 'src/data/Routes';
import { Cookie } from 'src/constants/Cookie';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const flattenMenuItems = (menuItems: Record<string, Array<MenuItem>>) => {
    let res: Array<MenuItem> = [];
    Object.keys(menuItems).map(item => res = res.concat(menuItems[item]));
    return res;
}

let navbarMenuItems = flattenMenuItems(menuItems);

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuFontSize = "14px";
    const isDarkTheme = useSelector((state: CombineReducerState) => state.navbar.isDarkTheme);
    const stage = useSelector((state: CombineReducerState) => state.navbar.stage);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
	const isSidebarActive = useSelector((state: CombineReducerState) => state.sidebar.isSidebarActive);

    const routeTo = (menu: MenuItem | null) => {
        if (menu != null) {
            dispatch(selectedMenuItemAction(menu.to));
            navigate(menu.to);
        };
    }

    const handleToggler = (
        event: React.MouseEvent<HTMLElement>,
        stageName: string,
    ) => {
        dispatch(stageAction(stageName));
        dispatch(fetchBaseUrlAction(baseUrl));
        window.location.href = `https://voldemort.${stageName}.global-selling.xp.sellers.a2z.com/`;
    };

    return (
        <AppBar color="inherit" position="static" sx={{ mb: 3 }}>
            <Toolbar>
                <IconButton color="inherit" aria-label="menu" onClick={() => dispatch(isSideBarActiveAction(!isSidebarActive))}>
                    <MenuIcon />
                </IconButton>
                <Box component={ NavLink } style={{ color: "inherit", textDecoration: "none" }} to={routes.index} onClick={() => dispatch(selectedMenuItemAction(routes.index))}>
                    <Typography variant="h6" sx={{ ml: 2 }}>VOLDEMORT</Typography>
                </Box>
                <Typography variant="h5" component="h5" sx={{ flexGrow: 1 }}>
                    {/* Header */}
                </Typography>
                <Search sx={{ mx: 2 }}>
                    <Autocomplete 
                        size="small"
                        id="menu-suggestions"
                        options={navbarMenuItems}
                        groupBy={(menuItem) => menuItem.group}
                        onChange={(_, menu) => routeTo(menu)}
                        getOptionLabel={(menuItem) => menuItem.title}
                        getOptionDisabled={(menuItem) => menuItem.disabled}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Search Menu" />}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems sx={{fontSize: menuFontSize}}>{params.children}</GroupItems>
                            </li>
                        )}
                    />
                </Search>
                <IconButton color="inherit" onClick={() => {
                    dispatch(isDarkThemeAction(!isDarkTheme));
                    localStorage.setItem(Cookie.THEME, !isDarkTheme ? "dark" : "light");
                }}>
                    {isDarkTheme ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
                </IconButton>
                <ToggleButtonGroup color="primary" value="stage" exclusive aria-label="Stage" size="small" sx={{ mx: 2 }}>
                    <ToggleButton value="beta" onClick={handleToggler} selected={stage === "beta"}>Beta</ToggleButton>
                    <ToggleButton value="prod" onClick={handleToggler} selected={stage === "prod"}>Prod</ToggleButton>
                </ToggleButtonGroup>
                <Avatar alt="User Photo" src={UserPhoto} />
            </Toolbar>
        </AppBar>
    )
}

export default Navbar;