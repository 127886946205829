import { HomeCard } from "src/components/collection/CardListProps";
import { homeCards } from "src/data/CardData";
import { BRASS_RESPONSE, CARD_DATA, HomeActions, IS_LOADING, ISP_DETAILS } from "./HomeActions";
import { HomeState } from "./HomeState";
import { ISP_DETAILS_INTERFACE } from "src/constants/ISPOnboarding";

const commonInitialState = {
    getInitialBrassAuthDataState: { isBrassAuthorized: false, error: null },
}
const initialISPDetailsState: ISP_DETAILS_INTERFACE[] = [ { ispId: null, ispName: null, gstins: [] } ];
const getInitialCardDataState = (homeCards: Array<HomeCard>) => {
    return Object.fromEntries(homeCards.map(card => [card.description, {data: [], isLoaded: false}]));
}


const initialState: HomeState = {
    isLoading: false,
    cardData: getInitialCardDataState(homeCards),
    brassResponse: commonInitialState.getInitialBrassAuthDataState,
    ispDetails: initialISPDetailsState
};

export const homeReducer = (state = initialState, action: HomeActions): HomeState => {
    switch (action.type) {
        case IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case CARD_DATA: {
            return {
                ...state,
                cardData: action.payload
            }
        }
        case BRASS_RESPONSE: {
            return {
                ...state,
                brassResponse: action.payload
            }
        }
        case ISP_DETAILS: {
            return {
                ...state,
                ispDetails: action.payload
            }
        }
        default:
            return state;
    }
};