import { GridColDef } from "@mui/x-data-grid";


export const CommercialInvoiceStatusTableColumns: GridColDef[] = [
    {
        field: "orderId",
        headerName: "Order ID",
        flex: 1,
    },
    {
        field: "marketplaceId",
        headerName: "Marketplace ID",
        flex: 1,
    },
    {
        field: "commercialInvoiceStatus",
        headerName: "Status",
        flex: 1,
    },
    {
        field: "generationStartTimestamp",
        headerName: "Generation Start Timestamp",
        flex: 1,
    },
    {
        field: "lastUpdatedTimestamp",
        headerName: "Updated Timestamp",
        flex: 1,
    },
];

export const OOCInvoiceStatus: GridColDef[] = [
    {
        field: "orderId",
        headerName: "Order Id",
        flex: 1,
    },
    {
        field: "shipmentId",
        headerName: "Shipment Id",
        flex: 1,
    },
    {
        field: "invoiceStatus",
        headerName: "Invoice Status",
        flex: 2,
    },
    {
        field: "marketplaceId",
        headerName: "Marketplace Id",
        flex: 1,
    },
    {
        field: "merchantId",
        headerName: "Merchant Id",
        flex: 1,
    },
    {
        field: "endToEndId",
        headerName: "End to End Id",
        flex: 1,
    },
    {
        field: "invoiceAmount",
        headerName: "Invoice Amount",
        flex: 1,
    },
    {
        field: "settlementId",
        headerName: "Settlement Id",
        flex: 1,
    },
    {
        field: "settlementGroupId",
        headerName: "SettlementGroup Id",
        flex: 1,
    },
    {
        field: "shipperId",
        headerName: "Shipper Id",
        flex: 1,
    },
    {
        field: "orderDate",
        headerName: "Order Date",
        flex: 1,
    },
    {
        field: "shipDate",
        headerName: "Ship Date",
        flex: 1,
    },
];
