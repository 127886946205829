import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  Typography,
  Alert,
  LinearProgress,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";
import { routes } from "src/data/Routes";
import apiRequest from "src/utils/ApiRequest";
import SellerData from "../SellerData";
import { isLoadingAction } from "src/views/HomeActions";
import { CombineReducerState } from "src/combineReducers";
import { useDispatch, useSelector } from "react-redux";
import {
  PARIS_FLAGVALUE,
  MARKETPLACE,
  MERCHANT_ID,
  SELLER_MARKETPLACE_FLAG_VALUE_MAP,
  MARKETPLACES,
  FLAGVALUE,
  MARKETPLACE_MAP,
  REALM_MAP
} from "../ParisFlagDataConstants";
import Collapsible from "src/components/shared/Collapsible";
import { encryptMerchantId } from "src/helpers/MerchantIdsHelper";
import { Container } from "@mui/system";
import ParisFlagActionsFAQ from "./ParisFlagActionFAQ";
import { createTableForIndividualSeller } from "./IndividualSellerData";

const GetSellerFlagDetails = (props: PageProps) => {
  const PAGE_TITLE = props.title;
  const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
  document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

  const [merchantIds, setMerchantIds] = useState<Array<string>>([]);
  const [marketplace, setMarketplace] = useState("");
  const [flagValue, setFlagValue] = useState("");
  const [isParamsLoaded, setIsParamsLoaded] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState({});
  const [modalData, setModalData] = useState({});
  const [url, setUrl] = useState("");

  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [responseLoaded, setResponseLoaded] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: CombineReducerState) => state.home.isLoading
  );

  useEffect(() => {
    const pMerchantIds = urlParams.get(MERCHANT_ID);
    setMerchantIds(pMerchantIds ? pMerchantIds.split(",") : [""]);
    setMarketplace(urlParams.get(MARKETPLACE) ?? "");
    setFlagValue(urlParams.get(PARIS_FLAGVALUE) ?? "");
    setIsParamsLoaded(true);
  }, []);

  useEffect(() => {
    setResponseLoaded(true);
  }, [data]);

  const isInputEmpty = () => {
    return merchantIds.length === 0 || marketplace === "" || flagValue === "";
  };

  const handleMarketplaceChange = (event: SelectChangeEvent) => {
    const countryCode = event.target.value;
    const realm = Object.keys(REALM_MAP).find(currentRealm => REALM_MAP[currentRealm].includes(countryCode)) || "";
    const regionUrl = (realm == "EU") ? "/seller/flags/details-eu/" : ((realm == "NA")
        ? "/seller/flags/details-na/": "/seller/flags/details-fe/");
    setUrl(baseUrl+regionUrl);
    console.log("inside handle marketplace",baseUrl+regionUrl);
    setMarketplace(countryCode);
  };

  const handleFlagValueChange = (event: SelectChangeEvent) => {
    setFlagValue(event.target.value);
  };

  const handleSubmit = async () => {
    clearErrors();
    window.history.pushState(
      null,
      "",
      `?${PARIS_FLAGVALUE}=${flagValue}&${MARKETPLACE}=${marketplace}&${MERCHANT_ID}=${merchantIds}`
    );

    dispatch(isLoadingAction(true));
    try {
      const encryptIds: Array<string> = merchantIds.map((id: any) => {
        let encryptedId = isNaN(id);
        return encryptedId ? id : encryptMerchantId(id);
      });

      let payload = {
        flagValue: flagValue,
        merchantIds: encryptIds.join(","),
        marketplace: marketplace,
      };
      const response = await apiRequest(url, payload);

      if (response.ok) {
        setApiError(false);
        const jsonData = await response.json();
        setData(jsonData[SELLER_MARKETPLACE_FLAG_VALUE_MAP]);
        let modaldata = createTableForIndividualSeller(
          jsonData[SELLER_MARKETPLACE_FLAG_VALUE_MAP],
          marketplace
        );
        setModalData(modaldata);
      } else {
        throw new Error("Error occured, please try again");
      }
    } catch (err: any) {
      setApiError(true);
      setApiErrorMessage(err.message);
    }
    dispatch(isLoadingAction(false));
  };

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <PageTitle title="Get Seller Flag Details" />
      <Grid container>
        <Grid item sm={4}>
          <Container maxWidth="sm">
            <Paper elevation={4} sx={{ paddingY: 2 }}>
              <Box sx={{ paddingX: 3, paddingY: 1 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="month">Flag</InputLabel>
                  <Select
                    labelId="flagValue"
                    label="Flag"
                    value={flagValue}
                    onChange={handleFlagValueChange}
                    sx={{ textAlign: "left" }}
                  >
                    {FLAGVALUE.map((flagValue) => (
                      <MenuItem key={flagValue} value={flagValue}>
                        {flagValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ paddingX: 3, paddingY: 1 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="marketplace">Marketplace</InputLabel>
                  <Select
                    labelId="marketplace"
                    label="Marketplace"
                    value={marketplace}
                    onChange={handleMarketplaceChange}
                    sx={{ textAlign: "left" }}
                  >
                    {MARKETPLACES.map((marketplace) => (
                      <MenuItem
                        key={marketplace}
                        value={MARKETPLACE_MAP[marketplace]}
                      >
                        {marketplace}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ paddingX: 0, paddingY: 1 }}>
                {isParamsLoaded && (
                  <MerchantIdsInput
                    label="Merchant IDs"
                    inputId="merchantId"
                    inputPlaceholder="Comma / Line separated Merchant Ids"
                    rows="10"
                    value={merchantIds}
                    handler={setMerchantIds}
                  />
                )}
              </Box>
              <Box sx={{ paddingX: 3, paddingY: 1 }}>
                <Button
                  variant="contained"
                  size="small"
                  disabled={isInputEmpty() || isLoading}
                  onClick={handleSubmit}
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
              {isLoading && <LinearProgress />}
            </Paper>
          </Container>
        </Grid>
        <Grid item sm={8}>
          <Collapsible title="Tool Description and Usage" open={false}>
            <ParisFlagActionsFAQ forFlagUpdate={false} />
          </Collapsible>

          <Box style={{ width: "100%", marginTop: "16px" }}>
            <TableContainer component={Paper} sx={{ marginBottom: "16px" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={100}>
                      <Typography variant="h5">Seller Details</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Seller ID</TableCell>
                    <TableCell>Operation Status</TableCell>
                    <TableCell>Paris Flag Value</TableCell>
                    <TableCell>Flag Details</TableCell>
                  </TableRow>
                </TableHead>
                {!apiError && responseLoaded && (
                  <SellerData
                    data={data}
                    modalData={modalData}
                    marketplace={marketplace}
                    flagValue={flagValue}
                  ></SellerData>
                )}
              </Table>
            </TableContainer>
            {apiError && <Alert severity="error">{apiErrorMessage}</Alert>}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GetSellerFlagDetails;