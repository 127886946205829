import React from "react"

import { Accordion, AccordionDetails, AccordionSummary, CardContent, CardHeader } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const AccordianTitle = (props: AccordianTitle) => {
    return (
        <Accordion elevation={props.elevation!} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header" sx={{ '.MuiAccordionSummary-content': { justifyContent: 'center' } }}>
                <CardHeader title={props.title} subheader={props.subTitle!} />
            </AccordionSummary>
            <AccordionDetails>
                <CardContent>
                    {props.cardContent}
                </CardContent>
            </AccordionDetails>
        </Accordion>
    )
}


interface AccordianTitle {
    title: string,
    subTitle?: string,
    cardContent: string,
    elevation?: number
}

export default AccordianTitle;