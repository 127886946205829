import React, {useEffect, useState} from "react"
	 
import { Grid, Card, CardContent, CardHeader, MenuItem, Select, Switch } from "@mui/material";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { CombineReducerState } from "src/combineReducers";
import { useSelector } from "react-redux";
import { routes } from "src/data/Routes";

import { decryptMerchantId } from "src/helpers/MerchantIdsHelper";
import SellerRegistrationGraph from "src/components/charts/SellerRegistrationLineChart";
import AccordianTitle from "src/components/display/AccordianTitle";
import MetricCard from "src/components/display/MetricCard";
import DateRangeForm from "src/components/forms/DateRangeForm";
import { DEFAULT_FORM_RESPONSE } from "src/constants/FormResponse";
import {
    DOMESTIC_MARKETPLACE_SUPPORTED_COUNTRY, INGRESS_MARKETPLACE_TO_COUNTRY_BETA,
    INGRESS_MARKETPLACE_TO_COUNTRY_PROD,
    JITA_SUPPORT_COUNTRY_MAP
} from "src/constants/JITAConstant";

export type GlobalSellerRegistrationMetrics = {
    sellerId: string,
    businessLocation: string,
    eventDate: string,
    treatmentNumber: string
}

const GlobalSellerRegistrationJitaMetrics = (props: PageProps) => {

    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

    const csvFileName: string = "sellerCids.csv";
    const bannerContent: string = "In this dashboard, users can easily identity number of sellers registering on Orbis for global selling and are experiencing JITA(Just in Time Assistance)";

    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    let [response, setResponse] = useState(DEFAULT_FORM_RESPONSE);
    let [metricData, setMetricData] = useState(DEFAULT_FORM_RESPONSE);
    let [businessLocation, setBusinessLocation] = useState('IN');
    let [dayDifference, setDayDifference] = useState<number>();
    let [isDomestic, setIsDomestic] = useState(false);



    const downloadCustomersAsCSV = (response: Array<GlobalSellerRegistrationMetrics>) => {
        const csvData = response.map(({ sellerId }) => decryptMerchantId(sellerId)).join("\n");
        const csvBlob = new Blob([csvData], { type: "text/csv" });

        const csvURL = URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.href = csvURL;
        a.download = csvFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        handleDomesticToggleChange(isDomestic)
    },[response])

    const handleDomesticToggleChange = (flag: boolean) => {
        if(flag) {
            const ingressMarketplaceID: string = window.location.href.includes("prod")? INGRESS_MARKETPLACE_TO_COUNTRY_PROD[businessLocation]
                : INGRESS_MARKETPLACE_TO_COUNTRY_BETA[businessLocation];
            let updatedDataObject = response.data.filter((item: any) => item.ingressMarketplaceId && item.ingressMarketplaceId === ingressMarketplaceID)
            console.log(updatedDataObject)
            setMetricData({...response, data: updatedDataObject})
        } else {
            setMetricData(response);
        }
    }





    return (
        <Grid container>
            <Grid container justifyContent={"space-between"} marginBottom={4}>
            <Grid xs={8.5} >
                <AccordianTitle title={"Global Seller Registration Metrics"} subTitle={"seller experiencing JITA"} cardContent={bannerContent} elevation={3} />
            </Grid>
                <Grid xs={3}>
                    <Card sx={{ p: 3 }} elevation={3} >
                    <CardHeader subheader={"Business Location"} sx={{ p: 0}}/>
                        <Select defaultValue={businessLocation} sx={{minWidth: 120 }} onChange={event => setBusinessLocation(event.target.value)}>
                            {Object.keys(JITA_SUPPORT_COUNTRY_MAP).map((key: string) => (
                                <MenuItem value={key}> {JITA_SUPPORT_COUNTRY_MAP[key]}
                                </MenuItem>
                            ))}
                        </Select>
                        {businessLocation && DOMESTIC_MARKETPLACE_SUPPORTED_COUNTRY.includes(businessLocation)?
                        <div >
                            Show Domestic
                            <Switch onChange={event => {
                                setIsDomestic(event.target.checked)
                                handleDomesticToggleChange(event.target.checked)
                            }}
                            />
                        </div> : null}
                    </Card>
                </Grid>
            </Grid>

            <Grid container justifyContent={"space-between"} alignItems={"center"} marginBottom={4} >
                <Grid xs={5}>
                    <DateRangeForm handler={setResponse}
                        url={baseUrl + routes.sellerRegistration.globalRegistrationMetrics}
                        businessLocation={businessLocation} setLastWeekDate={true} elevation={2}
                        dayDifference={setDayDifference}
                    />
                </Grid>
                <Grid xs={3}>
                    <MetricCard title={"Daily Unique Sellers"}
                        subTitle={"Average Registration"}
                        metric={metricData && dayDifference ? metricData.data.length / dayDifference : 0} animationDuration={2}
                    />
                </Grid>

                <Grid xs={3}>
                    <MetricCard title={"Total Unique Sellers"}
                        subTitle={"Registration"}
                        metric={metricData ? metricData.data.length : 0} animationDuration={2}
                        downloadableFunction={() => downloadCustomersAsCSV(metricData.data as Array<GlobalSellerRegistrationMetrics>)}
                        downloadMsg={"Download CIDs"}
                    />
                </Grid>
            </Grid>
            <Grid xs={12} marginBottom={2}>
                <Card sx={{ p: 2 }} elevation={3} >
                    <CardHeader title={"Registration Trend"} />
                    <CardContent style={{ width: "100%", height: "500px" }}>
                        <SellerRegistrationGraph data={metricData.data as Array<GlobalSellerRegistrationMetrics>} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default GlobalSellerRegistrationJitaMetrics