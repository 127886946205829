import { getCognitoConfig } from "src/config/Cognito";
import { Cookie } from "src/constants/Cookie";
import { cookie } from "src/utils/Cookie";
import { getRandomString } from "src/utils/StringUtil";
import { CookieSetOptions } from "universal-cookie";

let headers = new Headers();
headers.set("Cache-Control", "no-store");
const urlParams = new URLSearchParams(window.location.search);
const stage = window.location.hostname.includes("beta") || window.location.hostname.includes("localhost") ? "beta" : "prod";
const cognitoConfig = getCognitoConfig(stage);

const setCookie = (token: string) => {
    const expiresInMin = Cookie.EXPIRATION_TIME;
    let date = new Date();
    date.setTime(date.getTime() + expiresInMin * 60 * 1000);
    const options: CookieSetOptions = { path: "/", expires: date, sameSite: "lax", secure: true };
    cookie.set(Cookie.TOKEN, token, options);
};

export const authorizeUser = async () => {
    let code = urlParams.get("code");
    let state = urlParams.get("state");
    if (!code || !state) {
        // PKCE
        state = getRandomString();
        sessionStorage.setItem(Cookie.PKCE_STATE, state);
        // Code Verifier
        const code_verifier = getRandomString();
        sessionStorage.setItem(Cookie.CODE_VERIFIER, code_verifier);

        // Authorize endpoint - https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html#get-authorize
        const params = new URLSearchParams({
            client_id: cognitoConfig.CLIENT_ID,
            response_type: "code",
            state: state,
            scope: "email openid profile",
            redirect_uri: cognitoConfig.REDIRECT_URI,
        });
        window.location.href = cognitoConfig.AUTH_URL + "?" + params.toString();
    }
    else {
        if (sessionStorage.getItem(Cookie.PKCE_STATE) !== state) {
            console.warn("Invalid state!");
            cookie.remove(Cookie.TOKEN);
        }
        else {
            const existingIdToken = cookie.get(Cookie.TOKEN);
            if (!existingIdToken) {
                const stored_code_verifier = sessionStorage.getItem(Cookie.CODE_VERIFIER) || "";
                // Token endpoint - https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html#post-token
                const response = await fetch(cognitoConfig.TOKEN_URL, {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams({
                        grant_type: "authorization_code",
                        client_id: cognitoConfig.CLIENT_ID,
                        redirect_uri: cognitoConfig.REDIRECT_URI,
                        code,
                        stored_code_verifier,
                    })
                });
                const body = await response.json();
                const id_token = body.id_token;
                if (id_token) {
                    setCookie(id_token);
                    return id_token;
                }
            }
        }
    }
}
