import {GridColDef} from "@mui/x-data-grid";

export const AllowlistGSTINColumns: GridColDef[] = [
    {
        field: 'id_',
        headerName: 'ID',
        flex: 1,
        sortable: false
    },
    {
        field: 'id',
        headerName: 'GSTIN',
        flex: 3,
        editable: false,
        sortable: false
    }
];