import { Typography } from "@mui/material";
import React from "react";
import { PARIS_FLAG_DETAILS } from "../ParisFlagDataConstants";
const ParisFlagActionsFAQ = (props:ParisFlagActionsFAQ) => {
  const forFlagUpdate = props.forFlagUpdate;
  return (
    <div
      style={{
        justifyContent: "left",
        alignItems: "flex-start",
        textAlign: "left",
      }}
    >
      <Typography variant="h6" gutterBottom>
        <b> What does each attribute mean?</b>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <b>PARIS Flag:</b> <br /> PARIS refers to the Party And Role Identity
        Service. It pertains to storing basic global attributes and
        identification information related to sellers and marketplaces. For
        staging and unstaging, we only use the "inStaging" PARIS flag attribute.
        <br />
        {forFlagUpdate && (
          <div>
            <b>Reason:</b> <br /> The reason code for which the staging or
            unstaging has to happen. If a seller has to be temporarily blocked
            due to his pending SIV approval, we use SIV_UNAPPROVED. If the same
            staged seller has to be unstaged / re-activated due to approval of
            his SIV, we use the same code again, for the unstage process.
            <br />
            <b>Flag Value:</b> <br /> In case when parisFlag='inStaging', the
            available values are true and false. True is used while staging and
            False while un-staging.
            <br />
          </div>
        )}
        <b>Marketplace:</b> <br /> marketplace Id
        <br />
        {forFlagUpdate && (
          <div>
            <b>Comments:</b> <br /> user must enter why he/she is updating the
            PARIS flag. If possible, any related URLs can be attached.
            <br />
          </div>
        )}
        <b>Seller IDs:</b> <br /> list of merchant Ids. They can be in encrypted
        format or in numeric format. They can be separated by new lines or by
        commas(,).
      </Typography>

      <Typography variant="h6" gutterBottom>
        <b>Usage of Tool</b>
      </Typography>
      <div>
        <video
          controls
          id="broadcast-video_html5_api"
          poster="https://drive-render.corp.amazon.com/view/GSX/Voldemort/thumbnail.png"
          width="620"
        >
          <source
            src={
              "https://drive.corp.amazon.com/view/GSX/Voldemort/" +
              (forFlagUpdate ? "performFlagUpdates.mp4" : "getFlagValues.mp4")
            }
            type="video/mp4"
          />
        </video>
      </div>
      <text>
        More Information about the tool can be found here :{" "}
        <a href={PARIS_FLAG_DETAILS.HelpSectionURL}> Voldemort PARIS Flag Tool</a>
      </text>
    </div>
  );
};

interface ParisFlagActionsFAQ {
  forFlagUpdate:boolean
}

export default ParisFlagActionsFAQ;