import { Cookie } from "src/constants/Cookie";
import { cookie } from "./Cookie";

const apiRequest = async (url: string, params: Record<string, any> = {}) => {
    let urlParams = new URLSearchParams(params);
    let token = cookie.get(Cookie.TOKEN);
    const urlWithParams = `${url}?${urlParams}`;
    return await fetch(urlWithParams, { headers: { "Authorization": token } });
}

export default apiRequest;