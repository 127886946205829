import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";
import { MARKETPLACES } from "../mtr/Data";
import {
  DATEFORMAT,
  END_DATE,
  EPOCH_DIFFERENCE,
  ERROR_MESSAGE,
  GENERATE_INVENTORY_SHIPMENT_REQUEST,
  GENERATE_REPORTS_TYPE,
  ISPReportDataType,
  MARKETPLACE_ID,
  MERCHANT_ID,
  REPORT,
  START_DATE,
} from "../ISPReportsConstants";
import { encryptMerchantId } from "src/helpers/MerchantIdsHelper";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { routes } from "src/data/Routes";
import { isLoadingAction } from "src/views/HomeActions";
import { callBrassAPI, fetchISPReports } from "../ISPReportHelper";
import ReportGenerationStatus from "src/components/shared/ReportGenerationStatus";
import ISPReportTable from "../ISPReportsTable";
import { ISPREPORTSTYLE } from "../ISPReportStyle";
import { handleApiError } from "src/utils/CommonHelper";

let formattedStartDate = "";
let formattedEndDate = "";

const GenerateInventoryShipmentReports = (props: PageProps) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

  const dispatch = useDispatch();
  const {isLoading, brassResponse} = useSelector(
    (state: CombineReducerState) => state.home
  );
  const baseUrl = useSelector(
    (state: CombineReducerState) => state.navbar.baseUrl
  );
  const url = baseUrl + routes.isp.reports.generateInventoryShipment;

  const [generateInventoryPayload, setgenerateInventoryPayload] = useState(
    GENERATE_INVENTORY_SHIPMENT_REQUEST
  );
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [merchantIds, setMerchantIds] = useState<Array<string>>([]);
  const [reportType, setReportType] = useState<Array<string>>([]);
  const [reports, setReports] = useState<Array<Object>>([]);
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<String>("");
  const [responseLoaded, setResponseLoaded] = useState<boolean>(false);

  useEffect(() => {
    setResponseLoaded(true);
  }, [reports]);


  useEffect(() => {
    const encryptIds: Array<string> = merchantIds.map((id: any) => {
      let encryptedId = isNaN(id);
      return encryptedId ? id : encryptMerchantId(id);
    });
    setgenerateInventoryPayload({
      ...generateInventoryPayload,
      [MERCHANT_ID]: encryptIds.join(","),
      [REPORT]: reportType.join(""),
    });
  }, [merchantIds, reportType]);

  const isInputEmpty = () => {
    return !Object.keys(generateInventoryPayload).every(
      (k) => generateInventoryPayload[k]
    );
  };

  useEffect(() => {
    formattedStartDate = startDate?.format(DATEFORMAT) || "";
    formattedEndDate = endDate?.format(DATEFORMAT) || "";

    setgenerateInventoryPayload({
      ...generateInventoryPayload,
      [START_DATE]: (
        new Date(formattedStartDate).getTime() + EPOCH_DIFFERENCE
      ).toString(),
      [END_DATE]: (
        new Date(formattedEndDate).getTime() + EPOCH_DIFFERENCE
      ).toString(),
    });
  }, [startDate, endDate]);

  const handleReportTypeChange = (
    event: SelectChangeEvent<typeof reportType>
  ) => {
    const {
      target: { value },
    } = event;
    setReportType(typeof value === "string" ? value.split(",") : value);
  };

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  const renderReports = (data: ISPReportDataType) => {
    return (
      <Grid>
        <Card sx={{ marginTop: "15px", marginBottom: "15px" }}>
          <div style={ISPREPORTSTYLE.bgColor}>
            <div style={ISPREPORTSTYLE.reportsContainer}>
              <Box>
                <Typography>Reference Id - {data.referenceId}</Typography>
              </Box>
              <Box>
                <ReportGenerationStatus status={data.reportGenerationStatus} />
              </Box>
            </div>
            <div style={ISPREPORTSTYLE.bgColor}>
              <a
                href={`/isp/reports/inventory-shipment-report?referenceId=${data.referenceId}`}
                target="_blank"
                className="btn btn-link btn-lg"
              >
                Report Generation Status
              </a>
            </div>
            <div>
              <span>
                Report Generation has been initiated. Will take 5-10mins for
                report to be generated.
              </span>
              <br />
              <span>
                Email notification will be sent once report is generated.
              </span>
            </div>
          </div>
        </Card>
      </Grid>
    );
  };

  const handleSubmit = async() => {
    clearErrors();
    dispatch(isLoadingAction(true));

    let currentBrassResponse;
    if (!brassResponse.isBrassAuthorized) {
        currentBrassResponse = await callBrassAPI(baseUrl, dispatch);
    }
    if (brassResponse.isBrassAuthorized || currentBrassResponse.payload.isBrassAuthorized) {
        try {
            const data = await fetchISPReports({ISPReportPayload: generateInventoryPayload, reportType, url});
            if (data.message) {
                throw new Error(ERROR_MESSAGE.ServerError);
            } else {
                setApiError(false);
                setReports(data);
            }
        } catch (err : any) {
            handleApiError(err.message, setApiError, setApiErrorMessage);
        } finally {
            dispatch(isLoadingAction(false));
        }
    } else {
        handleApiError(brassResponse.error, setApiError, setApiErrorMessage, dispatch, isLoadingAction);
    }
};

  return (
    <Grid container maxWidth="xl" sx={{ padding: 0 }}>
      <PageTitle title="Generate Inventory Shipment Reports" />
      <Grid item sm={4}>
        <Paper elevation={4} sx={{ paddingY: 2 }}>
          <MerchantIdsInput
            label="Merchant IDs"
            inputId="merchantId"
            inputPlaceholder="Comma / Line separated Merchant Ids"
            rows="10"
            value={merchantIds}
            handler={setMerchantIds}
          />
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="marketplace">Marketplace</InputLabel>
              <Select
                labelId="marketplace"
                label="Marketplace"
                value={generateInventoryPayload.marketplaceId}
                onChange={(e) =>
                  setgenerateInventoryPayload({
                    ...generateInventoryPayload,
                    [MARKETPLACE_ID]: e.target.value,
                  })
                }
                sx={{ textAlign: "left" }}
              >
                {MARKETPLACES.map((marketplace) => (
                  <MenuItem key={marketplace} value={marketplace}>
                    {marketplace}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="report-type">Report Type</InputLabel>
              <Select
                labelId="report-type"
                label="Report Type"
                name="reportTypes"
                sx={{ textAlign: "left" }}
                value={reportType}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value: string) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                onChange={handleReportTypeChange}
                multiple
              >
                {GENERATE_REPORTS_TYPE.map((reportType) => (
                  <MenuItem key={reportType} value={reportType}>
                    {reportType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={isInputEmpty() || isLoading}
              onClick={handleSubmit}
              fullWidth
            >
              Submit
            </Button>
          </Box>
          {isLoading && <LinearProgress />}
        </Paper>
      </Grid>
      <Grid item sm={8}>
        <ISPReportTable
          type="generateInventory"
          renderReports={renderReports}
          reports={reports}
          apiError={apiError}
          apiErrorMessage={apiErrorMessage}
          responseLoaded={responseLoaded}
        />
      </Grid>
    </Grid>
  );
};

export default GenerateInventoryShipmentReports;
