export const JITA_SUPPORT_COUNTRY_MAP: { [key: string]: string }  = {
    'IN' : 'India',
    'VN' : 'Vietnam',
    'TW' : 'Taiwan',
    'BR' : 'Brazil',
    'MX' : 'Mexico',
    'SG' : 'Singapore',
    'KR' : 'Korea',
    'KH' : 'Cambodia',
    'PH' : 'Philippines',
    'TH' : 'Thailand',
    'ID' : 'Indonesia',
    'MY' : 'Malaysia',
}

export const DOMESTIC_MARKETPLACE_SUPPORTED_COUNTRY = ['MX', 'BR'];
export const INGRESS_MARKETPLACE_TO_COUNTRY_BETA: {[key: string]: string} = {
    'MX' : "A3P3J5A7D2ZVXI",
    'BR' : "AZXD3QD5B39HD"
}

export const INGRESS_MARKETPLACE_TO_COUNTRY_PROD: {[key: string]: string} = {
    'MX' : "A1AM78C64UM0Y8",
    'BR' : "A2Q3Y263D00KWC",
}

export const DEFAULT_JITA_METRIC_DATA: { [key : string] : any[]} ={};

export const JITA_INCEPTION_DATE: string = '2023-09-05';