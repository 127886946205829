import React, { useEffect, useRef, useState } from "react"
import { Alert, Box, Container, Grid, IconButton, Switch, TextField } from "@mui/material";
import { checkId, decryptMerchantId, encryptMerchantId, extractSellerIds } from "src/helpers/MerchantIdsHelper";
import SimpleFileInput from "src/components/input/SimpleFileInput";
import { Delete } from "@mui/icons-material";
import { HELPER_TEXT_FOR_MERCHANT_IDS_INPUT } from "src/constants/ISPOnboarding";

const MerchantIdsInput = (props: MerchantIdsInputProps) => {
    const sellerIds: Array<string> = props.value || [];
    const handler = props.handler;
    let inputRef:any = useRef()
    inputRef = {current:null}
    const [state, setState] = useState({
        text: sellerIds.join("\n"),
        isEncrypted: false,
        sellerIds: sellerIds,
        error: ""
    });

    useEffect(() => {
        handleParentCallback()
    }, [state.isEncrypted])

    const updateStateFromTextInput = (rawSellerIds: string | ArrayBuffer | null) => {
        let sellerIds = extractSellerIds(rawSellerIds);
        if (sellerIds.length === 0) {
            setState({ ...state, sellerIds: [], isEncrypted: false })
            handler([]);
        };
        let storeEncrypted = checkId(sellerIds[0])['isEncrypted']
        let erroredSellerIds: Array<string> = [];
        sellerIds = sellerIds.map((sellerId: string) => {
            let { isEncrypted, isValid } = checkId(sellerId);
            if (!isValid) {
                erroredSellerIds.push(sellerId);
                return sellerId;
            }
            return storeEncrypted === isEncrypted ? sellerId : (storeEncrypted ? encryptMerchantId(sellerId) : decryptMerchantId(sellerId));
        })

        setState({
            ...state,
            sellerIds: sellerIds,
            isEncrypted: storeEncrypted,
            error: generateErrorMsg(erroredSellerIds),
            text: sellerIds.join('\n')
        })
        handler(sellerIds);
    }

    const toggleSellerIdEncryption = (sellerIds: Array<string>, encrypt: boolean) => {
        let erroredSellerIds: Array<string> = [];
        sellerIds.forEach((sellerId: string) => {
            let { isEncrypted, isValid } = checkId(sellerId);
            if (!isValid) erroredSellerIds.push(sellerId);
        })
        if (erroredSellerIds.length > 0) {
            setState({ ...state, error: "Cannot encrypt/decrypt. " + generateErrorMsg(erroredSellerIds) });
            return;
        }
        sellerIds = sellerIds.map(encrypt ? encryptMerchantId : decryptMerchantId);
        setState({ ...state, sellerIds: sellerIds, isEncrypted: encrypt, text: sellerIds.join('\n') });
        handler(sellerIds);
    }

    const readMerchantIdsFromFile = (file: File) => {
        let fileReader = new FileReader();
        fileReader.onloadend = (e: Event) => {
            updateStateFromTextInput(fileReader.result);
        };
        fileReader.readAsText(file);
        inputRef.current.value = file
    };

    const generateErrorMsg = (erroredSellerIds: Array<string>) => {
        return erroredSellerIds && erroredSellerIds.length > 0 ? ("Please correct these invalid merchantIds: " + erroredSellerIds.join(',')) : "";
    }

    const handleParentCallback = () => {
        try {
            let sellerIds: Array<string> = state.sellerIds;
            if (!state.isEncrypted) {
                sellerIds = sellerIds.map(encryptMerchantId);
            }
        } catch (error) {
            handler([]);
        }
    }

    const handleClear = () => {
        inputRef.current.value = null
        setState({ ...state, sellerIds: [], isEncrypted: false, text: '', error: '' })
        handler([]);
    }

    return (
        <Container maxWidth="xl" style={props.customStyle}>
            <TextField
                id={props.inputId}
                rows={props.rows}
                placeholder={props.inputPlaceholder}
                label={(state.isEncrypted ? "Encrypted" : "Decrypted") + " " + props.label}
                onBlur={() => updateStateFromTextInput(state.text)}
                onChange={event => setState({ ...state, text: event.target.value })}
                helperText={HELPER_TEXT_FOR_MERCHANT_IDS_INPUT}
                value={state.text}
                InputProps={{
                    endAdornment:
                        <Box>
                            <Switch
                                checked={state.isEncrypted}
                                onChange={() => toggleSellerIdEncryption(state.sellerIds, !state.isEncrypted)}
                                sx={{ position: "absolute", top: 0, right: 0 }} />

                            <IconButton
                                disabled={state.text.length === 0}
                                color="error"
                                onClick={handleClear}
                                size="small"
                                sx={{ position: "absolute", top: 40, right: 10 }}>
                                <Delete />
                            </IconButton>
                        </Box>
                }}
                fullWidth
                multiline
            />

            <Grid container display="flex" justifyContent="space-between" sx={{ paddingY: 1 }} >
                <Grid item>
                    <SimpleFileInput
                        inputId="fileInputForSellerIds"
                        accept=".txt,.csv"
                        inputRef={inputRef}
                        onChange={(selection: any) => readMerchantIdsFromFile(selection[0])}
                    />
                </Grid>
            </Grid>
            {state.error && <Alert severity="error" onClose={() => setState({ ...state, error: "" })}>{state.error}</Alert>}
        </Container>
    )
}

interface MerchantIdsInputProps {
    inputId: string,
    label: string,
    rows: string,
    inputPlaceholder: string,
    value?: Array<string>,
    handler?: any,
    customStyle?: any
}

export default MerchantIdsInput;