import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import {
  MONTH,
  REPORT,
  GST_AGGREGATED_REQUEST,
  YEAR,
} from "./ISPReportsConstants";
import { getISPDetails } from "../isp/ISPAndSellerOnboardingAPI";
import { API_PATH, REPORT_TYPES, SELECTED_ALL } from "src/constants/ISPOnboarding";
import { HomeState } from "../HomeState";
import SearchableMultiSelectDropdown from "src/components/input/SearchableMultiSelectDropdown";
import { DataTable } from "./Table";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { isLoadingAction } from "../HomeActions";
import { fetchGSTReports } from "src/services/GSTReportAPI";
import { DATE_FORMAT_FOR_YEAR_MONTH } from "src/constants/Date";
import { routes } from "src/data/Routes";

export const GSTAggregatedReport = (props: PageProps) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
  const dispatch = useDispatch();
  const [gstReportPayload, setGSTReportPayload] = useState(
    GST_AGGREGATED_REQUEST
  );
  const [ispId, setISPId] = useState<string>("");
  const [gstinList, setGstinList] = useState<Array<string>>([]);
  const [selectedGstin, setSelectedGstin] = useState<Array<string>>([]);
  const [reportType, setReportType] = useState<Array<string>>([]);
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [presignedUrls, setPresignedUrls] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

  const currentDate = dayjs();
  const minDate = currentDate.subtract(1, YEAR).startOf(MONTH).toDate();
  const maxDate = currentDate.endOf(MONTH).toDate();

  const { isLoading } = useSelector((state: CombineReducerState) => state.home);
  const baseUrl = useSelector(
    (state: CombineReducerState) => state.navbar.baseUrl
  );
  const ispDetails = useSelector(
    (state: { home: HomeState }) => state.home.ispDetails
  );

  const URL_TO_SAVE_SELLER_DETAILS = baseUrl + API_PATH;

  const url = baseUrl + routes.isp.reports.gstAggregatedReport;

  useEffect(() => {
    setGSTReportPayload({
      ...gstReportPayload,
      ispId: ispId,
      gstins: selectedGstin,
      [REPORT]: reportType,
      [MONTH]: month,
      year: year,
    });
  }, [ispId, selectedGstin, reportType, month]);

  useEffect(() => {
    getISPDetails(
      URL_TO_SAVE_SELLER_DETAILS,
      dispatch,
      setApiError,
      setApiErrorMessage
    );
  }, []);

  const isInputEmpty = () => {
    return !Object.keys(gstReportPayload).every((k) => gstReportPayload[k] && selectedGstin.length && reportType.length);
  };

  const handleReportTypeChange = (event: SelectChangeEvent<typeof reportType>) => {
    setReportType(event.target.value as string[]);
  };

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  const handleSelectAll = () => {
    let allSelected: React.SetStateAction<string[]>;
      if (selectedGstin.length === gstinList.length) {
        allSelected = [];
      } else {
        allSelected = gstinList;
      }
    setSelectedGstin(allSelected);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string[]
  ) => {
    if (newValue.includes(SELECTED_ALL)) {
      handleSelectAll();
    } else {
      setSelectedGstin(newValue);
    }
  };

  useEffect(() => {
    setSelectedGstin([]);
    const ispDetailsObj = ispDetails.find((isp) => isp.ispId === ispId);
    if (ispDetailsObj) {
      setGstinList(ispDetailsObj.gstins);
    }
  }, [ispId]);

  const handleDateChange = (newValue: Date | null) => {
    const parsedDate = dayjs(newValue, DATE_FORMAT_FOR_YEAR_MONTH);
    setMonth(String(parsedDate.month() + 1)) 
    setYear(parsedDate.year().toString());
    setSelectedDate(parsedDate);
  };

  const handleSubmit = async () => {
    setPresignedUrls([])
    clearErrors();
    dispatch(isLoadingAction(true));

    await fetchGSTReports(
      url,
      gstReportPayload,
      setPresignedUrls,
      setApiErrorMessage,
      setApiError
    );
    dispatch(isLoadingAction(false));
  };

  return (
    <Grid container maxWidth="xl" sx={{ padding: 0 }}>
      <PageTitle title={PAGE_TITLE} />
      <Grid item sm={4}>
        <Paper elevation={4} sx={{ paddingY: 2 }}>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="ISPId">ISP Name</InputLabel>
              <Select
                labelId="ISPId"
                label="ISP ID"
                name="ispId"
                sx={{ textAlign: "left" }}
                disabled={!ispDetails[0].ispId}
                onChange={(e: SelectChangeEvent) => setISPId(e.target.value)}
              >
                {ispDetails.map((detail: any) => (
                  <MenuItem key={detail.ispId} value={detail.ispId}>
                    {detail.ispName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <SearchableMultiSelectDropdown
              ispId={ispId}
              gstin={gstinList}
              selectedOptions={selectedGstin}
              handleOptionChange={handleOptionChange}
            />
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="report-type">Report Type</InputLabel>
              <Select
                labelId="report-type"
                label="Report Type"
                sx={{ textAlign: "left" }}
                value={reportType}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                onChange={handleReportTypeChange}
                multiple
              >
                {REPORT_TYPES.map((reportType) => (
                  <MenuItem key={reportType} value={reportType}>
                    {reportType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month"]}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      helperText={null}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select Month and Year"
                    />
                  )}
                  inputFormat={DATE_FORMAT_FOR_YEAR_MONTH}
                  disableFuture
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={isInputEmpty() || isLoading}
              onClick={handleSubmit}
              fullWidth
            >
              Submit
            </Button>
          </Box>
          {isLoading && <LinearProgress />}
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ paddingX: 3, pb: 2 }}>
          {!apiError && presignedUrls?.length > 0 && (
            <Paper elevation={4} sx={{ padding: 2 }}>
              <DataTable rows={presignedUrls} />
            </Paper>
          )}
          {apiError && <Alert severity="error">{apiErrorMessage}</Alert>}
        </Box>
      </Grid>
    </Grid>
  );
};
