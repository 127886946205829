import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BASE_TITLE, DELIMITER, PageProps } from 'src/components/App';
import { useDispatch, useSelector } from 'react-redux';
import { isSideBarActiveAction } from 'src/components/sidebar/SidebarActions';
import { CombineReducerState } from 'src/combineReducers';
import CardList from 'src/components/collection/CardList';
import { homeCards } from 'src/data/CardData';
import apiRequest from 'src/utils/ApiRequest';
import { setCardDataAction } from './HomeActions';
import GlobalSellerRegistrationJitaMetricsHomepageCards from '../components/display/GlobalSellerRegistrationJitaMetricsHomepageCards';

const Home = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

    const dispatch = useDispatch();
    const isSidebarActive = useSelector((state: CombineReducerState) => state.sidebar.isSidebarActive);
    let cardData = useSelector((state: CombineReducerState) => state.home.cardData);

    useEffect(() => {
        const fetchCardData = async (key: string, url: string, queryParams: Record<string, string>) => {
            const response = await apiRequest(url, queryParams);
            const data = await response.json();
            cardData = { ...cardData, [key]: { data: data, isLoaded: true } };
            dispatch(setCardDataAction(cardData));
        }
        // For every card, we load the data on refresh only once and store it in redux
        homeCards.forEach(card => {
            if (card.fetchUrl && !cardData[card.description].isLoaded) {
                fetchCardData(card.description, card.fetchUrl, card.queryParam);
            }
        })
    }, []);

    return (
        <Box>
            <Typography variant="h1">VOLDEMORT 2.0</Typography>
            <Typography variant="h4" sx={{ mb: 3 }}>Your data visualization dashboard.</Typography>

            <Button variant="outlined" sx={{ mx: 1 }}>Learn More</Button>
            <Button variant="contained" sx={{ mx: 1 }} onClick={() => dispatch(isSideBarActiveAction(!isSidebarActive))}>Get Started</Button>

            <GlobalSellerRegistrationJitaMetricsHomepageCards/>
        </Box>
    )
}

export default Home;