import React from "react";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { SELECTED_ALL } from "src/constants/ISPOnboarding";

const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const SearchableMultiSelectDropdown = ({
  gstin,
  ispId,
  selectedOptions,
  handleOptionChange,
}: any) => {
  return (
    <Autocomplete
      multiple
      size="small"
      id="checkboxes-tags-demo"
      options={[SELECTED_ALL, ...gstin]}
      disableCloseOnSelect
      value={selectedOptions}
      onChange={handleOptionChange}
      disabled={!ispId}
      getOptionLabel={(option) => (option === SELECTED_ALL ? "Select All" : option)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={
              option === SELECTED_ALL
                ? selectedOptions.length === gstin.length
                : selected
            }
          />
          {option === SELECTED_ALL ? "Select All" : option}
        </li>
      )}
      sx={{ textAlign: "left" }}
      renderInput={(params) => <TextField {...params} label="Select GSTIN" />}
    />
  );
};

export default SearchableMultiSelectDropdown;