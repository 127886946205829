const Cognito = {
    beta: {
        CLIENT_ID: "76kdoopq6pghopudcklqe2lenn",
        // http://localhost:4321 for dev, https://xxx.cloudfront.net for beta and prod
        REDIRECT_URI: window.location.protocol + "//" + window.location.host + "/",
        AUTH_URL: "https://voldemort-test.auth.eu-west-1.amazoncognito.com/oauth2/authorize",
        TOKEN_URL: "https://voldemort-test.auth.eu-west-1.amazoncognito.com/oauth2/token",
    },
    prod: {
        CLIENT_ID: "6de1de4a48t9aduq6dju8g81hr",
        REDIRECT_URI: window.location.protocol + "//" + window.location.host + "/",
        AUTH_URL: "https://voldemort-prod.auth.eu-west-1.amazoncognito.com/oauth2/authorize",
        TOKEN_URL: "https://voldemort-prod.auth.eu-west-1.amazoncognito.com/oauth2/token",
    }
}

export const getCognitoConfig = (stage: string) => {
    return stage === "beta" ? Cognito.beta : Cognito.prod;
}
