import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, CircularProgress, FormControl,
    Grid, InputLabel, MenuItem,
    Paper, Select, SelectChangeEvent,
    Typography
} from "@mui/material";
import {PageTitle} from "src/components/display/PageTitle";
import React, {useState} from "react";
import {Box, Container} from "@mui/system";
import GSTIdsInput from "src/components/input/GSTIdsInput";
import Collapsible from "src/components/shared/Collapsible";
import {BASE_TITLE, DELIMITER, PageProps} from "src/components/App";
import apiRequest from "src/utils/ApiRequest";
import {routes} from "src/data/Routes";
import {useSelector} from "react-redux";
import {CombineReducerState} from "src/combineReducers";
import AllowedGSTINFAQ from "src/views/utilities/allowed-gstin-list/AllowedGSTINFAQ";
import {
    ALLOWED_GSTIN_PROGRAMS,
    UPDATE_FAILED_MSG,
    UPDATE_SUCCESS_MSG,
    UPDATE_TASK
} from "src/constants/AllowedGSTIN";
import {BRASS_ALLOWED_STATUS, BRASS_AUTH_DENIED_MSG} from "src/constants/BrassConstants";


const UpdateAllowedGSTINList = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

    const [allowedGSTINList, setAllowedGSTINList] =
        useState<Array<string>>([]);
    const [program, setProgram] = useState<string>("");
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const url: string = baseUrl + routes.isp.gst.updateGSTAllowlist;
    const brassAuthUrl: string = baseUrl + routes.brassAuth;

    const isInputEmpty = () => {
        return (
            allowedGSTINList.length === 0 ||
            program === ""
        );
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        window.history.pushState(
            null,
            "",
            `?program=${program}&task=${UPDATE_TASK}&allowlist=${allowedGSTINList}`
        );

        try {
            let payload = {
                path: routes.isp.gst.getGSTAllowlist,
                program: program,
                task: UPDATE_TASK
            }

            const brassResponse = await apiRequest(brassAuthUrl, payload);

            if(brassResponse.ok){
                const brass_data = await brassResponse.json();
                if (brass_data.authStatus === BRASS_ALLOWED_STATUS){
                    let payload = {
                        program: program,
                        task: UPDATE_TASK,
                        allowlist: allowedGSTINList
                    };

                    const response = await apiRequest(url, payload);
                    if (!response.ok){
                        setIsSuccess(false);
                        setStatusMessage(UPDATE_FAILED_MSG)
                    }else{
                        setIsSuccess(true);
                        setStatusMessage(UPDATE_SUCCESS_MSG)
                    }
                }else {
                    setIsSuccess(false);
                    setStatusMessage(BRASS_AUTH_DENIED_MSG);
                }
            }else {
                setIsSuccess(false);
                setStatusMessage(UPDATE_FAILED_MSG);
            }
        } catch (err) {
            setIsSuccess(false);
            setStatusMessage(UPDATE_FAILED_MSG);
        }
        setIsLoading(false);
    }

    const handleProgramChange = (event: SelectChangeEvent) => {
        setProgram(event.target.value);
    }

    return (
        <Grid container alignItems="center" justifyContent="center">
            <PageTitle title="Update Allowed GSTIN List" />
            <Grid container>
                <Grid item sm={4}>
                    <Container maxWidth="sm">
                        <Paper elevation={4} sx={{ paddingY: 2 }}>
                            <Box sx={{ paddingX: 3, paddingY: 1 }}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="program">Program</InputLabel>
                                    <Select
                                        labelId="programId"
                                        label="Program"
                                        value={program}
                                        onChange={handleProgramChange}
                                        sx={{ textAlign: "left" }}
                                    >
                                        {ALLOWED_GSTIN_PROGRAMS.map((program) => (
                                            <MenuItem
                                                key={program}
                                                value={program}
                                            >
                                                {program}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <GSTIdsInput
                                label="Allowed GSTIN List"
                                inputId="allowedGSTINListId"
                                inputPlaceholder="Comma / Line separated GSTIN list"
                                rows="20"
                                value={allowedGSTINList}
                                handler={setAllowedGSTINList}
                            />
                            {isLoading && <CircularProgress />}
                            <Box sx={{ paddingX: 3, paddingY: 1 }}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled={isInputEmpty()}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Paper>
                    </Container>
                </Grid>
                <Grid item sm={8}>
                    <Collapsible title="Tool Description and Usage" open={false}>
                        <AllowedGSTINFAQ/>
                    </Collapsible>
                    <Box style={{ width: "100%", marginTop: "16px" }}>
                        <Accordion expanded={true}>
                            <AccordionSummary>
                                <Typography variant="h6">Update Summary</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ textAlign: 'left' }}>
                                <Typography>Number of GSTIN allowed: {isSuccess?allowedGSTINList.length:0}</Typography>
                                <Typography>{statusMessage}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UpdateAllowedGSTINList;