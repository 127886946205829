import { GridColDef } from "@mui/x-data-grid";

export const TableProperties: GridColDef[] = [
  {
    headerName: "Seller Id",
    field: "seller",
    flex: 1,
  },
  {
    headerName: "Operation Status",
    field: "requestSuccessful",
    flex: 1,
  },
  {
    headerName: "Paris Flag Value",
    field: "flagValue",
    flex: 1,
  },
  {
    headerName: "Flag Details",
    field: "clickable",
    flex: 1,
  },
];

export const FlagValueDetailsTable: GridColDef[] = [
  {
    headerName: "Reason",
    field: "reason",
    flex: 1,
  },
  {
    headerName: "Reason Value",
    field: "value",
    flex: 1,
  },
  {
    headerName: "Reason Comment",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Updated On",
    field: "updatedOn",
    flex: 1,
  },
];