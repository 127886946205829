import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { darken, lighten, useTheme } from "@mui/material";
import {OPGSPPaymentFailureConstants} from "src/constants/ServiceConstants";

export const Row = (props: RowProps) => {

    const theme = useTheme();
    const merchantId = props.merchantId;
    const details = props.details;
    const totalAmount = details[OPGSPPaymentFailureConstants.TOTAL_AMOUNT];
    const totalFailures = details[OPGSPPaymentFailureConstants.TOTAL_FAILURES];
    const [isOpen, setIsOpen] = useState(false);

    const rowBgColor = theme.palette.mode === "light" ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.75);
    const activeRowBgColor = theme.palette.mode === "light" ? lighten(theme.palette.success.light, 0.85) : darken(theme.palette.success.dark, 0.75);
    const tableBgColor = theme.palette.mode === "light" ? lighten(theme.palette.grey[500], 0.85) : darken(theme.palette.grey[500], 0.75);

    const formatDate = (updateEpochDay: string) => {
        return new Date(parseInt(updateEpochDay, 10)*86400000).toISOString().split('T')[0]
    }

    return (
        <Fragment>
            <TableRow sx={{ backgroundColor: isOpen ? activeRowBgColor : "inherit", cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)}>
                <TableCell>{merchantId}</TableCell>
                <TableCell><span>&#8377;</span> {totalAmount}</TableCell>
                <TableCell>{totalFailures}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={100}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" sx={{ backgroundColor: tableBgColor }}>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: rowBgColor }}>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Amount for a day (<span>&#8377;</span>)</TableCell>
                                        <TableCell>Camt053 Failure</TableCell>
                                        <TableCell>Pain002 Failure</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(details).map(key => {
                                        if(key != OPGSPPaymentFailureConstants.TOTAL_AMOUNT && key != OPGSPPaymentFailureConstants.TOTAL_FAILURES) {
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell>{formatDate(key)}</TableCell>
                                                    <TableCell><span>&#8377;</span> {details[key][OPGSPPaymentFailureConstants.AMOUNT_FOR_A_DAY]}</TableCell>
                                                    <TableCell>{details[key][OPGSPPaymentFailureConstants.PAIN002_FAILURE]}</TableCell>
                                                    <TableCell>{details[key][OPGSPPaymentFailureConstants.CAMT053_FAILURE]}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export interface RowProps {
    merchantId: string,
    details: any
}