import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";
import { routes } from "src/data/Routes";
import { MARKETPLACES } from "../mtr/Data";
import {
  SETTLEMENT_REQUEST,
  SETTLEMENT_REPORTS_TYPE,
  REPORT,
  ISPREPORTS,
  WEEKS,
  MERCHANT_ID,
  MARKETPLACE_ID,
  ERROR_MESSAGE,
} from "../ISPReportsConstants";
import { encryptMerchantId } from "src/helpers/MerchantIdsHelper";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { isLoadingAction } from "src/views/HomeActions";
import {  callBrassAPI, fetchISPReports } from "../ISPReportHelper";
import ISPReportTable from "../ISPReportsTable";
import { ISPREPORTSTYLE } from "../ISPReportStyle";
import { handleApiError } from "src/utils/CommonHelper";

const SettlementReports = (props: PageProps) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

  const dispatch = useDispatch();
  const {isLoading, brassResponse} = useSelector(
    (state: CombineReducerState) => state.home
  );
  const baseUrl = useSelector(
    (state: CombineReducerState) => state.navbar.baseUrl
  );
  const url = baseUrl + routes.isp.reports.settlement;

  const [settlementPayload, setSettlementPayload] =
    useState(SETTLEMENT_REQUEST);
  const [merchantIds, setMerchantIds] = useState<Array<string>>([]);
  const [reportType, setReportType] = useState<Array<string>>([]);
  const [reports, setReports] = useState<Array<Object>>([]);
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<String>("");
  const [responseLoaded, setResponseLoaded] = useState<boolean>(false);

  useEffect(() => {
    setResponseLoaded(true);
  }, [reports]);


  useEffect(() => {
    const encryptIds: Array<string> = merchantIds.map((id: any) => {
      let encryptedId = isNaN(id);
      return encryptedId ? id : encryptMerchantId(id);
    });

    setSettlementPayload({
      ...settlementPayload,
      [MERCHANT_ID]: encryptIds.join(","),
      [REPORT]: reportType.join(","),
    });
  }, [merchantIds, reportType]);

  const isInputEmpty = () => {
    return !Object.keys(settlementPayload).every((k) => settlementPayload[k]);
  };

  const handleReportTypeChange = (
    event: SelectChangeEvent<typeof reportType>
  ) => {
    const {
      target: { value },
    } = event;
    setReportType(typeof value === "string" ? value.split(",") : value);
  };

  const renderReports = (data: Array<Object>) => {
    return data.map((report: any) => {
      return (
        <Grid>
          <Card sx={{ marginTop: "15px", marginBottom: "15px" }}>
            <div style={ISPREPORTSTYLE.bgColor}>
              <div style={ISPREPORTSTYLE.reportsContainer}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography>Reference Id - {report.referenceId}</Typography>
                  </Box>
                  <Box>
                    <Typography>Document Id - {report.documentId}</Typography>
                  </Box>
                </Grid>
                <div style={ISPREPORTSTYLE.downloadButton}>
                  <a
                    href={report.urlForDownload}
                    target="_blank"
                    className="btn btn-link btn-lg"
                  >
                    DOWNLOAD
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
      );
    });
  };
 
  const handleSubmit = async() => {
    dispatch(isLoadingAction(true));
    clearErrors();

    let currentBrassResponse;
    if (!brassResponse.isBrassAuthorized) {
        currentBrassResponse = await callBrassAPI(baseUrl, dispatch);
    }
    if (brassResponse.isBrassAuthorized || currentBrassResponse.payload.isBrassAuthorized) {
        try {
            const data = await fetchISPReports({ISPReportPayload: settlementPayload, reportType, url});
            if (data.message) {
                throw new Error(ERROR_MESSAGE.ServerError);
            } else {
                setApiError(false);
                setReports(data);
            }
        } catch (err : any) {
            handleApiError(err.message, setApiError, setApiErrorMessage);
        } finally {
            dispatch(isLoadingAction(false));
        }
    } else {
        handleApiError(brassResponse.error, setApiError, setApiErrorMessage, dispatch, isLoadingAction);
    }
};

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  return (
    <Grid container maxWidth="xl" sx={{ padding: 0 }}>
      <PageTitle title="Settlement Reports" />
      <Grid item sm={4}>
        <Paper elevation={4} sx={{ paddingY: 2 }}>
          <MerchantIdsInput
            label="Merchant IDs"
            inputId="merchantId"
            inputPlaceholder="Comma / Line separated Merchant Ids"
            rows="10"
            value={merchantIds}
            handler={setMerchantIds}
          />
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="marketplace">Marketplace</InputLabel>
              <Select
                labelId="marketplace"
                label="Marketplace"
                name="marketplaceId"
                value={settlementPayload?.marketplaceId}
                sx={{ textAlign: "left" }}
                onChange={(e) =>
                  setSettlementPayload({
                    ...settlementPayload,
                    [MARKETPLACE_ID]: e.target.value,
                  })
                }
              >
                {MARKETPLACES.map((marketplace) => (
                  <MenuItem key={marketplace} value={marketplace}>
                    {marketplace}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="report-type">Report Type</InputLabel>
              <Select
                labelId="report-type"
                label="Report Type"
                name="reportType"
                sx={{ textAlign: "left" }}
                value={reportType}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value: string) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                onChange={handleReportTypeChange}
                multiple
              >
                {SETTLEMENT_REPORTS_TYPE.map((reportType) => (
                  <MenuItem key={reportType} value={reportType}>
                    {reportType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="weeks">Weeks</InputLabel>
              <Select
                labelId="weeks"
                label="weeks"
                value={settlementPayload.weeks}
                onChange={(e) =>
                  setSettlementPayload({
                    ...settlementPayload,
                    [WEEKS]: e.target.value,
                  })
                }
                sx={{ textAlign: "left" }}
              >
                {ISPREPORTS.WEEKS.map((week) => (
                  <MenuItem key={week.id} value={week.id}>
                    {week.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={isInputEmpty() || isLoading}
              onClick={handleSubmit}
              fullWidth
            >
              Submit
            </Button>
          </Box>
          {isLoading && <LinearProgress />}
        </Paper>
      </Grid>
      <Grid item sm={8}>
        <ISPReportTable
          type="settlement"
          renderReports={renderReports}
          reports={reports}
          apiError={apiError}
          apiErrorMessage={apiErrorMessage}
          responseLoaded={responseLoaded}
        />
      </Grid>
    </Grid>
  );
};

export default SettlementReports;
