const reportsContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    padding: "15px",
}

const bgColor = {
    background: "rgb(229 229 229 / 17%)", 
    padding:'15px 0'
}

const downloadButton = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
}

export const ISPREPORTSTYLE = {
    reportsContainer,
    bgColor,
    downloadButton
}