import { combineReducers } from "redux";
import { navbarReducer } from "src/components/navbar/NavbarReducers";
import { NavbarState } from "./components/navbar/NavbarState";
import { sidebarReducer } from "./components/sidebar/SidebarReducers";
import { SidebarState } from "./components/sidebar/SidebarState";
import { homeReducer } from "./views/HomeReducers";
import { HomeState } from "./views/HomeState";

export interface CombineReducerState {
    navbar: NavbarState,
    sidebar: SidebarState,
    home: HomeState,
}

const combineReducer = combineReducers({
    navbar: navbarReducer,
    sidebar: sidebarReducer,
    home: homeReducer,
});

export default combineReducer;