import {BASE_URL, IS_DARK_THEME, NavbarActions, STAGE} from "src/components/navbar/NavbarActions";
import {NavbarState} from "src/components/navbar/NavbarState";
import {ApiGatewayBaseUrl} from "src/data/Routes";

const initialState: NavbarState = {
    isDarkTheme: false,
    stage: window.location.href.includes("beta") || window.location.hostname.includes("localhost") ? "beta" : "prod",
    baseUrl: window.location.href.includes("beta") || window.location.hostname.includes("localhost") ? ApiGatewayBaseUrl.beta : ApiGatewayBaseUrl.prod
};


export const navbarReducer = (state = initialState, action: NavbarActions): NavbarState => {

    switch (action.type) {
        case IS_DARK_THEME: {
            return {
                ...state,
                isDarkTheme: action.payload
            }
        }
        case STAGE: {
            return {
                ...state,
                stage: action.payload
            }
        }
        case BASE_URL: {
            return {
                ...state,
                stage: action.payload
            }
        }
        default:
            return state;
    }
};