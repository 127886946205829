import {SUBSCRIPTION_DETAILS} from "./SubscriptionConstant";

export const provideInferredData:any = (data:Record<string,string>, merchantIdList:string, addOnSubscriptionType:string, addOnSubscriptionTier:string) => {
    if (data.isSucceded) {
        return {
            isSucceded: true,
            tableRows: createTableForAddOnSubscription(merchantIdList, addOnSubscriptionType, addOnSubscriptionTier)
        }
    } else {
        return data;
    }
}
 
const createTableForAddOnSubscription = (merchantIdList:string, subscriptionType:string, subscriptionTier:string):Array<tableData> => {
    let table: Array<tableData> = [];
    merchantIdList.split(",").forEach((merchantId:string) => {
        table.push({
            merchantId: merchantId,
            subscriptionType: subscriptionType,
            subscriptionTier: subscriptionTier,
            requestSuccessful: SUBSCRIPTION_DETAILS.SUCCESS,
        });
    });
    return table;
}

interface tableData {
    merchantId: string,
    subscriptionType: string,
    subscriptionTier: string,
    requestSuccessful: string,
}