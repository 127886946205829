import { DEFAULT_SELLER_DATA, Event, SellerRegistrationStatus } from "src/constants/FormResponse";

export const getEvents = (response: any) => {
    if (response.value) {
        const events: Array<Event> = response.value.events;
        events.forEach(event => event.eventTime = new Date(parseInt(event.eventTime)).toLocaleString());
        return events;
    }
    return [];
}

export const getSellerData = (response: any) => {
    if (response.value) {
        const data = response.value;
        const events: Array<Event> = data.events;
        let sourceChannel = "-";
        events.forEach(event => sourceChannel = event.sourceChannel);
        return {
            sellerId: data.sellerID || "-",
            sourceMerchantId: data.sourceMerchantID || "-",
            createdTime: new Date(parseInt(data.createdTime, 10)).toLocaleString() || "-",
            updatedTime: new Date(parseInt(data.updatedTime, 10)).toLocaleString() || "-",
            eventDate: new Date(parseInt(data.eventDate, 10)).toLocaleString() || "-",
            sourceChannel: sourceChannel,
            languageOfPreference: data.languageOfPreference || "-",
            countryOfEstablishment: data.countryOfEstablishment || "-",
        }
    }
    return DEFAULT_SELLER_DATA;
}

// Given a list of seller data, it returns the number of sellers who have completed/not completed the registration.
export const getRegistrationStats = (response: any) => {
    const res = { total: 0, completed: 0, notCompleted: 0 };
    if (response.value) {
        const sellers = response.value;
        sellers.forEach((seller: SellerRegistrationStatus) => {
            res.notCompleted++;
            res.total++;
            const events: Array<Event> = seller.events;
            for (let event of events) {
                // Condition for registration completion.
                if (event.stateName.toLowerCase() === "doc upload page" && event.status.toLowerCase() === "completed") {
                    res.completed++;
                    res.notCompleted--;
                    break;
                }
            }
        })
    }
    return res;
}