import {
  DEVO_COUNTRY_CODE_TO_REALM_MAP,
  DEVO_MARKETPLACE_ID_TO_COUNTRY_MAP,
  PROD_COUNTRY_CODE_TO_REALM_MAP,
  PROD_MARKETPLACE_ID_TO_COUNTRY_MAP
} from "src/constants/Marketplace";

const stage = window.location.hostname.includes("beta") || window.location.hostname.includes("localhost") ? "beta" : "prod";

export const MARKETPLACES = [
  "Turkey",
  "India",
  "Saudi Arabia",
  "United Arab Emirates",
  "Egypt",
  "US",
  "Canada",
  "Mexico",
  "Brazil",
  "Japan",
  "Australia",
  "Singapore",
];

export const REASON_LIST = [
  "OOC2IN_REGISTRATION_INCOMPLETE",
  "OOC2IN_BUSINESS_SUSPENSION",
  "SIV_UNAPPROVED",
  "TAX_VERIFICATION_PENDING",
  "NO_PE_DOCUMENT_ABSENT"
];

export const REASONVALUE = ["true", "false"];

export const FLAGVALUE = ["inStaging"];

export const DEFAULT_SELLER_DATA = {
  sellerIdBySellerMarketplaceFlagValueMap: "",
};

export const MERCHANT_ID = "merchantIds";
export const MARKETPLACE_ID = "marketplaceId";
export const MARKETPLACE = "marketplace";
export const PARIS_FLAGVALUE = "flagValue";
export const REASON_VALUE = "reasonValue";
export const REASON = "reason";
export const COMMENT = "comment";

export const SELLER_MARKETPLACE_FLAG_VALUE_MAP = "sellerIdBySellerMarketplaceFlagValueMap";

export const PARIS_FLAG_DETAILS = {
  // UI Related config
  ErrorMessages: {
    InvalidParams:
      "Invalid parameters. Make sure you are entering appropriate details.",
    ServerError:
      "Failed to fetch the data from the server. Debug error in lambda",
    NOPermittedActions:
      "You do not have permissions to update PARIS flags. Please refer {wiki link}",
  },
  InfoMessages:{
    SuccessfulFlagUpdates : "Successful flag updates",

    DuplicateFlagUpdates : "Duplicate flag updates",
   
    FailedFlagUpdates : "Failed flag updates",
   
    SuccessfulDetailFetches : "Successful fetch details",
   
    FailedDetailFetches : "Failed fetch details",
   
    FlagOperationStatusKey : "flagOperationStatus",
   
    SuccessStatus : "Success",
   
    DuplicateStatus : "Duplicate",
   
    FailStatus : "Failed"

  },
  HelpSectionURL:
    "https://w.amazon.com/bin/view/Global_Selling_IN/Projects/GSX/Voldemort/ParisFlagTool/",
};

export const MARKETPLACE_MAP: {[key: string]: string} = stage === "beta" ? DEVO_MARKETPLACE_ID_TO_COUNTRY_MAP : PROD_MARKETPLACE_ID_TO_COUNTRY_MAP;
export const REALM_MAP = stage === "beta" ? DEVO_COUNTRY_CODE_TO_REALM_MAP : PROD_COUNTRY_CODE_TO_REALM_MAP;