import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GlobalSellerRegistrationMetrics } from "src/views/seller-registration/metrics/global-seller-registration-metrics/GlobalSellerRegistrationJitaMetrics";


interface GlobalSellerRegistrationChartProps {
	data: GlobalSellerRegistrationMetrics[],
}

interface graphDataPoint {
	Date: string;
	Registrations: number;
}

const SellerRegistrationLineChart: React.FC<GlobalSellerRegistrationChartProps> = ({ data }) => {
	const [graphData, setGraphData] = useState<Array<graphDataPoint>>([])

	useEffect(() => {
		const dates = data.map((entry) => entry.eventDate);
		const registrations: { [date: string]: number } = {};
		dates.forEach((date) => {
			registrations[date] = registrations[date] ? registrations[date] + 1 : 1;
		});

		let graphDataTemp: Array<graphDataPoint> = []

		Object.keys(registrations).forEach((key) => {
			graphDataTemp.push({
				Date: key,
				Registrations: registrations[key]
			})

		})

		// sort graph data in ascending order based on date
		const parsedData = graphDataTemp.map((item) => ({
			...item,
			date: new Date(item.Date),
		}));

		parsedData.sort((a, b) => a.date.getTime() - b.date.getTime());

		const sortedData: graphDataPoint[] = parsedData.map((item) => ({
			...item,
			date: item.date.toISOString().split("T")[0],
		}));

		setGraphData(sortedData)

	}, [data]);


	return (<>
		<ResponsiveContainer width="100%" height="100%">
		<LineChart
				width={500}
				height={300}
				data={graphData}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="Date" />
				<YAxis />
				<Tooltip />
				<Legend />
				<Line type="monotone" dataKey={"Registrations"} stroke="#1876d2" activeDot={{ r: 8 }} />
			</LineChart>
		</ResponsiveContainer>
	</>
	)
};

export default SellerRegistrationLineChart;
