import React from "react";
import { Alert, Box } from "@mui/material";
import Collapsible from "src/components/shared/Collapsible";
import { ERROR_MESSAGE } from "./ISPReportsConstants";
import { errorHandlingForISPReports } from "./inventory-shipment/InventoryShipmentReports";

const ISPReportTable = (props:ISPReportTableInterface) => {
    const {renderReports, reports, apiError, apiErrorMessage, responseLoaded, type} = props;
    const renderResult = (renderReports:any, reports:Array<Object>) => {
        return reports.map((report: any) => {
          const reportTitle = `Merchant Id:- ${report.merchantId} - ReportType:- ${report.reportType}`;
          return (
            <Box key={reportTitle} sx={{ paddingX: 3, pb:2 }}>
              <Collapsible title={reportTitle} variant={"subtitle1"} open={false}>
                {renderISPReports(renderReports, report)}
              </Collapsible>
            </Box>
          );
        });
      };

    const renderISPReports = (renderReports: any, reports: reportDataType) => {
        if (!reports.hasOwnProperty("status")) {
          return <Alert severity="error">{ERROR_MESSAGE.ServerError}</Alert>;
        } 
        return errorHandlingForISPReports(renderReports, reports, type)
    };
    
    return(
        <>
        {!apiError && responseLoaded ? (
            renderResult(renderReports, reports)
          ) : (
            <Alert severity="error">{apiErrorMessage}</Alert>
          )}
        </>
    )
}

interface ISPReportTableInterface {
  renderReports:any, 
  reports:Array<Object>, 
  apiError:boolean, 
  apiErrorMessage:any, 
  responseLoaded:boolean
  type:string
}

export interface reportDataType {
  status:string,
  message:string,
  reports:Array<Object>
}

export default ISPReportTable;