import {Typography} from "@mui/material";
import React from "react";

const AllowedGSTINFAQ = () => {
    return (
        <div style={{ justifyContent: 'left', alignItems: 'flex-start', textAlign: 'left' }}>
            <Typography variant="h6" gutterBottom>
                <b>   What does each attribute mean?</b>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <b>Program Selection:</b> <br /> Select the program in which to upload the list of GSTIN.
                <br />
                <b>GSTIN List:</b> <br />It is the list of GSTINs which should be allowed for duplication in SSR.
                They can be separated by new lines or by commas(,).
                List can be uploaded(.csv/.txt) or can be entered in the text-field.
                <br />
                <b>Submit Button:</b> <br />
                Submit button will upload and initiate the process of allowing this list of GSTIN.
                Changes may take around 10-15 minutes to be reflected in the registration.
            </Typography>
            <Typography variant="h6" gutterBottom>
                <b>FAQ</b>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <b>After uploading or entering the list, form is showing following warning. What can I do?
                    “Following are invalid GSTINs: 27AAATI1446A1Z”</b> <br />
                The warning shows the list of GSTINs which may be invalid considering the standard format of a GSTIN.
                Please re-check these GSTINs.
                <br />
                <b>After clicking ‘Submit’, the ‘Update Summary’ shows that update failed. What should be done?</b>
                <br />The list can be re-uploaded and re-submitted. Multiple submissions will not create a problem.
                <br />
                <b>I have uploaded a GSTIN mistakenly, how can I undo that?</b> <br />
                GSTINs for a given program can be deleted from get GSTIN allowlist page.
            </Typography>
        </div>
    );
}

export default AllowedGSTINFAQ;