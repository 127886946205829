import React, { useState } from 'react'
import { Alert, Box, Button, Container, Grid, LinearProgress, Paper, Step, StepLabel, Stepper, TextField } from '@mui/material';
import apiRequest from 'src/utils/ApiRequest';
import { Errors } from 'src/constants/Errors';
import { useDispatch, useSelector } from 'react-redux';
import { CombineReducerState } from 'src/combineReducers';
import { isLoadingAction } from 'src/views/HomeActions';

const SingleIdForm = (props: SingleIdFormProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    // Props
    const url = props.url;
    const handler = props.handler;
    const label = props.label || "Seller ID";
    const placeholder = props.placeholder || "";
    const buttonText = props.buttonText || "Submit";
    const elevation = props.elevation || 4;

    // Steps
    const steps = [`Enter ${label}`, "Response"];
    const PREV_STEP = -1;
    const INPUT_STEP = 0;
    const RESPONSE_STEP = 1;
    const NEXT_STEP = 2;

    // States
    const urlParams = new URLSearchParams(window.location.search);
    const [id, setId] = useState(urlParams.get("id") || "");
    const [activeStep, setActiveStep] = useState(INPUT_STEP);
    const [failedStep, setFailedStep] = useState(PREV_STEP);
    const [emptyInputError, setEmptyInputError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState("");

    // This method is called when a user submits the form.
    const handleSubmit = async () => {
        // Empty input case
        if (id === "") {
            setEmptyInputError(true);
            setFailedStep(INPUT_STEP);
            document.getElementById("id")?.focus();
            return;
        };
        if (isLoading) return;
        window.history.pushState(null, "", "?id=" + id);
        // Setup for API call
        clearErrors();
        setActiveStep(RESPONSE_STEP);
        dispatch(isLoadingAction(true));

        try {
            const response = await apiRequest(url, { id: id });

            if (response.ok) {
                const data = await response.json();
                handler({
                    data,
                    error: false,
                    status: response.status,
                    apiErrorMessage: null,
                });
                setActiveStep(NEXT_STEP);
                setApiError(false);
            } else {
                const error = await response.json();
                handler({
                    data: [],
                    error: true,
                    status: response.status,
                    apiErrorMessage: error.message,
                });
                setFailedStep(RESPONSE_STEP);
                setApiError(true);
                setApiErrorMessage(error.message);
            }
        } catch (err) {
            console.error(err);
            handler({
                data: [],
                error: true,
                status: Errors.StatusCode.SERVER_ERROR,
                apiErrorMessage: Errors.Message.SERVER_ERROR,
            });
            setFailedStep(RESPONSE_STEP);
            setApiError(true);
            setApiErrorMessage(Errors.Message.SERVER_ERROR);
        }
        dispatch(isLoadingAction(false));
    }

    // This method is called when a change is detected in the input box.
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setId(val);
        clearErrors();
        setActiveStep(INPUT_STEP);
        if (val === "") {
            setEmptyInputError(true);
            return;
        };
    }

    // This method clears the errors on UI.
    const clearErrors = () => {
        setFailedStep(PREV_STEP);
        setEmptyInputError(false);
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={elevation}>
                <Box sx={{ paddingY: 2 }}>
                    <Stepper alternativeLabel activeStep={activeStep} sx={{ color: "secondary" }}>
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel error={index == failedStep}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <Grid container alignItems="center" justifyContent="center" sx={{ paddingX: 3, paddingY: 2 }}>
                    <Grid item sm={10}>
                        <TextField id="id" type="search" name="id" size="small" label={label} value={id} sx={{ width: "100%" }}
                            placeholder={placeholder} disabled={isLoading} error={emptyInputError} onChange={handleOnChange} autoFocus required />
                    </Grid>
                    <Grid item sm={2}>
                        <Button variant="contained" size="small" disabled={isLoading} onClick={handleSubmit}>{buttonText}</Button>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2 }}>
                        {emptyInputError && <Alert severity="error" onClose={() => setEmptyInputError(false)}>Please enter {label}.</Alert>}
                        {apiError && <Alert severity="error" onClose={() => setApiError(false)}>{apiErrorMessage}</Alert>}
                        {isLoading && <LinearProgress />}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

interface SingleIdFormProps {
    url: string,
    handler: any,
    label?: string,
    placeholder?: string,
    buttonText?: string,
    elevation?: number,
}

export default SingleIdForm;