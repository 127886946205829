import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    // TODO
    // Basic Not Found page, to be updated!
    return (
        <>
            <Typography variant="h1">Page not found!</Typography>
            <Link to="/">Go back</Link>
        </>
    )
}

export default NotFound;
