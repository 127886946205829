import React from "react";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const PageTitle = (props: PageTitleProps) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Typography variant="h4">{props.title}</Typography>
            <Divider sx={{ mb: props.mb || 4 }} />
        </Box>
    );
}

export interface PageTitleProps {
    title: string,
    mb?: number
}
