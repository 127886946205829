export const IS_SIDEBAR_ACTIVE = 'IS_SIDEBAR_ACTIVE';
export const MENU_STATE = 'MENU_STATE';
export const SELECTED_MENU_ITEM = 'SELECTED_MENU_ITEM';

export type SidebarActions = IsSidebarActiveAction | MenuStateAction | SelectedMenuItemAction;

export interface IsSidebarActiveAction {
    type: typeof IS_SIDEBAR_ACTIVE;
    payload: boolean;
}

export interface MenuStateAction {
    type: typeof MENU_STATE;
    payload: Record<string, boolean>;
}

export interface SelectedMenuItemAction {
    type: typeof SELECTED_MENU_ITEM;
    payload: string;
}

export const isSideBarActiveAction = (isSidebarActive: boolean): IsSidebarActiveAction => {
    return {
        type: IS_SIDEBAR_ACTIVE,
        payload: isSidebarActive
    };
};

export const menuStateAction = (menuState: Record<string, boolean>): MenuStateAction => {
    return {
        type: MENU_STATE,
        payload: menuState
    };
};

export const selectedMenuItemAction = (selectedMenuItem: string): SelectedMenuItemAction => {
    return {
        type: SELECTED_MENU_ITEM,
        payload: selectedMenuItem
    };
};
