import React from "react"
import { Tooltip, Typography, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";
import CountUp from 'react-countup';

const MetricCard = (props: MetricCardProps) => {
    return (
        <Card sx={{ p: 1 }} style={{ height: "100%" }} elevation={3}>
            <CardHeader title={props.title} subheader={props.subTitle}
                action={
                    props.downloadableFunction ?
                        <Tooltip title={props.downloadMsg ? props.downloadMsg : "Download"}>
                            <IconButton disabled={props.metric == 0} aria-label="download" onClick={() => props.downloadableFunction ? props.downloadableFunction() : null}>
                                <Download />
                            </IconButton>
                        </Tooltip> : ''
                }
            />
            <CardContent >
                <Typography variant="h3" component="div">
                    {props.metric ? <CountUp end={props.metric} decimals={0} duration={props.animationDuration} /> : 0}
                </Typography>
            </CardContent>
        </Card>
    )
}

interface MetricCardProps {
    title: string,
    subTitle?: string,
    metric?: number,
    downloadableFunction?: Function,
    downloadMsg?: string,
    elevation?: number,
    animationDuration?: number,
}

export default MetricCard;