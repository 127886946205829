
import React from "react";
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';

export default function SelectDropdown(props: SelectDropdown) {
    const { name, label, value, error = null, options, onChange } = props;
    return (
        <FormControl size="small"  variant="outlined" {...(error && { error: true })} fullWidth sx={{ m: 1 }} >
            <InputLabel>{label}</InputLabel>
            <Select label={label} name={name} onChange={onChange} value={value} sx={{ textAlign: 'left'}}>
                {options.map((item: any) => (
                    <MenuItem key={item.id} value={item.id} >
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

interface SelectDropdown {
    name: string,
    label: string,
    options?: any,
    value?: Array<string>,
    onChange?:any
    error?:any;
    
}
