import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { routes } from "src/data/Routes";
import { useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { DEFAULT_FORM_RESPONSE, DEFAULT_PAGE_SIZE } from "src/constants/FormResponse";
import React, {useEffect, useState} from "react";
import { Grid } from "@mui/material";
import { PageTitle } from "src/components/display/PageTitle";
import DateRangeForm from "src/components/forms/DateRangeForm";
import { Box} from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CommercialInvoiceStatusTableColumns } from "src/views/invoices/TableColumns";


const CommercialInvoiceStatusDateRange = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const cardKey = "Invoices Generated";

    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const cardData = useSelector((state: CombineReducerState) => state.home.cardData);
    const [response, setResponse] = useState(DEFAULT_FORM_RESPONSE);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);

    // Since there is a "Invoices Generated" card at the home page, the data will be loaded there.
    // In that case, we will use the data stored in redux to display on this page without calling the API again.

    useEffect(() => {
        if(cardData[cardKey].isLoaded) {
            setResponse({...response, data: cardData[cardKey].data})
        }
    },[]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <PageTitle title="Commercial Invoice Status in date range" />

            <DateRangeForm url={baseUrl + routes.invoices.CommercialInvoiceStatusInDateRange} handler={setResponse} />

            <Box style={{ width: "100%", marginTop: "32px" }}>
                <DataGrid
                    rows={response.data}
                    getRowId={row => row.shipmentId}
                    columns={CommercialInvoiceStatusTableColumns}
                    pageSize={pageSize}
                    pagination
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    density="compact"
                    loading={isLoading}
                />
            </Box>
        </Grid>
    )
};

export default CommercialInvoiceStatusDateRange;