import { GridColDef } from "@mui/x-data-grid";

export const MERCHANT_IDS:string = "merchantIds";

export const CREATE_ADD_ON_SUBSCRIPTION_REQUEST : createAddOnSubscription = {
    merchantIds: "",
    marketplace: "",
    domainName: "",
    addOnSubscriptionType: "",
    addOnSubscriptionTier: "",
    baseSubscriptionType: ""
}

interface createAddOnSubscription extends IObjectKeys {
    merchantIds: string,
    marketplace: string,
    domainName: string,
    addOnSubscriptionType: string,
    baseSubscriptionType: string
}

export const REMOVE_ADD_ON_SUBSCRIPTION_REQUEST : removeAddOnSubscription = {
    merchantIds: "",
    marketplace: "",
    domainName: "",
    addOnSubscriptionType: "",
    baseSubscriptionType: ""
}

interface removeAddOnSubscription extends IObjectKeys {
    merchantIds: string,
    marketplace: string,
    domainName: string,
    addOnSubscriptionType: string,
    baseSubscriptionType: string
}

interface IObjectKeys {
    [key: string]: string | Array<string>;
}

export const DEFAULT_PAGE_SIZE = 10;

export const ERROR_MESSAGE = {
    InvalidParams: "Invalid parameters. Make sure you are entering appropriate details.",
    ServerError: "Failed to fetch the data from the server, please try again",
}

export const  SUBSCRIPTION_DETAILS = {
    COMMA_SEPARATOR: ",",
    SUCCESS: "Success"
};

export const CREATE_SUBSCRIPTION_TABLE_PROPERTIES : GridColDef[] = [
  {
    headerName: "Merchant Id",
    field: "merchantId",
    flex: 1,
  },
  {
    headerName: "Subscription Type",
    field: "subscriptionType",
    flex: 1,
  },
  {
    headerName: "Subscription Tier",
    field: "subscriptionTier",
    flex: 1,
  },
  {
    headerName: "Operation",
    field: "requestSuccessful",
    flex: 1,
  },
];
export const REMOVE_SUBSCRIPTION_TABLE_PROPERTIES : GridColDef[] = [
    {
      headerName: "Merchant Id",
      field: "merchantId",
      flex: 1,
    },
    {
      headerName: "Subscription Type",
      field: "subscriptionType",
      flex: 1,
    },
    {
      headerName: "Operation",
      field: "requestSuccessful",
      flex: 1,
    },
  ];