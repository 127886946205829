import React, { useEffect, useRef, useState } from "react"
import { Dayjs } from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isLoadingAction } from "src/views/HomeActions";
import { Alert, Button, Container, Grid, LinearProgress, Paper, TextField } from "@mui/material";
import dayjs from 'dayjs';
import apiRequest from "src/utils/ApiRequest";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { Errors } from "src/constants/Errors";

let formattedStartDate = "";
let formattedEndDate = "";

const DateRangeForm = (props: DateRangeFormProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);

    // Props
    const url = props.url;
    const handler = props.handler;
    const buttonText = props.buttonText || "Submit";
    const elevation = props.elevation || 4;
    const dateFormat = props.dateFormat || "YYYY-MM-DD";
    const urlParams = new URLSearchParams(window.location.search);
    const businessLocation = props.businessLocation;
    const setLastWeekDate = props.setLastWeekDate;

    // States
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [emptyInputError, setEmptyInputError] = useState(false);
    const [dateRangeError, setDateRangeError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const didMount = useRef(true);

    // Fill the form inputs if "startDate" and "endDate" are present in search params.
    useEffect(() => {
        const pStartDate = urlParams.get("startDate");
        if (pStartDate) setStartDate(dayjs(new Date(pStartDate)));
        const pEndDate = urlParams.get("endDate");
        if (pEndDate) setEndDate(dayjs(new Date(pEndDate)));

        if (setLastWeekDate && !pStartDate && !pEndDate) { 
            setLastWeekDateFunc();
        }
    }, []);

    useEffect(() => {
        formattedStartDate = startDate?.format(dateFormat) || "";
        formattedEndDate = endDate?.format(dateFormat) || "";

        if(didMount.current && setLastWeekDate && endDate) {
            handleSubmit()
            didMount.current = false;
        }
    }, [startDate, endDate])

    useEffect(() => {
        handleSubmit()
    }, [businessLocation])


    const handleSubmit = async () => {
        clearErrors();
        if (formattedStartDate === "" || formattedEndDate === "") {
            setEmptyInputError(true);
            return;
        };
        if (endDate?.isBefore(startDate || "")) {
            setDateRangeError(true);
            return;
        }
        if (isLoading) return;
        window.history.pushState(null, "", "?startDate=" + formattedStartDate + "&endDate=" + formattedEndDate);

        // Setup for API call
        dispatch(isLoadingAction(true));

        try {
            const response = await apiRequest(url, buildQueryParam());
            if (response.ok) {
                const data = await response.json();
                handler({
                    data,
                    error: false,
                    status: response.status,
                    apiErrorMessage: null,
                });
                setApiError(false);
                props.dayDifference && startDate? props.dayDifference(endDate?.diff(startDate, "day")!+1) : null;
            } else {
                const error = await response.json();
                handler({
                    data: [],
                    error: true,
                    status: response.status,
                    apiErrorMessage: error.message,
                });
                setApiError(true);
                setApiErrorMessage(error.message);
            }
        } catch (err) {
            console.error(err);
            handler({
                data: [],
                error: true,
                status: Errors.StatusCode.SERVER_ERROR,
                apiErrorMessage: Errors.Message.SERVER_ERROR,
            });
            setApiError(true);
            setApiErrorMessage(Errors.Message.SERVER_ERROR);
        }
        dispatch(isLoadingAction(false));
    }

    const buildQueryParam = () => {
        if (businessLocation) {
            return { startDate: formattedStartDate, endDate: formattedEndDate, businessLocation: businessLocation }
        } else {
            return { startDate: formattedStartDate, endDate: formattedEndDate }
        }
    }

    const clearErrors = () => {
        setDateRangeError(false);
        setEmptyInputError(false);
    }

    const setLastWeekDateFunc = () => {
        const currentDate = dayjs();
        const lastSunday = currentDate.startOf('week');
        const lastSaturday = lastSunday.subtract(1, 'day');
        const lastMonday = lastSaturday.subtract(6, 'day');
        setStartDate(lastMonday)
        setEndDate(lastSaturday)
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={elevation}>
                <Grid container alignItems="center" justifyContent="center" sx={{ paddingX: 3, paddingY: 2 }}>
                    <Grid item sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item sm={12} sx={{ m: 2 }}>
                        <Button variant="contained" disabled={isLoading} onClick={handleSubmit} fullWidth>{buttonText}</Button>
                    </Grid>
                    <Grid item sm={12}>
                        {emptyInputError && <Alert severity="error" onClose={() => setEmptyInputError(false)}>Please enter the dates...</Alert>}
                        {dateRangeError && <Alert severity="error" onClose={() => setDateRangeError(false)}>Start date should be before than End date.</Alert>}
                        {apiError && <Alert severity="error" onClose={() => setApiError(false)}>{apiErrorMessage}</Alert>}
                        {isLoading && <LinearProgress />}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

interface DateRangeFormProps {
    url: string,
    handler: any,
    buttonText?: string,
    elevation?: number,
    dateFormat?: string,
    businessLocation?: string,
    setLastWeekDate?: boolean,
    dayDifference?: Function
}

export default DateRangeForm;