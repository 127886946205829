export const IS_DARK_THEME = 'IS_DARK_THEME';
export const STAGE = 'STAGE';
export const BASE_URL = 'BASE_URL';

export type NavbarActions = IsDarkThemeAction | IsBetaAction | FetchBaseUrlAction;

export interface  IsBetaAction {
    type: typeof STAGE;
    payload: string;
};

export interface IsDarkThemeAction {
    type: typeof IS_DARK_THEME;
    payload: boolean;
};

export interface FetchBaseUrlAction {
    type: typeof BASE_URL;
    payload: string
};

export const isDarkThemeAction = (isDarkTheme: boolean): IsDarkThemeAction => {
    return {
        type: IS_DARK_THEME,
        payload: isDarkTheme
    };
};

export const stageAction = (stage: string): IsBetaAction => {
    return {
        type: STAGE,
        payload: stage
    };
};

export const fetchBaseUrlAction = (baseUrl: string): FetchBaseUrlAction => {
    return {
        type: BASE_URL,
        payload: baseUrl
    };
};
