export const AnkanaAllowlistUtility = {
    // Base Urls
    BaseUrlForPerformAnkanaAllowlist: "/performAnkanaAllowlist",

    // UI Related config
    ErrorMessages: {
        InvalidParams: "Invalid parameters. Make sure you are entering appropriate details.",
        ServerError: "Failed to fetch the data from the server. Debug error in lambda",

    },
    HelpSectionURL: "https://w.amazon.com/bin/view/Global_Selling_IN/SelfService/AnkanaAllowListing/",
    TableProperties: [
        {
            title: "Seller Id",
            data: "seller",
            Customizations: {}
        },
        {
            title: "Operation Status",
            data: "requestSuccessful",
            Customizations: {}
        },
        {
            title: "Ankana Tag applied",
            data: "ankanaTag",
            Customizations: {}
        }
    ],
    IsColumnSearchable: {
        columnDefs: [
            { searchable: true, targets: 0 },
            { searchable: true, targets: 1 },
            { searchable: false, targets: 2 }
        ]
    },
    SearchPlaceholder: {
        language: {
            searchPlaceholder: "Status / Seller Id"
        }
    },


    SuccessfulAnkanaAllowList: 'Successful ankana allowlist',

    FailedAnkanaAllowList: 'Failed ankana allowlist',

    MerchantId: 'Merchant Id',
    AnkanaTag: "Ankana Tag",
    IsApplied: "Is Applied",

    ANKANA_SUCCESS: 204,
    ANKANA_FAILURE: 404
};

