import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { darken, lighten, useTheme } from "@mui/material";
import { Event, SellerRegistrationStatus } from "src/constants/FormResponse";

export const Row = (props: RowProps) => {
    const theme = useTheme();

    const row = props.row;
    const subrows = props.subrows;
    const [isOpen, setIsOpen] = useState(false);

    const rowBgColor = theme.palette.mode === "light" ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.75);
    const activeRowBgColor = theme.palette.mode === "light" ? lighten(theme.palette.success.light, 0.85) : darken(theme.palette.success.dark, 0.75);
    const tableBgColor = theme.palette.mode === "light" ? lighten(theme.palette.grey[500], 0.85) : darken(theme.palette.grey[500], 0.75);

    return (
        <Fragment>
            <TableRow sx={{ backgroundColor: isOpen ? activeRowBgColor : "inherit", cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)}>
                <TableCell>{row.sellerId}</TableCell>
                <TableCell>{row.createdTime}</TableCell>
                <TableCell>{row.languageOfPreference}</TableCell>
                <TableCell>{row.sourceMerchantId}</TableCell>
                <TableCell>{row.sourceChannel}</TableCell>
                <TableCell>{row.updatedTime}</TableCell>
                <TableCell>{row.countryOfEstablishment}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={100}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" sx={{ backgroundColor: tableBgColor }}>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: rowBgColor }}>
                                        <TableCell>Page Order</TableCell>
                                        <TableCell>Page Name</TableCell>
                                        <TableCell>Page State</TableCell>
                                        <TableCell>Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subrows.map(event => (
                                        <TableRow key={event.eventTime}>
                                            <TableCell>{event.stateOrder}</TableCell>
                                            <TableCell>{event.stateName}</TableCell>
                                            <TableCell>{event.status}</TableCell>
                                            <TableCell>{event.eventTime}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export interface RowProps {
    row: SellerRegistrationStatus,
    subrows: Array<Event>,
}
