import {BASE_TITLE, DELIMITER, PageProps} from "src/components/App";
import {
    Grid,
    Box,
    Button,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    List,
    ListItemText, SelectChangeEvent, FormControl, InputLabel, Select, MenuItem, Paper
} from "@mui/material";
import {PageTitle} from "src/components/display/PageTitle";
import React, {useEffect, useState} from "react";
import apiRequest from "src/utils/ApiRequest";
import {useSelector} from "react-redux";
import {CombineReducerState} from "src/combineReducers";
import {routes} from "src/data/Routes";
import {DataGrid, GridSelectionModel} from "@mui/x-data-grid";
import ListItem from "@mui/material/ListItem";
import {AllowlistGSTINColumns} from "src/views/utilities/allowed-gstin-list/TableColumns";
import {
    ALLOWED_GSTIN_PROGRAMS,
    GET_FAILED_MSG,
    GET_INPROGRESS_MSG,
    GET_SUCCESS_MSG
} from "src/constants/AllowedGSTIN";
import {BRASS_ALLOWED_STATUS, BRASS_AUTH_DENIED_MSG} from "src/constants/BrassConstants";

const GetAllowedGSTINList = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMessage , setLoadingMessage] = useState<string>("")
    const [GSTINList, setGSTINList] = useState<Array<string>>([]);
    const [selectedId, setSelectedId] = useState<GridSelectionModel>([]);
    const [rowData, setRowData] = useState<Array<{ id_: number; id: string; }>>([]);
    const [program, setProgram] = useState<string>("");

    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const url: string =  baseUrl + routes.isp.gst.getGSTAllowlist;
    const brassAuthUrl: string = baseUrl + routes.brassAuth;

    const handler = async (props: { task: string; list: any; })=> {
        setLoading(true);
        setLoadingMessage(props.task + GET_INPROGRESS_MSG);

        window.history.pushState(
            null,
            "",
            `?program=${program}&task=${props.task}&allowlist=${props.list}`
        );

        try {
            let payload = {
                path: routes.isp.gst.getGSTAllowlist,
                program: program,
                task: props.task
            }

            const brassResponse = await apiRequest(brassAuthUrl, payload);

            if(brassResponse.ok){
                const brass_data = await brassResponse.json();
                if (brass_data.authStatus === BRASS_ALLOWED_STATUS){
                    let payload = {
                        program: program,
                        task: props.task,
                        allowlist: props.list
                    };

                    const response = await apiRequest(url, payload);
                    if (!response.ok){
                        setLoadingMessage(props.task + GET_FAILED_MSG);
                    }else{
                        const data = await response.json();
                        props.task == "get"? setGSTINList(data): setGSTINList(GSTINList.filter(GSTIN =>
                            !selectedId.includes(GSTIN)));
                        setLoadingMessage(props.task + GET_SUCCESS_MSG);
                    }
                }else {
                    setLoadingMessage(BRASS_AUTH_DENIED_MSG);
                }
            }else {
                setLoadingMessage(props.task + GET_FAILED_MSG);
            }
        }catch (err) {
            setLoadingMessage(props.task + GET_FAILED_MSG)
        }
        setLoading(false);
    }

    useEffect(() => {
        let rows: {id_: number, id: string}[] = [];
        GSTINList.forEach((GSTIN, index) => {
            (rows.push({id_: index, id: GSTIN}));
        })
        setRowData(rows);
    }, [GSTINList]);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleDelete = () => {
        handleClose();
        handler({task: "delete", list: selectedId} );
    }

    const handleFetch = () => {
        handler({task: "get", list: []});
    }

    const handleProgramChange = (event: SelectChangeEvent) => {
        setProgram(event.target.value);
    }

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <PageTitle title="Get Allowed GSTIN List" />
            <Box>
                <Paper elevation={4} sx={{ paddingX: 5, paddingY: 3 }}>
                    <Box sx={{ paddingX: 3, paddingY: 3}}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="program">Program</InputLabel>
                            <Select
                                labelId="programId"
                                label="Program"
                                value={program}
                                onChange={handleProgramChange}
                                sx={{ textAlign: "left" }}
                            >
                                {ALLOWED_GSTIN_PROGRAMS.map((program) => (
                                    <MenuItem
                                        key={program}
                                        value={program}
                                    >
                                        {program}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant="contained" sx={{mx: 1}} disabled={program === ""} onClick={() => handleFetch()}>Fetch</Button>
                    <Button variant="contained" sx={{mx: 1}} color="error" disabled={ selectedId.length == 0 } onClick={handleClickOpen}>
                        Delete Selected
                    </Button>
                    <Box style={{marginTop: "32px" }}>
                        Status: {loadingMessage}
                    </Box>
                </Paper>
            </Box>

            <Box style={{ width: "100%", marginTop: "32px" }}>
                {loading && <LinearProgress />}
                <DataGrid
                    columns={AllowlistGSTINColumns}
                    rows={rowData}
                    autoHeight
                    checkboxSelection
                    density="compact"
                    disableSelectionOnClick
                    onSelectionModelChange={setSelectedId}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete selected GSTIN?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Following GSTIN will be deleted from the allowlist:
                        <List dense={true}>
                            {selectedId.length != 0 && selectedId.map((GSTIN) => (
                                <ListItem>
                                    <ListItemText
                                        primary={GSTIN}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default GetAllowedGSTINList;