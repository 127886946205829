export const DEVO_MARKETPLACE_ID_TO_COUNTRY_MAP : MarketplaceIdToCountryMapInterface = {
    "India" : "A2XZLSVIQ0F4JT" ,
    "Turkey" : "A3CQBQD3RGPJR8" ,
    "Saudi Arabia" : "A1MQPSGJ6U9Q54",
    "United Arab Emirates" : "A34GYYCZVDBSIK",
    "Egypt" : "AUJPM9XGFJRC7" ,
    "US" : "ATVPDKIKX0DER",
    "Canada" : "A2EUQ1WTGCTBG2",
    "Mexico" : "A3P3J5A7D2ZVXI",
    "Brazil" : "AZXD3QD5B39HD" ,
    "Japan" : "A1VC38T7YXB528" ,
    "Australia" : "A1RNPCQ4K8U27I",
    "Singapore" : "A1DQ6JV7I20JSG"
}

export const PROD_MARKETPLACE_ID_TO_COUNTRY_MAP: MarketplaceIdToCountryMapInterface = {
    "India" : "A21TJRUUN4KGV" ,
    "Turkey" : "A33AVAJ2PDY3EV" ,
    "Saudi Arabia" : "A17E79C6D8DWNP",
    "United Arab Emirates" : "A2VIGQ35RCS4UG",
    "Egypt" : "ARBP9OOSHTCHU" ,
    "US" : "ATVPDKIKX0DER",
    "Canada" : "A2EUQ1WTGCTBG2",
    "Mexico" : "A1AM78C64UM0Y8",
    "Brazil" : "A2Q3Y263D00KWC" ,
    "Japan" : "A1VC38T7YXB528" ,
    "Australia" : "A39IBJ37TRP1C6",
    "Singapore" : "A19VAU5U5O7RUS"
}
export const DEVO_COUNTRY_CODE_TO_REALM_MAP: { [key: string]: string[] } = {
    "EU" : ["A2XZLSVIQ0F4JT", "A3CQBQD3RGPJR8", "A1MQPSGJ6U9Q54", "A34GYYCZVDBSIK", "AUJPM9XGFJRC7"],
    "NA" : ["ATVPDKIKX0DER", "A2EUQ1WTGCTBG2", "A3P3J5A7D2ZVXI", "AZXD3QD5B39HD"],
    "FE" : ["A1VC38T7YXB528", "A1RNPCQ4K8U27I", "A1DQ6JV7I20JSG"]
}

export const PROD_COUNTRY_CODE_TO_REALM_MAP: { [key: string]: string[] } = {
    "EU" : ["A21TJRUUN4KGV", "A33AVAJ2PDY3EV", "A17E79C6D8DWNP", "A2VIGQ35RCS4UG", "ARBP9OOSHTCHU"],
    "NA" : ["ATVPDKIKX0DER", "A2EUQ1WTGCTBG2", "A1AM78C64UM0Y8", "A2Q3Y263D00KWC"],
    "FE" : ["A1VC38T7YXB528", "A39IBJ37TRP1C6", "A19VAU5U5O7RUS"]
}

interface MarketplaceIdToCountryMapInterface { [key: string]: string; }