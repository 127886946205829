export const DEFAULT_FORM_RESPONSE: FormResponse = {
    data: [],
    error: false,
    status: -1,
    apiErrorMessage: "" || null,
}

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_SELLER_DATA = {
    sellerId: "",
    sourceMerchantId: "",
    createdTime: "",
    updatedTime: "",
    eventDate: "",
    sourceChannel: "",
    languageOfPreference: "",
    countryOfEstablishment: "",
}

export const AWB_INVOICE_FAILURE_RESPONSE = {
    data: {
        failedInvoices: [],
        totalInvoices: 0,
        transferComplete: 0,
        assemblyComplete: 0,
        creationFailed: 0,
        aggregationFailed: 0,
        transferFailed: 0
    },
    error: false,
    status: -1,
    apiErrorMessage: "" || null,
};

export const AWB_INVOICE_STATS = {
    totalInvoicesPercentage: "-",
    transferCompletePercentage: "-",
    failedInvoicesPercentage: "-",
    assemblyCompletePercentage: "-",
    creationFailedPercentage: "-",
    aggregationFailedPercentage: "-",
    transferFailedPercentage: "-",
}

export const DEFAULT_REG_STATS = {
    total: 0,
    completed: 0,
    notCompleted: 0
}

export const SELLER_EVENTS: Array<Event> = [];

interface FormResponse {
    data: Array<Object>,
    error: false,
    status: number,
    apiErrorMessage: string | null,
}

export interface SellerRegistrationStatus {
    sellerId: string,
    sourceMerchantId: string,
    events: Array<Event>,
    createdTime: string,
    updatedTime: string,
    eventDate: string,
    sourceChannel: string,
    languageOfPreference: string,
    countryOfEstablishment: string,
}

export interface Event {
    stateOrder: number,
    stateName: string,
    status: string,
    eventTime: string,
    sourceChannel: string,
    sourceMerchantID: string,
}

export const initialAnkanaFieldValues = {
    sellerIdsList: [],
    marketplaceId: "",
    ankanaTag: "",
    operation: ""
};