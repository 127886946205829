import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
    {
        field: "seller ",
        headerName: "Seller Id",
        flex: 1,
    },
    {
        field: "requestSuccessful",
        headerName: "Operation Status",
        flex: 1,
    },
    {
        field: "ankanaTag",
        headerName: "Ankana Tag applied",
        flex: 1,
    },

];
