import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Button,
  Alert,
  AlertTitle,
  TablePagination,
} from "@mui/material";
import SellerFlagModal from "./SellerFlagModal";

const SellerData = (props: SellerData) => {
  const { data, modalData, marketplace, flagValue } = props;
  const [selectSellerId, setSellectSellerId] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [row, setRow] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleOpen = (id: string) => {
    rowData(modalData, id);
    setSellectSellerId(id);
    setOpen(true);
  };

  const formatData = (responseData: any, marketplace: string) => {
    let dataList: any = [];
    let ids = Object.keys(responseData);
    if (responseData) {
      if (displayAlert === true) {
        setDisplayAlert(false);
      }
      ids.forEach((id) => {
        let item: { [key: string]: string } = {};
        item["operationStatus"] =
          responseData[id].flagOperationStatus ?? "UNKNOWN";
        item["sellerId"] = id;
        if (responseData[id].errorType) {
          item["flag"] = "UNKNOWN";
        } else {
          if (
            responseData[id].marketplaceIdToSellerMarketplaceFlagValueMap[
              marketplace
            ]
          ) {
            item["flag"] =
              responseData[id].marketplaceIdToSellerMarketplaceFlagValueMap[
                marketplace
              ].flagValue;
          } else {
            item["flag"] = "-";
          }
        }
        dataList.push(item);
      });
    } else {
      if (displayAlert === false) {
        setDisplayAlert(true);
      }
    }
    return dataList;
  };

  const sellerData = formatData(data, marketplace);

  const rowData = (modalData: any, id: string) => {
    let modalDataForSeller = modalData[id];
    let resons = modalDataForSeller["reasons"];
    if (resons) {
      let arr = modalDataForSeller["reasons"].map((row: any) => row);
      setRow(arr);
      setErrorMessage("");
    } else {
      setErrorMessage(modalDataForSeller.errorMessage);
      setRow([]);
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableBody>
        {sellerData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((seller: any) => (
            <TableRow key={seller.sellerId}>
              <TableCell>{seller.sellerId}</TableCell>
              <TableCell>{seller.operationStatus}</TableCell>
              <TableCell>{seller.flag}</TableCell>
              <TableCell>
                <Button onClick={() => handleOpen(seller.sellerId)}>
                  Details
                </Button>
                <SellerFlagModal
                  row={row}
                  modalData={modalData}
                  errorMessage={errorMessage}
                  selectSellerId={selectSellerId}
                  open={open}
                  handleClose={handleClose}
                  flagValue={flagValue}
                />
              </TableCell>
            </TableRow>
          ))}
        {displayAlert && (
          <TableRow>
            <TableCell colSpan={4}>
              <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                Couldn't complete seller flag details operation —{" "}
                <strong>check your permissions!</strong>
              </Alert>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sellerData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ float: "left" }}
      />
    </>
  );
};

interface SellerData {
  data: any;
  modalData: any;
  marketplace: string;
  flagValue: string;
}

export default SellerData;