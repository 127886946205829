import React, { useState } from 'react'
import { Alert, Box, Button, Container, LinearProgress, TextField,} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CombineReducerState } from 'src/combineReducers';
import { isLoadingAction } from 'src/views/HomeActions';
import apiRequest from 'src/utils/ApiRequest';

const SubscriberForm = (props:SubscriberFormProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);

    const url = props.url;
    const subscriberToLabel = props.subscriberTo;
    const subscribersLabel = props.subscriber;
    const buttonName = props.buttonName;

    const [alias, setAlias] = useState<String>("");
    const [subscribers, setSubscribers] = useState<String>("");
    const [apiErrorAlert, setApiErrorAlert] = useState<boolean>(false);
    const [successAlert, setSuccessAlert] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<String>("");

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        clearErrors();
        if (isLoading) return;
        dispatch(isLoadingAction(true));
        try{
            const response = await apiRequest(url, {alias: alias, subscribers: subscribers});
            if(response.ok) {
                setSuccessMessage(JSON.stringify(subscribers) + " subscribed to " + JSON.stringify(alias));
                setSuccessAlert(true);
            } else {
                setApiErrorAlert(true);
            }
        } catch (err) {
            console.error(err);
        }
        dispatch(isLoadingAction(false));
        clearData();
        }

    const clearErrors = () => {
        setSuccessAlert(false);
        setApiErrorAlert(false);
    }

    const clearData  = () => {
        setAlias("");
        setSubscribers("");
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, flexDirection: 'column', alignItems: 'center'}}>
                <Alert severity="info">Subscribe to Credit Score Reports.</Alert>
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        onChange={(e) => setAlias(e.target.value)}
                        value={alias} label={subscriberToLabel}
                        autoComplete="off"
                        fullWidth
                        required/>
                    <TextField
                        margin="normal"
                        onChange={(e) => setSubscribers(e.target.value)}
                        value={subscribers}
                        label={subscribersLabel}
                        autoComplete="off"
                        fullWidth
                        required/>
                    <Button variant="contained" sx={{mt: 3, mb: 2}} type={"submit"}>{buttonName}</Button>
                    {apiErrorAlert && <Alert severity="error" onClose={() => setApiErrorAlert(false)}><strong>ERROR!</strong> API Failure!</Alert>}
                    {successAlert && <Alert severity="success" onClose={() => setSuccessAlert(false)}><strong>SUCCESS!</strong> {successMessage}</Alert>}
                    {isLoading && <LinearProgress/>}
                </Box>
            </Box>
        </Container>
    );
}

interface SubscriberFormProps {
    url: string,
    subscriberTo: string,
    subscriber: string,
    buttonName: string
}

export default SubscriberForm;