import React, {useEffect, useState} from "react"

import {Grid, Card, CardContent, CardHeader} from "@mui/material";
import {CombineReducerState} from "src/combineReducers";
import {useSelector, useDispatch} from "react-redux";
import {routes} from "src/data/Routes";
import SellerRegistrationPieChart from "src/components/charts/SellerRegistrationPieChart";
import SellerRegistrationScatterChart from "src/components/charts/SellerRegistrationScatterChart"
import AccordianTitle from "src/components/display/AccordianTitle";
import {isLoadingAction} from "src/views/HomeActions";
import {Alert, LinearProgress, CircularProgress, Tabs, Tab} from "@mui/material";
import {
    DOMESTIC_MARKETPLACE_SUPPORTED_COUNTRY,
    JITA_SUPPORT_COUNTRY_MAP,
    JITA_INCEPTION_DATE
} from "src/constants/JITAConstant";
import apiRequest from "src/utils/ApiRequest";
import dayjs from "dayjs";
import {GlobalSellerRegistrationDataForCharts} from "src/constants/ChartConstants";
import {SCATTER_CHART,PIE_CHART,dateFormat,primaryDataKey,secondaryDataKey,DOMESTIC_SUFFIX} from "src/constants/ChartConstants";

const GlobalSellerRegistrationJitaMetricsHomepageCards = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const [chartData, setChartData] = useState<Array<GlobalSellerRegistrationDataForCharts>>([]);
    const [chartType, setChartType] = useState(SCATTER_CHART);
    const [apiError, setApiError] = useState(false);
    const bannerContent: string = "In this dashboard, users can easily identity number of sellers registering on Orbis for global selling and are experiencing SAT(Seller Assistance Tool)";
    const apiErrorMessage: string = "Internal Server Error , failed to fetch complete data ";
    const fetchDataBasedOnBusinessLocation = async (location: string) => {
        return new Promise<Array<Object>>(async (resolve) => {
            try {
                const response = await apiRequest(baseUrl + routes.sellerRegistration.globalRegistrationMetrics, buildQueryParam(location));
                if (response.ok) {
                    const data = await response.json();
                    resolve(data);
                } else {
                    const error = await response.json();
                    console.log(error.message);
                    setApiError(true);
                    resolve([]);
                }
            } catch (err) {
                console.error(err);
                setApiError(true);
                resolve([]);
            }
        })
    }

    const getMetrics = async () => {

        if (isLoading) return;

        // Setup for API call
        dispatch(isLoadingAction(true));

        const dataPromisesForBusinessLocations: Promise<any>[] = [];
        const listOfCountries = Object.keys(JITA_SUPPORT_COUNTRY_MAP);
        DOMESTIC_MARKETPLACE_SUPPORTED_COUNTRY.forEach((location) => {
            listOfCountries.push(location + DOMESTIC_SUFFIX);
        })
        listOfCountries.forEach((location) => {
            dataPromisesForBusinessLocations.push(fetchDataBasedOnBusinessLocation(location));
        });
        const results = await Promise.all(dataPromisesForBusinessLocations);
        const countryWiseData: { [key: string]: {[key:string]:string} } = {};
        for (let countryIndex = 0; countryIndex < dataPromisesForBusinessLocations.length; countryIndex++) {
            countryWiseData[listOfCountries[countryIndex]] = results[countryIndex];
        }

        const dataForGraphs: GlobalSellerRegistrationDataForCharts[] = [];
        Object.keys(countryWiseData).forEach((country) => {
            if (!country.includes(DOMESTIC_SUFFIX) && Object.keys(countryWiseData[country]).length !== 0) {
                const element: GlobalSellerRegistrationDataForCharts = {
                    name: country,
                    globalCount: parseInt(countryWiseData[country].cumulatedCount),
                    domesticCount: 0
                }

                if (DOMESTIC_MARKETPLACE_SUPPORTED_COUNTRY.includes(country)) {
                    element.domesticCount = parseInt(countryWiseData[country + DOMESTIC_SUFFIX].cumulatedCount);
                    element.globalCount -= element.domesticCount;
                }

                dataForGraphs.push(element);
            }
        })
        setChartData(dataForGraphs);

        dispatch(isLoadingAction(false));
    }

    const buildQueryParam = (location: string) => {
        return {
            startDate: JITA_INCEPTION_DATE,
            endDate: dayjs().format(dateFormat),
            businessLocation: location,
            IsAggregateRequest: "true"
        };
    }

    useEffect(() => {
        getMetrics();
    }, [])

    const handleChartChange = (event: React.SyntheticEvent, newValue: string) => {
        setChartType(newValue);
    }

    return (
        <Grid container justifyContent={"center"} alignItems="center" marginTop={10}>
            <Grid container justifyContent={"center"} alignItems={"center"} marginBottom={4}>
                <Grid xs={6} alignItems={"center"}>
                    <AccordianTitle title={"Global Seller Registration Metrics"} subTitle={"sellers experiencing SAT"}
                                    cardContent={bannerContent} elevation={3}/>
                    {isLoading && <LinearProgress/>}
                    {apiError && <Alert severity="error" onClose={() => setApiError(false)}>{apiErrorMessage}</Alert>}
                    <Tabs variant="fullWidth" onChange={handleChartChange} value={chartType}>
                        <Tab label="Scatter View " value={SCATTER_CHART}/>
                        <Tab label="Pie View" value={PIE_CHART}/>
                    </Tabs>
                    <Grid xs={12} marginBottom={2}>
                        <Card sx={{p: 2}} elevation={3}>
                            <CardContent style={{width: "95%", height: "540px"}}>
                                {isLoading ? <CircularProgress sx={{mt: 18}} size={200}/> :
                                    chartType == SCATTER_CHART ?
                                        <SellerRegistrationScatterChart
                                            data={chartData} primaryDataKey={primaryDataKey}
                                            secondaryDataKey={secondaryDataKey}
                                        />
                                        :
                                        <>
                                            <CardHeader title={`Outer chart - Global | Inner chart - Domestic`}
                                                        sx={{height: "3px", p: 0}}/>
                                            <SellerRegistrationPieChart
                                                data={chartData} primaryDataKey={primaryDataKey}
                                                secondaryDataKey={secondaryDataKey}
                                            />
                                        </>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GlobalSellerRegistrationJitaMetricsHomepageCards
