export const routes = {
    index: "/",
    sellerOrders: {
        listOrders: "/seller/orders/",
        listOrdersInDateRange: "/seller/orders/date-range/",
    },
    payments: {
        l2Rejects: "/payments/l2-rejects/",
        tickets: "/payments/tickets/",
        opgspPaymentFailure: "/payments/opgsp-payment-failure/",
    },
    sellerRegistration: {
        metrics: "/seller/registration/metrics/",
        metricsInDateRange: "/seller/registration/metrics/date-range/",
        metricsOfDay: "/seller/registration/metrics/day/",
        metricsWithNoKYC: "/seller/registration/metrics/no-kyc/",
        ineligibleSellers: "/seller/registration/ineligible-sellers/",
        proactiveReplicationFailure: "/seller/registration/proactive-replication-failures/",
        carrierInfoData: "/seller/registration/carrier-info-data/",
        globalRegistrationMetrics: "/seller/registration/global-seller-registration-jita/"
    },
    offerListings: {
        invalidPTC: "/offers/blocked/ptc/",
        invalidPTCInDateRange: "/offers/blocked/ptc/date-range/",
    },
    invoices: {
        CommercialInvoiceStatus: "/invoices/commercial-invoice/",
        CommercialInvoiceStatusInDateRange: "/invoices/commercial-invoice/date-range",
        awbInvoiceFailure: "/invoices/awb-invoice-failure/",
    },
    sellerFlags: {
        performFlagUpdates: "/seller/flags/update/",
        getFlagDetails: "/seller/flags/details/",
        getFlagHistoryDetails: "/seller/flags/history-details/",

    },
    isp: {
        reports:{
            mtr: "/isp/reports/mtr-report/",
            settlement: "/isp/reports/settlement-report/",
            generateInventoryShipment: "/isp/reports/get-inventory-shipment-report/",
            inventoryShipment: "/isp/reports/inventory-shipment-report/",
            gstAggregatedReport: "/isp/reports/gst-aggregated-report/"
        },
        gst:{
            getGSTAllowlist:"/isp/allowed-gstin-list/",
            updateGSTAllowlist:"/isp/allowed-gstin-list/"
        },
        oocFBASellerOnboard:"/isp/oocfbaseller",
        ispOnboarding:"/isp/onboarding"
    },
    utilities: {
        encryptDecryptId: "/utilities/encrypt-decrypt-ids/",
        ankanaTag: "/utilities/perform-ankana-allowlist/",
        crescentSubscriber: "/utilities/crescent-subscriber/",
        applyAddOnSubscription: "/utilities/apply-add-on-subscription/",
        removeAddOnSubscription: "/utilities/remove-add-on-subscription/"
    },
    oocKyc: {
        customerJourney: "/kyc/customer-journey/"
    },
    crossLaunchMetrics: {
        scorpionMetrics: "/cross-launch-metrics/MonitorScorpionServiceData"
    },
    brassAuth: "/brass-auth/",
}

export const ApiGatewayBaseUrl = {
    beta: "https://av66w10bfl.execute-api.eu-west-1.amazonaws.com/prod",
    prod: "https://5jhpgqwh7f.execute-api.eu-west-1.amazonaws.com/prod",
}