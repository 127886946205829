export type GlobalSellerRegistrationDataForCharts = {
    name: string,
    globalCount: number,
    domesticCount?: number
}

export interface GlobalSellerRegistrationChartProps {
    data: GlobalSellerRegistrationDataForCharts[],
    primaryDataKey: string,
    secondaryDataKey?: string
}

export const pieChartColors = [
    "#FF6347",
    "#4682B4",
    "#7FFF00",
    "#8A2BE2",
    "#20B2AA",
    "#FFD700",
    "#DC143C",
    "#00BFFF",
    "#32CD32",
    "#FFA500",
    "#9932CC",
    "#2E8B57"
]

export const primaryBarColor = "#8884d8";

export const secondaryBarColor = "#82ca9d";

export const SCATTER_CHART = "scatterChart";

export const PIE_CHART = "pieChart";

export const dateFormat = "YYYY-MM-DD";

export const primaryDataKey = "globalCount";

export const secondaryDataKey = "domesticCount";

export const DOMESTIC_SUFFIX = "(Domestic)";