import React from "react";

const ReportGenerationStatus = (props:ReportGenerationStatusInterface) => {

    const selectBadgeType = () => {
        const { status } = props;
        if(status == "InQueue" || status == "InProgress") {
            return "#42a5f5";
        } else if(status == "Sidelined") {
            return "#ff9800";
        } else if(status == "Fatal" || status == "Cancelled") {
            return "#ef5350";
        } else if(status == "Done") {
            return "#4caf50";
        } else {
            return "#42a5f5";
        }
    }
    const buttonStyled = {
        border:"1px solid transparent", 
        background:selectBadgeType(), 
        color:"#fff", 
        padding:"8px 12px", 
        borderRadius:"4px",
        width: "149px",
    }

    return <div style={buttonStyled} >{props.status}</div>
}

interface ReportGenerationStatusInterface {
    status :string
}

export default ReportGenerationStatus;
