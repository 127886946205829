import React, { useEffect, useState } from "react"
import { Box, CircularProgress, darken, Grid, lighten, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import { DEFAULT_FORM_RESPONSE, DEFAULT_REG_STATS, SellerRegistrationStatus } from "src/constants/FormResponse";
import { routes } from "src/data/Routes";
import SingleDateForm from "src/components/forms/SingleDateForm";
import { Row } from "src/components/display/Row";
import { getEvents, getRegistrationStats, getSellerData } from "src/utils/SellerRegistration";
import { useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";

const SellerRegistrationMetricsOfADay = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const cardKey = "Seller Registrations";

    const theme = useTheme();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const cardData = useSelector((state: CombineReducerState) => state.home.cardData);
    const [response, setResponse] = useState(DEFAULT_FORM_RESPONSE);
    const [data, setData] = useState<Array<any>>([]);
    const [regStats, setRegStats] = useState(DEFAULT_REG_STATS);
    const rowBgColor = theme.palette.mode === "light" ? lighten(theme.palette.primary.light, 0.5) : darken(theme.palette.primary.main, 0.5);

    const cleanData = (data: Array<any>) => {
        let res: any[] = [];
        data.forEach(row => {
            const response = { value: row };
            row = getSellerData(response);
            row.events = getEvents(response);
            res.push(row);
        })
        return res;
    }

    // Since there is a "Seller Registrations" card at the home page, the data will be loaded there.
    // In that case, we will use the data stored in redux to display on this page without calling the API again.
    useEffect(() => {
        if (cardData[cardKey].isLoaded) {
            setResponse({ ...response, data: cardData[cardKey].data })
        }
    }, []);

    useEffect(() => {
        setData(cleanData(response.data));
        setRegStats(getRegistrationStats({ value: response.data }));
    }, [response]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">

            <PageTitle title="Seller Registration Metrics of a day" />

            <Grid container alignItems="center" justifyContent="center">
                <Grid item sm={6}>
                    <SingleDateForm handler={setResponse} url={baseUrl + routes.sellerRegistration.metricsOfDay} />
                </Grid>
                <Grid item sm={6}>
                    <Paper variant="outlined">
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total sellers</TableCell>
                                        <TableCell>
                                            {isLoading ? <CircularProgress size={16} /> : regStats.total}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Registration completed</TableCell>
                                        <TableCell>
                                            {isLoading ? <CircularProgress size={16} /> : regStats.completed}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Registration not completed</TableCell>
                                        <TableCell>
                                            {isLoading ? <CircularProgress size={16} /> : regStats.notCompleted}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>

            <Box style={{ width: "100%", marginTop: "16px" }}>

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: rowBgColor }}>
                                <TableCell>Seller ID</TableCell>
                                <TableCell>First registered on</TableCell>
                                <TableCell>LOP</TableCell>
                                <TableCell>Source merchant ID</TableCell>
                                <TableCell>Source channel</TableCell>
                                <TableCell>Last activity on</TableCell>
                                <TableCell>COE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row: SellerRegistrationStatus) => (
                                <Row key={row.sellerId} row={row} subrows={row.events} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

        </Grid>
    )

}

export default SellerRegistrationMetricsOfADay;
