import { Errors } from "src/constants/Errors";

interface ErrorHandlingFunction {
    (error?: string | null, setApiError?: Function, setApiErrorMessage?: Function, dispatch?: Function, isLoadingAction?: Function): void;
}
      
export const handleApiError:ErrorHandlingFunction = (error, setApiError, setApiErrorMessage, dispatch, isLoadingAction) => {
    if (error && setApiError && setApiErrorMessage) {
        setApiError(true);
        setApiErrorMessage(error || Errors.Message.SERVER_ERROR);
    }
    if (dispatch && isLoadingAction) {
      dispatch(isLoadingAction(false));
    }
  };