import React from "react";
import { Typography } from "@mui/material";
import { AnkanaAllowlistUtility } from "src/constants/AnkanaAllowlistUtility";

const AnkanaFAQ = () => {

    const helpSectionURL = AnkanaAllowlistUtility.HelpSectionURL
    
    return (
        <div style={{ justifyContent: 'left', alignItems: 'flex-start', textAlign: 'left' }}>
            <Typography variant="h6" gutterBottom>
             <b>   What does each attribute mean?</b>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <b>Ankana Tag:</b> <br /> The word Ankana (अङ्कन) means a tag in Sanskrit and is the internal name for this project. With Ankana, sellers can be dynamically tagged with tags (arbitrary strings) based on pre-defined criteria for each tag. Tags would be dynamic (i.e. change over time) and the set of tags applied on a seller could be thought of as representing the current state/context of a seller.
                <br />

                We currently use GLBL_NA2IN_QUALIFIED ankana tag to allowlist seller to register on OOC2IN.

                <br />
                <b>Marketplace:</b> <br />To register in OOC2IN pipeline through SGD we use NA  marketplace to ankana allowlist the seller.
                <br />
                <b>Seller IDs:</b> <br /> list of merchant Ids. They can be in encrypted format or in numeric format. They can be separated by new lines or by commas(,).
            </Typography>
            <Typography variant="h6" gutterBottom>
                <b>Usage of Tool</b>
            </Typography>
            <text>More Information about the tool can be found here : <a href={helpSectionURL}> Ankana self-service tool</a></text>

        </div>
    );
}

export default AnkanaFAQ;