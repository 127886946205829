import * as amznID from '@amzn/amazon-id';

const extractSellerIds = (sellerIds: any) => {
    // If there are new lines, replace them all with commas
    if (!sellerIds) return [];
    let csvSellerIds = sellerIds.split('\n').join(',');

    // remove consecutive commas if present
    csvSellerIds = csvSellerIds.replace(/,+/g, ',');

    // Retrieve all seller Ids and remove any starting or trailing spaces
    return csvSellerIds.split(',').map((el: string) => el.trim()).filter(Boolean);
};

const checkId = (id: any) => {
    let isEncrypted = isNaN(id);
    let isValid = true;
    if (isEncrypted) {
        try {
            if (!decryptMerchantId(id)) {
                isValid = false;
            }
        } catch (err) {
            isValid = false;
        }
    }
    return { isEncrypted, isValid };
}

const encryptMerchantId = (merchantId: string) => {
    return amznID.encryptCustomerId(merchantId);
}

const decryptMerchantId = (merchantId: string) => {
    return amznID.decryptCustomerId(merchantId);
}

export { extractSellerIds, checkId, encryptMerchantId, decryptMerchantId };