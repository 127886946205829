import { SidebarState } from "src/components/sidebar/SidebarState";
import { MenuItem, menuItems } from "src/data/MenuItems";
import { IS_SIDEBAR_ACTIVE, MENU_STATE, SELECTED_MENU_ITEM, SidebarActions } from "./SidebarActions";

const getInitialMenuState = (menuItems: Record<string, Array<MenuItem>>) => {
    let state: Record<string, boolean> = {};
    Object.keys(menuItems).map(key => state[key] = false);
    return state;
}

const initialState: SidebarState = {
    isSidebarActive: false,
    menuState: getInitialMenuState(menuItems),
    selectedMenuItem: "/",
};

export const sidebarReducer = (state = initialState, action: SidebarActions): SidebarState => {
    switch (action.type) {
        case IS_SIDEBAR_ACTIVE: {
            return {
                ...state,
                isSidebarActive: action.payload
            };
        }
        case MENU_STATE: {
            return {
                ...state,
                menuState: action.payload
            };
        }
        case SELECTED_MENU_ITEM: {
            return {
                ...state,
                selectedMenuItem: action.payload
            };
        }
        default:
            return state;
    }
};