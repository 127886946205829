import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from './combineReducers';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';

import '@amzn/katal-components/styles.css';
import './index.scss';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

ReactDOM.render(
	<React.StrictMode>
		<AppInitWrapper>
			<React.Suspense fallback={<KatSpinner size='large' />}>
				<Provider store={createStoreWithMiddleware(reducers)}>
					<App />
				</Provider>
			</React.Suspense>
		</AppInitWrapper>
	</React.StrictMode>,
	document.getElementById('root')
);
