import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
    {
        field: "stateOrder",
        headerName: "Page Order",
        flex: 1,
    },
    {
        field: "stateName",
        headerName: "Page Name",
        flex: 1,
    },
    {
        field: "status",
        headerName: "Page Status",
        flex: 1,
    },
    {
        field: "eventTime",
        headerName: "Time",
        flex: 1,
    },
];
