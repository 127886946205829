import {BASE_TITLE, DELIMITER, PageProps} from "src/components/App";
import {routes} from "src/data/Routes";
import {useSelector} from "react-redux";
import {CombineReducerState} from "src/combineReducers";
import React, {useState} from "react";
import {DEFAULT_FORM_RESPONSE, DEFAULT_PAGE_SIZE} from "src/constants/FormResponse";
import {Box, Grid} from "@mui/material";
import {PageTitle} from "src/components/display/PageTitle";
import SingleIdForm from "src/components/forms/SingleIdForm";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {CommercialInvoiceStatusTableColumns} from "./TableColumns";


const CommercialInvoiceStatusForAShipmentId = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);

    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const [response, setResponse] = useState(DEFAULT_FORM_RESPONSE);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">

            <PageTitle title="Commercial Invoice Status for a Shipment ID" />

            <SingleIdForm
                handler={setResponse}
                url={baseUrl + routes.invoices.CommercialInvoiceStatus}
                label="Shipment ID"
                placeholder={"Try \"7000585110425\""} />

            <Box style={{ width: "100%", marginTop: "32px" }}>
                <DataGrid
                    rows={response.data}
                    getRowId={row => row.shipmentId}
                    columns={CommercialInvoiceStatusTableColumns}
                    pageSize={pageSize}
                    pagination
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    density="compact"
                    loading={isLoading}
                />
            </Box>
        </Grid>
    )
}

export default CommercialInvoiceStatusForAShipmentId;