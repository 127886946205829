import React, { useEffect, useState } from "react"
import { Dayjs } from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isLoadingAction } from "src/views/HomeActions";
import { Alert, Button, Container, Grid, LinearProgress, Paper, TextField } from "@mui/material";
import dayjs from 'dayjs';
import apiRequest from "src/utils/ApiRequest";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { Errors } from "src/constants/Errors";

let formattedDate = "";

const SingleDateForm = (props: SingleDateFormProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);

    // Props
    const url = props.url;
    const handler = props.handler;
    const buttonText = props.buttonText || "Submit";
    const elevation = props.elevation || 4;
    const dateFormat = props.dateFormat || "YYYY-MM-DD";
    const urlParams = new URLSearchParams(window.location.search);

    // States
    const [date, setDate] = useState<Dayjs | null>(null);
    const [emptyInputError, setEmptyInputError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState("");

    // Fill the form inputs if "date" is present in search params.
    useEffect(() => {
        const pDate = urlParams.get("date");
        if (pDate) setDate(dayjs(new Date(pDate)));
    }, []);

    useEffect(() => {
        formattedDate = date?.format(dateFormat) || "";
    }, [date])

    const handleSubmit = async () => {
        clearErrors();
        if (formattedDate === "") {
            setEmptyInputError(true);
            return;
        };
        if (isLoading) return;
        window.history.pushState(null, "", "?date=" + formattedDate);

        // Setup for API call
        dispatch(isLoadingAction(true));

        try {
            const response = await apiRequest(url, { date: formattedDate });
            if (response.ok) {
                const data = await response.json();
                handler({
                    data,
                    error: false,
                    status: response.status,
                    apiErrorMessage: null,
                });
                setApiError(false);
            } else {
                const error = await response.json();
                handler({
                    data: [],
                    error: true,
                    status: response.status,
                    apiErrorMessage: error.message,
                });
                setApiError(true);
                setApiErrorMessage(error.message);
            }
        } catch (err) {
            console.error(err);
            handler({
                data: [],
                error: true,
                status: Errors.StatusCode.SERVER_ERROR,
                apiErrorMessage: Errors.Message.SERVER_ERROR,
            });
            setApiError(true);
            setApiErrorMessage(Errors.Message.SERVER_ERROR);
        }
        dispatch(isLoadingAction(false));
    }

    const clearErrors = () => {
        setEmptyInputError(false);
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={elevation}>
                <Grid container alignItems="center" justifyContent="center" sx={{ paddingX: 3, paddingY: 2 }}>
                    <Grid item sm={10}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date"
                                value={date}
                                disabled={isLoading}
                                onChange={(date) => setDate(date)}
                                renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item sm={2}>
                        <Button variant="contained" sx={{ m: 1 }} size="small" disabled={isLoading} onClick={handleSubmit} fullWidth>{buttonText}</Button>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 1 }}>
                        {emptyInputError && <Alert severity="error" onClose={() => setEmptyInputError(false)}>Please enter the date...</Alert>}
                        {apiError && <Alert severity="error" onClose={() => setApiError(false)}>{apiErrorMessage}</Alert>}
                        {isLoading && <LinearProgress />}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

interface SingleDateFormProps {
    url: string,
    handler: any,
    buttonText?: string,
    elevation?: number,
    dateFormat?: string,
}

export default SingleDateForm;