import React, { useState } from 'react';
import { GlobalSellerRegistrationChartProps, primaryBarColor, secondaryBarColor } from 'src/constants/ChartConstants';
import { ScatterChart ,Scatter,CartesianGrid,XAxis,YAxis ,Tooltip ,ScatterProps} from "recharts";
import { FormControl,Radio,FormControlLabel,RadioGroup } from '@mui/material';

const CustomScatterDot: React.FC<ScatterProps & { cx?: number; cy?: number , color: string}> = ({ cx, cy ,color }) => {
    return (
      <circle cx={cx} cy={cy} r={6} fill={color} />
    );
};

const SellerRegistrationScatterChart: React.FC<GlobalSellerRegistrationChartProps> = (chartData) =>{
    const [dataKey,setDataKey]= useState(true);
    
    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataKey((event.target.value === 'true'))
    };

    return (
        <>
        <ScatterChart
            width={650}
            height={500}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis dataKey={dataKey?  chartData.primaryDataKey : chartData.secondaryDataKey} type="number"   />
            <XAxis dataKey="name" type="category" name="Country"  />
            <Tooltip/>
            <Scatter 
                name={dataKey ? "Global Registrations" : "Domestic Registrations"} data={chartData.data}
                fill={dataKey ? primaryBarColor : secondaryBarColor}
                line={{stroke: 'red', strokeWidth: 2}}
                lineType='joint'
                isAnimationActive={true}
                shape={<CustomScatterDot color={dataKey ? primaryBarColor : secondaryBarColor}/>}
            />
        </ScatterChart>
        <FormControl>
            <RadioGroup
                row
                value={dataKey}
                onChange={handleSwitch}
            >
                <FormControlLabel value={true} control={<Radio />} label="Global Registrations" />
                <FormControlLabel value={false} control={<Radio />} label="Domestic Registrations" />
            </RadioGroup>
        </FormControl>
        </>
    )
}

export default SellerRegistrationScatterChart;