import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
    {
        field: "merchantId",
        headerName: "Merchant ID",
        flex: 1,
    },
    {
        field: "clientId",
        headerName: "Client ID",
        flex: 1,
    },
    {
        field: "endToEndId",
        headerName: "End To End ID",
        flex: 1,
    },
    {
        field: "disbursementStatus",
        headerName: "Disbursement Status",
        flex: 1,
    },
    {
        field: "amount",
        headerName: "Amount",
        flex: 1,
    },
    {
        field: "createDate",
        headerName: "Created Date",
        flex: 1,
    },
    {
        field: "updateDate",
        headerName: "Updated Date",
        flex: 1,
    },
];
