import React, { useState } from 'react'
import {
    PieChart ,Pie, 
    Tooltip,
    Cell,Legend,
} from "recharts";
import { GlobalSellerRegistrationChartProps ,pieChartColors } from 'src/constants/ChartConstants';


const SellerRegistrationPieChart: React.FC<GlobalSellerRegistrationChartProps> = (chartData) => {
    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
        
    const onPieEnter = (_: any ,index: number) => {
        setActiveIndex(index);
    };

    const onPieLeave = () =>{
        setActiveIndex(undefined);
    }

    const getSectorColor = (index: number) =>{
        return index == activeIndex ? 'white' : pieChartColors[index % pieChartColors.length];
    }

    return (
        <PieChart width={700} height={500} margin={{ top:0 ,right: 25 }} >
             <Legend
            verticalAlign="bottom"
            layout="horizontal"
            align="center"
            iconSize={20}
            payload={chartData.data.map((entry ,index) => ({ value: entry.name, type: 'circle', color: pieChartColors[index % pieChartColors.length]}))}
            />
            <Pie 
            data={chartData.data} 
            dataKey={chartData.primaryDataKey}
            isAnimationActive={true}
            cx="50%"
            cy="50%" 
            innerRadius={150}
            outerRadius={200}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            >
                {chartData.data?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getSectorColor(index)} 
                    stroke={index==activeIndex? 'black' : pieChartColors[index % pieChartColors.length]}
                    strokeWidth={index==activeIndex? '2px': '0px'}
                    cursor={'pointer'} 
                    />
                ))}
            </Pie>
            <Pie 
            data={chartData.data} 
            dataKey={chartData.secondaryDataKey!}
            isAnimationActive={false}
            cx="50%"
            cy="50%" 
            innerRadius={0}
            outerRadius={120}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            >
                {chartData.data?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getSectorColor(index)} 
                    cursor={'pointer'} 
                    stroke={index==activeIndex? 'black' : pieChartColors[index % pieChartColors.length]}
                    strokeWidth={index==activeIndex? '2px': '0px'}
                    />
                ))}
            </Pie>
            <Tooltip />
        </PieChart>
    )
};

export default SellerRegistrationPieChart;