import { PARIS_FLAG_DETAILS } from "../ParisFlagDataConstants";

const { FlagOperationStatusKey, SuccessStatus, DuplicateStatus } = PARIS_FLAG_DETAILS.InfoMessages;

const createTableForIndividualSeller = (
    responseData: any,
    marketplaceId: string
  ) => {
    let sellerIdByFlagDetailsData: any = {};
    for (let seller in responseData) {
      let operationStatus = responseData[seller][FlagOperationStatusKey];
      let err = responseData[seller].errorMessage ? true : false;

      if (
        !(
          operationStatus == SuccessStatus || operationStatus == DuplicateStatus
        ) ||
        err
      ) {
        sellerIdByFlagDetailsData[seller] = responseData[seller];
      } else {
        let table = [];

        let marketplaceIdToFlagValueMap =
          responseData[seller].marketplaceIdToSellerMarketplaceFlagValueMap;
        if (marketplaceIdToFlagValueMap[marketplaceId]) {
          let reasonToReasonDetailsMap =
            marketplaceIdToFlagValueMap[marketplaceId].reasonDetailsMap;
          for (let reason in reasonToReasonDetailsMap) {
            table.push({
              reason: reason,
              value: reasonToReasonDetailsMap[reason].value,
              comment: reasonToReasonDetailsMap[reason].reasonComment,
              updatedOn: new Date(
                reasonToReasonDetailsMap[reason].updatedOn
              ).toLocaleString(),
            });
          }
        }
        sellerIdByFlagDetailsData[seller] = {
          flagValue: marketplaceIdToFlagValueMap[marketplaceId]
            ? marketplaceIdToFlagValueMap[marketplaceId].flagValue
            : "UNKNOWN",
          reasons: table,
        };
      }
    }
    return sellerIdByFlagDetailsData;
  };

export {createTableForIndividualSeller}