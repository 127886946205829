import React from "react";

/*
    Renders a single button with label for getting file
    Attributes
    1)inputId -> Id for the input box
    2)accept -> which file type to accept
    3)onChange -> is a function passed as props to track input changes
    Returns a div
*/
const SimpleFileInput = (props:any) => {
        return (
            <div>
                <input
                    ref={props.inputRef}
                    type="file"
                    id={props.inputId}
                    accept={props.accept}
                    onChange={event => props.onChange(event.target.files)}
                />
            </div>
        );
    
}

export default SimpleFileInput;