import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    TextField
} from '@mui/material';
import { useSelector } from 'react-redux';
import { CombineReducerState } from 'src/combineReducers';
import useForm from "src/components/forms/UseForm";
import SelectDropdown from "src/components/input/SelectDropdown";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";
import {initialAnkanaFieldValues} from "src/constants/FormResponse";

const AnkanaForm = (props: SingleIdFormProps) => {
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const buttonText = props.buttonText || "Submit";
    const elevation = props.elevation || 4;
    const [merchantIds, setMerchantIds] = useState<Array<string>>([]);

    const {
        values,
        // errors,
        // setErrors,
        setValues,
        handleInputChange,
    } = useForm(initialAnkanaFieldValues);

    const marketplaceArray =  [
        { id: "NA", title: "North America" },
    ];
    const ankanaTagArray = [
        { id: "GLBL_NA2IN_QUALIFIED", title: "OOC2IN" },
    ];
    const optionArray =  [
        { id: "Apply", title: "Apply Ankana Tag" },
        { id: "Verify", title: "Verify Ankana Tag" },
    ];
    useEffect(() => {
        setValues({...values, sellerIdsList:merchantIds})
    },[merchantIds])

    const ApplyAnkanaOperation = () => {
        return (
            <>
                <MerchantIdsInput
                    label="IDs"
                    inputId="sellerIds"
                    inputPlaceholder="Comma / Line separated Ids"
                    rows="8"
                    handler={setMerchantIds}
                    customStyle={{ paddingLeft: 12, paddingRight: 0 }}
                />
            </>
        );
    }

    const VerifyAnkanaOperation = () => {
        return (
            <TextField
                id="sellerIdsList"
                type="search"
                name="sellerIdsList"
                size="small"
                label="Seller ID"
                fullWidth sx={{ m: 1 }}
                placeholder="Enter Merchant Id" disabled={isLoading}
                onChange={(e: any) => setValues({ ...values, sellerIdsList: [e.target.value] })} autoFocus required />
        );
    }

    const handleSubmit = () => {}

    return (
        <Container maxWidth="sm">
            <Paper elevation={elevation}>
                <Grid container alignItems="center" sx={{ paddingX: 3, paddingY: 2 }}>
                    <Grid item md={12}>
                        <SelectDropdown
                            name="marketplaceId"
                            label="Marketplace"
                            value={values.marketplaceId}
                            onChange={handleInputChange}
                            options={marketplaceArray}
                        />
                        <SelectDropdown
                            name="ankanaTag"
                            label="Ankana Tag"
                            value={values.ankanaTag}
                            onChange={handleInputChange}
                            options={ankanaTagArray}
                        />
                        <SelectDropdown
                            name="operation"
                            label="Options"
                            value={values.operation}
                            onChange={handleInputChange}
                            options={optionArray}
                        />
                        {values.operation === "Apply" ? ApplyAnkanaOperation() : VerifyAnkanaOperation()}
                        <Box style={{ width: "100%", marginTop: "10px" }}>
                            <Button variant="contained" size="medium" disabled={isLoading} onClick={handleSubmit} >{buttonText}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

interface SingleIdFormProps {
    url?: string,
    handler: any,
    label?: string,
    placeholder?: string,
    buttonText?: string,
    elevation?: number,
}

export default AnkanaForm;