import { HomeCard } from "src/components/collection/CardListProps";
import { ApiGatewayBaseUrl, routes } from "./Routes";
import dayjs from 'dayjs';
import { DATE_FORMAT } from "src/constants/Date";

const stage = window.location.hostname.includes("beta") || window.location.hostname.includes("localhost") ? "beta" : "prod";
const baseUrl = stage === "beta" ? ApiGatewayBaseUrl.beta : ApiGatewayBaseUrl.prod;
export const homeCards: Array<HomeCard> = [
    {
        description: "Seller Registrations",
        url: routes.sellerRegistration.metricsOfDay,
        buttonText: "Details",
        fetchUrl: baseUrl + routes.sellerRegistration.metricsOfDay,
        queryParam: {
            date: dayjs(new Date()).format(DATE_FORMAT),
        },
    },
    {
        description: "Rejected Disbursements",
        url: routes.payments.l2Rejects,
        buttonText: "Details",
        fetchUrl: baseUrl + routes.payments.l2Rejects,
        queryParam: {
            startDate: dayjs(new Date()).format(DATE_FORMAT),
            endDate: dayjs(new Date()).format(DATE_FORMAT),
        },
    },
    {
        description: "Orders Placed",
        url: routes.sellerOrders.listOrdersInDateRange,
        buttonText: "Details",
        fetchUrl: baseUrl + routes.sellerOrders.listOrdersInDateRange,
        queryParam: {
            startDate: dayjs(new Date()).format(DATE_FORMAT),
            endDate: dayjs(new Date()).format(DATE_FORMAT),
        },
    },
    {
        description: "Invoices Generated",
        url: routes.invoices.CommercialInvoiceStatusInDateRange,
        buttonText: "Details",
        fetchUrl: baseUrl + routes.invoices.CommercialInvoiceStatusInDateRange,
        queryParam: {
            startDate: dayjs(new Date()).format(DATE_FORMAT),
            endDate: dayjs(new Date()).format(DATE_FORMAT),
        },
    },
];