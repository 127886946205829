import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isSideBarActiveAction,
  menuStateAction,
  selectedMenuItemAction,
} from "src/components/sidebar/SidebarActions";
import {
  alpha,
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { CombineReducerState } from "src/combineReducers";
import { NavLink, useLocation } from "react-router-dom";
import { MenuItem } from "src/data/MenuItems";

interface SidebarProps {
  menuItems: Record<string, Array<MenuItem>>;
}

const Sidebar = (props: SidebarProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();

  const isSidebarActive = useSelector(
    (state: CombineReducerState) => state.sidebar.isSidebarActive
  );
  const menuState = useSelector(
    (state: CombineReducerState) => state.sidebar.menuState
  );
  const selectedMenuItem = useSelector(
    (state: CombineReducerState) => state.sidebar.selectedMenuItem
  );
  const menuItems = props.menuItems;

  const boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;";
  const menuFontSize = "14px";
  const ListSx = {
    paddingX: "12px",
    "&& .Mui-selected, && .Mui-selected:hover": {
      bgcolor: alpha(theme.palette.primary.main, 0.3),
    },
  };

  // Set selected Menu Item on page refresh
  useEffect(() => {
    dispatch(selectedMenuItemAction(location.pathname));
  }, []);

  const getMenuTitle = (key: string, color: string) => {
    return (
      <ListItemButton
        onClick={() =>
          dispatch(menuStateAction({ ...menuState, [key]: !menuState[key] }))
        }
      >
        <ListItemText
          primaryTypographyProps={{
            fontSize: menuFontSize,
            fontWeight: "bold",
            color: color,
          }}
        >
          {key}
        </ListItemText>
        {menuState[key] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    );
  };

  const getMenuItem = (item: any, isChildren: boolean) => {
    return (
      <Box key={item.to}>
        <ListItemButton key={item.to} sx={{ p: 0 }} disabled={item.disabled}>
          <ListItem
            color="primary"
            component={NavLink}
            to={item.to}
            style={{ color: "inherit" }}
            selected={selectedMenuItem === item.to}
            onClick={() => dispatch(selectedMenuItemAction(item.to))}
          >
            <ListItemText
              primary={item.title}
              primaryTypographyProps={
                isChildren
                  ? { fontSize: menuFontSize, ml: 2 }
                  : { fontSize: menuFontSize }
              }
            />
          </ListItem>
        </ListItemButton>
        <Divider />
      </Box>
    );
  };

  return (
    <Drawer
      PaperProps={{ sx: { minWidth: "300px" } }}
      open={isSidebarActive}
      onClose={() => dispatch(isSideBarActiveAction(!isSidebarActive))}
      color="secondary"
    >
      {/* Sidebar Hamburger icon and Logo */}
      <ListItem sx={{ height: "64px", paddingX: "24px" }}>
        <IconButton
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => dispatch(isSideBarActiveAction(!isSidebarActive))}
        >
          <MenuIcon color="inherit" />
        </IconButton>
        <Box
          component={NavLink}
          style={{ color: "inherit", textDecoration: "none" }}
          to="/"
          onClick={() => dispatch(selectedMenuItemAction("/"))}
        >
          <Typography variant="h6">VOLDEMORT</Typography>
        </Box>
      </ListItem>
      <Divider />

      {/* List of all the menus */}
      <List
        subheader={
          <ListSubheader component="div">Out Of Country</ListSubheader>
        }
        sx={ListSx}
      >
        {Object.keys(menuItems).map((key) => (
          <Box sx={{ boxShadow: boxShadow, mb: 1 }} key={key}>
            {getMenuTitle(key, "primary")}
            <Collapse in={menuState[key]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuItems[key].map((item) =>
                  item.children.length > 0 ? (
                    <Box>
                      {" "}
                      {getMenuTitle(item.title, "#1f618d")}
                      <Divider />
                      <Collapse
                        in={menuState[item.title]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {item.children.map((item) => (
                            <>{getMenuItem(item, true)}</>
                          ))}
                        </List>
                      </Collapse>
                    </Box>
                  ) : (
                    <>{getMenuItem(item, false)} </>
                  )
                )}
              </List>
            </Collapse>
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;