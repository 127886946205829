// API operations
export const OPERATION_FETCH_ISP_DETAILS = "fetchISPDetails";
export const OPERATION_TO_ADD_ISP = "addISP";
export const OPERATION_TO_UPDATE_GSTINS = "updateGSTINs";
export const OPERATION_SAVE_SELLER_DETAILS = "sellerOnboarding";
export const API_PATH = "/isp/isp-and-seller-onboarding";
export const ADD_OPERATION = "add";
export const UPDATE_OPERATION = "update";
export const SELECTED_ALL = "all";
export const LABEL_FOR_ADD_ISP = "Add a new ISP as necessary";
export const LABEL_FOR_UPDATE_ISP = "Update the existing GSTIN for the ISP";


// Error messages
export const ERROR_FETCH_ISP_DETAILS = "Failed to fetch ISP details.";
export const ERROR_UPDATE_GSTINS = "Failed to update GSTINs.";
export const ERROR_MESSAGE_FOR_DUPLICATE_ISP =
  "ISP with the same name already exists ISPName: ";
export const MAX_LIMIT = 50;
export const LETTERS_AND_SINGLE_SPACE_REGEX = /[^A-Za-z ]| {2,}/g;
export const HELPER_TEXT_FOR_MERCHANT_IDS_INPUT = "Please click the toggle button to check the validity of merchant IDs and their encryption/decryption status.";

export const REPORT_TYPES = ["MTRB2B", "MTRB2C", "STR"];

export const SELLER_REASONS = {
  FAILED_TO_ONBOARD: "Failed to onboard",
  ALREADY_EXIST: "Already exist",
  WITHOUT_ISP_SUBSCRIPTION: "Without ISP subscription",
  NO_GSTIN_FOUND: "No GSTIN found",
  NEW_GSTIN_FOUND: "New GSTIN found",
};

export const SUCCESSFULLY_ONBOARD_MESSAGE = "Successfully onboard no of sellers: ";
export const FAILED_TO_ONBOARD_MESSAGE = "Falied to onboard no of sellers: ";
export const DEFAULT_ERROR_MESSAGE = "Error occured, Something went wrong";
export const NO_REPORTS_FOUND = "No report found";

export const STATUS = {
    Success: "Success",
    Failed: "Failed",
}

// interface
export interface SELLER_GSTIN_NOT_IN_ISP_INTERFACE {
  [sellerId: string]: string[];
}

export interface OOCFBA_SELLER_RESPONSE_INTERFACE {
  successCount: number;
  failedCount: number;
  failedSellerIds: string[];
  existingSellerIds: string[];
  sellersWithoutISPSubscription: string[];
  sellerGSTINsNotInISP: SELLER_GSTIN_NOT_IN_ISP_INTERFACE;
}

export interface ISP_DETAILS_INTERFACE {
  ispId: string | null;
  ispName: string | null;
  gstins: string[];
}

export interface SELLER_RESPONSE_TABLE_INTERFACE {
  id: string;
  reason: string;
}