import { Grid } from "@mui/material";
import React from "react";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import { SCORPION_BUSINESS_METRICS_URL } from "src/constants/ServiceConstants";

const ScorpionMetrics = (props: PageProps) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

  return (
    <Grid container style={{ width: "100%", height: "100vh" }}>
      <PageTitle title={PAGE_TITLE} />
      <iframe
        title={PAGE_TITLE}
        style={{ width: "100%", height: "100%", border: "none" }}
        src={SCORPION_BUSINESS_METRICS_URL}
      />
    </Grid>
  );
};

export default ScorpionMetrics;
