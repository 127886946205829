import {BASE_TITLE, DELIMITER, PageProps} from "src/components/App";
import {useSelector} from "react-redux";
import {CombineReducerState} from "src/combineReducers";
import React, {useEffect, useState} from "react";
import {
    Box, darken,
    Grid,
    lighten,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, useTheme
} from "@mui/material";
import {PageTitle} from "src/components/display/PageTitle";
import DateRangeForm from "src/components/forms/DateRangeForm";
import {routes} from "src/data/Routes";
import {DEFAULT_FORM_RESPONSE, DEFAULT_PAGE_SIZE} from "src/constants/FormResponse";
import {Row} from "src/views/payments/opgsp-payment-failure/Row";
import {OPGSPPaymentFailureConstants} from "src/constants/ServiceConstants";


const OPGSPPaymentFailure = (props: PageProps) => {

    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const [response, setResponse] = useState(DEFAULT_FORM_RESPONSE);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [data, setData] = useState({});
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);

    const theme = useTheme();
    const rowBgColor = theme.palette.mode === "light" ? lighten(theme.palette.primary.light, 0.5) : darken(theme.palette.primary.main, 0.5);


    interface OPGSPPaymentFailureData  {
        merchantId: string,
        updateEpochDay: string,
        amount: string,
        disbursementStatus: string
    }

    const formatResponse = (data: any) => {
        let OPGSPPaymentFailureForMerchant: any = {};

        data.forEach((row: OPGSPPaymentFailureData) => {
            const merchantId = row.merchantId;
            const date = row.updateEpochDay;
            if(OPGSPPaymentFailureForMerchant[merchantId] === undefined) {
                OPGSPPaymentFailureForMerchant[merchantId] = {};
            }
            if(OPGSPPaymentFailureForMerchant[merchantId][date] === undefined) {
                OPGSPPaymentFailureForMerchant[merchantId][date] = {};
                OPGSPPaymentFailureForMerchant[merchantId][date][OPGSPPaymentFailureConstants.AMOUNT_FOR_A_DAY] = 0;
                OPGSPPaymentFailureForMerchant[merchantId][date][OPGSPPaymentFailureConstants.PAIN002_FAILURE] = 0;
                OPGSPPaymentFailureForMerchant[merchantId][date][OPGSPPaymentFailureConstants.CAMT053_FAILURE] = 0;
            }
            OPGSPPaymentFailureForMerchant[merchantId][date][OPGSPPaymentFailureConstants.AMOUNT_FOR_A_DAY]+=parseInt(row.amount);
            OPGSPPaymentFailureForMerchant[merchantId][date][row.disbursementStatus]+=1;
            if(OPGSPPaymentFailureForMerchant[merchantId][OPGSPPaymentFailureConstants.TOTAL_AMOUNT] === undefined) {
                OPGSPPaymentFailureForMerchant[merchantId][OPGSPPaymentFailureConstants.TOTAL_AMOUNT] = 0;
                OPGSPPaymentFailureForMerchant[merchantId][OPGSPPaymentFailureConstants.TOTAL_FAILURES] = 0;
            }
            OPGSPPaymentFailureForMerchant[merchantId][OPGSPPaymentFailureConstants.TOTAL_AMOUNT]+=parseInt(row.amount);
            OPGSPPaymentFailureForMerchant[merchantId][OPGSPPaymentFailureConstants.TOTAL_FAILURES]+=1;
        });

        return OPGSPPaymentFailureForMerchant;
    }

    useEffect(() => {
        setData(formatResponse(response.data));
    },[response]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">

            <PageTitle title="OPGSP Payment Failure" />

            <DateRangeForm handler={setResponse} url={baseUrl + routes.payments.opgspPaymentFailure} />

            <Box style={{ width: "100%", marginTop: "32px" }}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: rowBgColor }}>
                                <TableCell>Merchant Id</TableCell>
                                <TableCell>Total Amount (<span>&#8377;</span>)</TableCell>
                                <TableCell>Total Failures</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(data).map((merchantId) => (
                                <Row key={merchantId} merchantId={merchantId} details={(data as any)[merchantId]} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Grid>
    )
}

export default OPGSPPaymentFailure;