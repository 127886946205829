import React, { useState } from "react";

export default function useForm(initialFieldValues: useForm) {
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    };
}

export interface useForm {
    sellerIdsList?: Array<Object>,
    marketplaceId?: any,
    ankanaTag?: any,
    operation?: any,
    values?: any
}