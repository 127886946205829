import React, {useEffect, useState} from "react";
import {Alert, Box, Container, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import SimpleFileInput from "src/components/input/SimpleFileInput";
import {Delete} from "@mui/icons-material";
import {extractSellerIds} from "src/helpers/MerchantIdsHelper";

const GSTIdsInput = (props: GSTIdsInputProps) => {
    const GSTIds: Array<string> = props.value || [];
    const {inputId, rows, inputPlaceholder, handler, gstinList, handleInvalidGST} = props
    const [state, setState] = useState<any>({
        text: "",
        GSTIds: GSTIds.join("\n"),
        error: ""
    });
    const GSTINRegex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);

    const updateStateFromTextInput = (rawGSTIds: String | ArrayBuffer| null) => {
        let GSTIds: Array<string> = extractSellerIds(rawGSTIds);

        let InvalidGSTINList = GSTIds.filter((str) => !GSTINRegex.test(str));

        setState({
            ...state,
            GSTIds: GSTIds.join("\n"),
            text:  rawGSTIds,
            error: ''
        })

        if(InvalidGSTINList.length > 0){
            handleInvalidGST && handleInvalidGST(true);
            setState({
                ...state, error: "Following are invalid GSTINs: " + InvalidGSTINList
            })
        }

        handler(GSTIds);
    }

    const readGSTIdsFromFile = (file: File) => {
        let fileReader = new FileReader();
        fileReader.onloadend = (e: Event) => {
            updateStateFromTextInput(fileReader.result);
        };
        fileReader.readAsText(file);
    };

    useEffect(() => {
        handleInvalidGST && handleInvalidGST(false)
        updateStateFromTextInput(state.text);
    },[state.text])

    useEffect(() => {
        gstinList && gstinList?.length <= 0 && handleClear()
    },[gstinList])

    const handleClear = () => {
        setState({...state, text: '',GSTIds: [], error: ''})
    }

    return (
        <Container maxWidth="xl">
            <TextField
                id={inputId}
                rows={rows}
                placeholder={inputPlaceholder}
                onBlur={() => updateStateFromTextInput(state.text)}
                onChange={event => setState({ ...state, text: event.target.value })}
                value={state.text}
                InputProps={{
                    endAdornment:
                        <Box>
                            <IconButton
                                disabled={state.text.length === 0}
                                color="error"
                                onClick={handleClear}
                                size="small"
                                sx={{ position: "absolute", top: 10, right: 10 }}>
                                <Delete />
                            </IconButton>
                        </Box>
                }}
                fullWidth
                multiline
            />

            <Grid container display="flex" justifyContent="space-between" sx={{ paddingY: 1 }} >
                <Grid item>
                    <SimpleFileInput
                        inputId="fileInputForGSTIds"
                        accept=".txt,.csv"
                        onChange={(selection: any) => readGSTIdsFromFile(selection[0])}
                    />
                </Grid>
            </Grid>
            {state.error &&
                <Alert severity="error" sx={{ wordBreak: "break-word" }} onClose={() => setState({ ...state, error: "" })}>
                {state.error}
                </Alert>
            }
        </Container>
    );
}

interface GSTIdsInputProps {
    inputId: string,
    label: string,
    rows: string,
    inputPlaceholder: string,
    value?: Array<string>,
    handler?: any,
    customStyle?: any,
    gstinList?: Array<string>,
    handleInvalidGST?: any
}

export default GSTIdsInput;