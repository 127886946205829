import { GridColDef } from "@mui/x-data-grid";

export const MERCHANT_ID = "merchantId";
export const REPORT:string = "reportType";
export const START_DATE:string = "startDate";
export const END_DATE:string = "endDate";
export const WEEKS:string = "weeks";
export const MARKETPLACE_ID:string = "marketplaceId";
export const DATEFORMAT = "YYYY-MM-DD";
export const REFERENCE_ID = "referenceId";
export const EPOCH_DIFFERENCE = 19800000;
export const DEFAULT_PAGE_SIZE = 10;
export const MONTH = "month";
export const YEAR = "year";
export const ISP_REPORTS_PATH_FOR_BRASS_AUTH = {path: "/isp/reports"};

export const  MONTHS = [
      "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
      "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

const getWeeksList = () => {
  let weeks = [];
  for(let i=1;i<=12;i++) {
      weeks.push({
          id: i,
          name: `${i} week`
      });
  }
  return weeks;
}

const getLast4Months = () => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentMonth = new Date().getMonth();
  
  let last4Months = [];
  for(let i = 0; i<=3 ; i++) {
      let m = currentMonth - i;
      if(m<0)
          m = m + 12;
      last4Months.push({
          id: m+1,
          name: months[m]
      });
  }

  return last4Months.reverse();
}

const getYearForLast4Months = () => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  let years = [];

  if(currentMonth <= 2)
      years.push({
          id: currentYear-1,
          name: currentYear-1
      });
  years.push({
      id: currentYear,
      name: currentYear
  });

  return years;
}


export const GENERATE_REPORTS_TYPE = ["FBA_FULFILLMENT_INVENTORY_HEALTH_DATA", "FBA_RECOMMENDED_REMOVAL_DATA", "FBA_FULFILLMENT_REMOVAL_ORDER_DETAIL_DATA",
    "FBA_FULFILLMENT_REMOVAL_SHIPMENT_DETAIL_DATA","FBA_FULFILLMENT_INVENTORY_RECEIPTS_DATA"];

export const SETTLEMENT_REPORTS_TYPE = ["SETTLEMENT_REPORT_DATA_FLAT_FILE_V2", "SETTLEMENT_REPORT_DATA_XML"];

export const RESPONSE_STATUS = {
  invalidInput:"INVALID_INPUT",
  failure:"FAILURE",
  noReportRequestFound:"NO_REPORT_REQUEST_FOUND",
  noReportsFound:"NO_REPORTS_FOUND",
  success:"SUCCESS",
  completeReportGenerationStatus:"Done"
};

export const ERROR_MESSAGE = {
    InvalidParams: "Invalid parameters. Make sure you are entering appropriate details.",
    ServerError: "Failed to fetch the data from the server, please try again",
    NoReportsFound:"No Reports found for the provided seller.",
    NoReportRequestFound:"No Reports Requests found for the provided Reference Id."
}

export const  ISPREPORTS = {
    WEEKS:getWeeksList(),
    MONTH:getLast4Months(),
    YEAR:getYearForLast4Months()
};

export const GENERATE_INVENTORY_SHIPMENT_REQUEST : generateInventoryShipmentInterface = {
    merchantId: "",
    marketplaceId: "",
    reportType: "",
    startDate: "",
    endDate: "",
}

export const SETTLEMENT_REQUEST : settlementInterface = {
  merchantId: "",
  marketplaceId: "",
  reportType: "",
  weeks: "",
}

export const MTR_REQUEST : mtrInterface = {
  merchantId: "",
  marketplaceId: "",
  reportType: "",
  month: "",
  year: "",
}

export const GST_AGGREGATED_REQUEST : gstAggregatedInterface = {
  ispId: "",
  gstins: [],
  reportType: [],
  month: "",
  year: "",
}

interface generateInventoryShipmentInterface extends IObjectKeys {
    merchantId: string,
    marketplaceId: string,
    reportType: string,
    startDate: string,
    endDate: string,
}

interface settlementInterface extends IObjectKeys {
  merchantId: string,
  marketplaceId: string,
  reportType: string,
  weeks:string
}

interface mtrInterface extends IObjectKeys {
  merchantId: string,
  marketplaceId: string,
  reportType: string,
  month:string,
  year:string
}

interface gstAggregatedInterface extends IObjectKeys {
  ispId: string,
  gstins: string[],
  reportType: string[],
  month:string,
  year:string
}

interface IObjectKeys {
    [key: string]: string | Array<string>;
}

export interface ISPReportDataType {
  referenceId:string,
  reportGenerationStatus:string,
  status:string,
  message:string,
  documentId:string,
  urlForDownload:string,
  error:string
}

export const CREATE_SUBSCRIPTION_TABLE_PROPERTIES : GridColDef[] = [
  {
    headerName: "Merchant Id",
    field: "merchantId",
    flex: 1,
  },
  {
    headerName: "Subscription Type",
    field: "subscriptionType",
    flex: 1,
  },
  {
    headerName: "Subscription Tier",
    field: "subscriptionTier",
    flex: 1,
  },
  {
    headerName: "Operation",
    field: "requestSuccessful",
    flex: 1,
  },
];
export const REMOVE_SUBSCRIPTION_TABLE_PROPERTIES : GridColDef[] = [
    {
      headerName: "Merchant Id",
      field: "merchantId",
      flex: 1,
    },
    {
      headerName: "Subscription Type",
      field: "subscriptionType",
      flex: 1,
    },
    {
      headerName: "Operation",
      field: "requestSuccessful",
      flex: 1,
    },
  ];