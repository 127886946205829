import React, { useEffect, useState } from "react"
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SingleIdForm from "src/components/forms/SingleIdForm";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { columns } from "./TableColumns";
import { DEFAULT_FORM_RESPONSE, DEFAULT_PAGE_SIZE, DEFAULT_SELLER_DATA, SELLER_EVENTS } from "src/constants/FormResponse";
import { PageTitle } from "src/components/display/PageTitle";
import { useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { routes } from "src/data/Routes";
import { getEvents, getSellerData } from "src/utils/SellerRegistration";

const SellerRegistrationMetrics = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);

    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const [response, setResponse] = useState(DEFAULT_FORM_RESPONSE);
    const [data, setData] = useState(DEFAULT_SELLER_DATA);
    const [events, setEvents] = useState(SELLER_EVENTS);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    useEffect(() => {
        setData(getSellerData(response.data));
        setEvents(getEvents(response.data));
    }, [response]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">

            <PageTitle title="Seller Registration Metrics" />

            <SingleIdForm handler={setResponse} url={baseUrl + routes.sellerRegistration.metrics} placeholder={"Try \"75752837217\""} />

            <Box style={{ width: "100%", marginTop: "16px" }}>

                <TableContainer component={Paper} sx={{ marginBottom: "16px" }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={100}>
                                    <Typography variant="subtitle1">Seller Details</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Seller ID</TableCell>
                                <TableCell>First registered on</TableCell>
                                <TableCell>LOP</TableCell>
                                <TableCell>Source merchant ID</TableCell>
                                <TableCell>Source channel</TableCell>
                                <TableCell>Last activity on</TableCell>
                                <TableCell>COE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{data.sellerId}</TableCell>
                                <TableCell>{data.createdTime}</TableCell>
                                <TableCell>{data.languageOfPreference}</TableCell>
                                <TableCell>{data.sourceMerchantId}</TableCell>
                                <TableCell>{data.sourceChannel}</TableCell>
                                <TableCell>{data.updatedTime}</TableCell>
                                <TableCell>{data.countryOfEstablishment}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="subtitle2" align="left">* LOP - Language Of Preference</Typography>
                <Typography variant="subtitle2" align="left">* COE - Country Of Establishment</Typography>

                <Typography variant="subtitle1" sx={{marginTop: 1}}>Step Details</Typography>
                <DataGrid
                    rows={events}
                    getRowId={(row) => row.stateOrder + row.eventTime}
                    columns={columns}
                    pageSize={pageSize}
                    pagination
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    density="compact"
                    loading={isLoading}
                />
            </Box>
        </Grid>
    )
}

export default SellerRegistrationMetrics;