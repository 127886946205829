import {BASE_TITLE, DELIMITER, PageProps} from "src/components/App";
import {useSelector} from "react-redux";
import {CombineReducerState} from "src/combineReducers";
import React, {useEffect, useState} from "react";
import {
    AWB_INVOICE_FAILURE_RESPONSE,
    AWB_INVOICE_STATS,
    DEFAULT_PAGE_SIZE
} from "src/constants/FormResponse";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {PageTitle} from "src/components/display/PageTitle";
import DateRangeForm from "src/components/forms/DateRangeForm";
import {routes} from "src/data/Routes";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {OOCInvoiceStatus} from "src/views/invoices/TableColumns";
import {Box} from "@mui/system";

const getAwbInvoiceStats = (data: any) => {
    if (data.totalInvoices > 0) {
        return {
            totalInvoicesPercentage: ((data.totalInvoices/data.totalInvoices)*100).toString()+'%',
            transferCompletePercentage: ((data.transferComplete/data.totalInvoices)*100).toString()+'%',
            failedInvoicesPercentage: ((data.failedInvoices.length/data.totalInvoices)*100).toString()+'%',
            assemblyCompletePercentage: ((data.assemblyComplete/data.totalInvoices)*100).toString()+'%',
            creationFailedPercentage: ((data.creationFailed/data.totalInvoices)*100).toString()+'%',
            aggregationFailedPercentage: ((data.aggregationFailed/data.totalInvoices)*100).toString()+'%',
            transferFailedPercentage: ((data.transferFailed/data.totalInvoices)*100).toString()+'%'
        };
    }
    return AWB_INVOICE_STATS;
}

const AWBInvoiceFailure = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);
    const [response, setResponse] = useState(AWB_INVOICE_FAILURE_RESPONSE);
    const [awbInvoiceStats, setAwbInvoiceStats] = useState(AWB_INVOICE_STATS);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);

    useEffect(() => {
        setAwbInvoiceStats(getAwbInvoiceStats(response.data));
    }, [response]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">

            <PageTitle title="AWB Invoice Failure" />

            <DateRangeForm url={baseUrl + routes.invoices.awbInvoiceFailure} handler={setResponse} />

            <Box style={{ width: "100%", marginTop: "32px" }}>

                <TableContainer component={Paper} sx={{ marginBottom: "16px" }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={100}>
                                    <Typography variant="subtitle1">AWB Invoice Failure Details</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total number of AWB Invoices</TableCell>
                                <TableCell>Number of successful transferred AWB Invoices</TableCell>
                                <TableCell>Number of failed AWB Invoices</TableCell>
                                <TableCell>Failures due to Retried Payments	</TableCell>
                                <TableCell>Failure due to Tracking ID Related Issues</TableCell>
                                <TableCell>Failure due to Amazon System Issues</TableCell>
                                <TableCell>Failure due to DB System Issues</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{response.data.totalInvoices}</TableCell>
                                <TableCell>{response.data.transferComplete}</TableCell>
                                <TableCell>{response.data.failedInvoices.length}</TableCell>
                                <TableCell>{response.data.assemblyComplete}</TableCell>
                                <TableCell>{response.data.creationFailed}</TableCell>
                                <TableCell>{response.data.aggregationFailed}</TableCell>
                                <TableCell>{response.data.transferFailed}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{awbInvoiceStats.totalInvoicesPercentage}</TableCell>
                                <TableCell>{awbInvoiceStats.transferCompletePercentage}</TableCell>
                                <TableCell>{awbInvoiceStats.failedInvoicesPercentage}</TableCell>
                                <TableCell>{awbInvoiceStats.assemblyCompletePercentage}</TableCell>
                                <TableCell>{awbInvoiceStats.creationFailedPercentage}</TableCell>
                                <TableCell>{awbInvoiceStats.aggregationFailedPercentage}</TableCell>
                                <TableCell>{awbInvoiceStats.transferFailedPercentage}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <DataGrid
                    rows={response.data.failedInvoices}
                    getRowId={row => row.shipmentId}
                    columns={OOCInvoiceStatus}
                    pageSize={pageSize}
                    pagination
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    density="compact"
                    loading={isLoading}
                />
            </Box>

        </Grid>
    )
}

export default AWBInvoiceFailure;