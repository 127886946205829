import { BRASS_ALLOWED_STATUS, BRASS_AUTH_DENIED_MSG } from "src/constants/BrassConstants"
import { Errors } from "src/constants/Errors"
import { routes } from "src/data/Routes"
import apiRequest from "src/utils/ApiRequest"
import { isBrassAuthAction } from "../HomeActions"
import { ISP_REPORTS_PATH_FOR_BRASS_AUTH, RESPONSE_STATUS } from "./ISPReportsConstants"

const getISPReport = async (url:string, payload:any) => {
    try {
      const res = await apiRequest(url, payload)
      const data = await res.json()
      if(data.status === RESPONSE_STATUS.success){
        data['merchantId'] = payload.merchantId
        data['reportType'] = payload.reportType
      }else{
        data['merchantId'] = payload.merchantId
        data['reportType'] = payload.reportType
        data['reports'] = []
      }
      return data
  } catch (err:any) {
      return err.message
    }
}

export const callBrassAPI = async (baseUrl:string, dispatch:Function) => {
    const brassAuthUrl: string = baseUrl + routes.brassAuth;
    try {
        const res = await apiRequest(brassAuthUrl, ISP_REPORTS_PATH_FOR_BRASS_AUTH)
        const brass_data = await res.json()
        if (brass_data.authStatus === BRASS_ALLOWED_STATUS) {
            return dispatch(isBrassAuthAction({ isBrassAuthorized: true, error: null }))
        } else {
            const error = brass_data.message?brass_data.message:BRASS_AUTH_DENIED_MSG;
            return dispatch(isBrassAuthAction({ isBrassAuthorized: false, error: error}))
        }
    } catch (err: any) {
        const errorMessage = err instanceof Error ? err.message : Errors.Message.SERVER_ERROR;
        return dispatch(isBrassAuthAction({ isBrassAuthorized: false, error: errorMessage }))
    }
}

export const fetchISPReports = (props: ISPReportInterface ):any =>{
    return new Promise((resolve, reject) => {
       let {ISPReportPayload, reportType, url} = props
        let ispReportsPromise:Array<Object> = [];
        ISPReportPayload?.merchantId.split(',').forEach((merchantId:string) => {
        reportType.forEach((reportType) => {
            let payload = {
                ...ISPReportPayload,
                merchantId:merchantId,
                reportType:reportType
              };     
              ispReportsPromise.push(getISPReport(url, payload));
        });
    });
    Promise.all(ispReportsPromise)
        .then(ispReports => {
            return resolve(ispReports);
        })
        .catch(err => {
            return reject(err);
        });
    });
}

interface ISPReportInterface {
    ISPReportPayload?:any
    reportType:Array<string>
    url:string
}