import React from "react";
import {
  Typography,
  Modal,
  Box,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";
import { FlagValueDetailsTable } from "./details/TableColumns";
import { DataGrid } from "@mui/x-data-grid";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SellerFlagModal = (props: SellerFlagModal) => {
  const {
    row,
    modalData,
    errorMessage,
    selectSellerId,
    open,
    handleClose,
    flagValue,
  } = props;

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style }}>
            <IconButton sx={{ float: "right" }} onClick={handleClose}>
              <Close />
            </IconButton>
            <Box sx={{ display: "flex", gap: 4, p: "10px 0" }}>
              <Typography variant="h6">Seller ID: {selectSellerId}</Typography>
              <Typography variant="h6"> Paris Flag: {flagValue}</Typography>
              <Typography variant="h6">
                {" "}
                Value: {modalData[selectSellerId]["flagValue"]}{" "}
              </Typography>
            </Box>
            <Typography sx={{ p: "10px 0" }} variant="subtitle1">
              Current Flag Status
            </Typography>
            {errorMessage ? (
              <Alert severity="error">
                {" "}
                <AlertTitle>Error</AlertTitle> {errorMessage}{" "}
              </Alert>
            ) : null}
            <DataGrid
              rows={row}
              getRowId={(row) => row.updatedOn}
              columns={FlagValueDetailsTable}
              pageSize={2}
              rowsPerPageOptions={[2]}
              autoHeight
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

interface SellerFlagModal {
  row: any;
  modalData: any;
  errorMessage: string;
  selectSellerId: string;
  open: boolean;
  handleClose: any;
  flagValue: string;
}

export default SellerFlagModal;