import { Grid, Paper } from "@mui/material";
import { Container } from "@mui/system";
import React from "react"
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import SubscriberForm from "src/components/forms/SubscriberForm";
import {routes} from "src/data/Routes";
import {useSelector} from "react-redux";
import {CombineReducerState} from "src/combineReducers";

const CrescentSubscriber = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <PageTitle title="Crescent Subscriber" />
            <Container maxWidth="lg">
                <Paper elevation={4} sx={{ padding: 2 }}>
                    <SubscriberForm url={baseUrl + routes.utilities.crescentSubscriber} subscriberTo={"Manager Login"} subscriber={"Subscriber Login"} buttonName={"Subscribe"} />
                </Paper>
            </Container>
        </Grid>
    )
}

export default CrescentSubscriber;