import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react"
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import AnkanaForm from "src/views/utilities/ankana-tag/AnkanaForm";
import AnkanaFAQ from "src/views/utilities/ankana-tag/AnkanaFAQ";
import Collapsible from "src/components/shared/Collapsible";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { columns } from "./TableColumns";
import { DEFAULT_PAGE_SIZE } from "src/constants/FormResponse";


const PerformAnkanaAllowList = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const handleSubmit = () => { }

    return (
        <Grid container alignItems="center" justifyContent="center">
            <PageTitle title="Ankana Tag" />
            <Grid container >
                <Grid item md={4}>
                    <AnkanaForm url="" handler={handleSubmit} />
                </Grid>
                <Grid item md={8}>
                    <Collapsible title="Tool Description and Usage" open={false}>
                        <AnkanaFAQ />
                    </Collapsible>
                    <Box style={{ width: "100%", marginTop: "32px" }}>
                        <Accordion expanded={true}>
                            <AccordionSummary>
                                <Typography variant="h6">Update Summary</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ textAlign: 'left' }}>
                                <Typography>Successful ankana allowlist = 2</Typography>
                                <Typography>Failed ankana allowlist = 0</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box style={{ width: "100%", marginTop: "32px" }}>
                        <DataGrid
                            rows={[]}
                            getRowId={row => row.disburseTaskId}
                            columns={columns}
                            pageSize={pageSize}
                            pagination
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            autoHeight
                            components={{ Toolbar: GridToolbar }}
                            checkboxSelection
                            density="compact"
                        />
                    </Box>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default PerformAnkanaAllowList;