import {
  DEFAULT_ERROR_MESSAGE,
  ERROR_FETCH_ISP_DETAILS,
  MAX_LIMIT,
  OOCFBA_SELLER_RESPONSE_INTERFACE,
  OPERATION_FETCH_ISP_DETAILS,
} from "src/constants/ISPOnboarding";
import apiRequest from "src/utils/ApiRequest";
import { SetISPDetailsAction } from "../HomeActions";
import { Errors } from "src/constants/Errors";
import React from "react";

export const getISPDetails = async (
  url: string,
  dispatch: React.Dispatch<React.SetStateAction<any>>,
  setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  setApiErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  let payload = { operation: OPERATION_FETCH_ISP_DETAILS };

  try {
    const response = await apiRequest(url, payload);
    const data = await response.json();
    if (response.ok) {
      dispatch(SetISPDetailsAction(data.ispDetailsList));
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    console.error({ err });
    setApiErrorMessage(ERROR_FETCH_ISP_DETAILS);
    setApiError(true);
  }
};

export const addOrUpdateISPDetails = async (
  url: string,
  getPayload: any,
  dispatch: React.Dispatch<React.SetStateAction<any>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setApiMessage: React.Dispatch<React.SetStateAction<string>>,
  setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  setApiErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  clearForm:any
) => {
  try {
    const res = await apiRequest(url, getPayload());
    const ispDetails = await res.json();
    if (res.ok) {
      setApiMessage(ispDetails.successMessage);
      setOpen(true);
      getISPDetails(url, dispatch, setApiError, setApiErrorMessage);
      clearForm()
    } else {
      throw new Error(ispDetails?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  } catch (err: any) {
    const errorMessage =
      err instanceof Error ? err.message : Errors.Message.SERVER_ERROR;
    setApiErrorMessage(errorMessage);
    setApiError(true)
  }
};

const splitIntoBatches = (array: string[], chunkSize: number) => {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize)
  );
};

export const saveOOCFBASellerDetails = async (
  url: string,
  payload: any,
  setSellerResponse: React.Dispatch<React.SetStateAction<any>>,
  setApiError: React.Dispatch<React.SetStateAction<boolean>>,
  setApiErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  sellersResponse: OOCFBA_SELLER_RESPONSE_INTERFACE,
) => {
  const sellerBatches = splitIntoBatches(payload.merchantIds, MAX_LIMIT);

  try {
    const promises = sellerBatches.map(async (batch) => {
      const batchPayload = { ...payload, merchantIds: batch };
      const response = await apiRequest(url, batchPayload);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data?.message ?? DEFAULT_ERROR_MESSAGE);
      }
      return data;
    });

    const results: OOCFBA_SELLER_RESPONSE_INTERFACE[] = await Promise.all(promises);

    let totalSuccessCount = 0;

    results.forEach(result => {
      if (result) {
        totalSuccessCount += result.successCount;
        sellersResponse.failedSellerIds.push(...result.failedSellerIds);
        sellersResponse.existingSellerIds.push(...result.existingSellerIds);
        sellersResponse.sellersWithoutISPSubscription.push(...result.sellersWithoutISPSubscription);

        Object.assign(sellersResponse.sellerGSTINsNotInISP, result.sellerGSTINsNotInISP);
      }
    });

    sellersResponse.successCount = totalSuccessCount
    sellersResponse.failedCount = payload.merchantIds.length - totalSuccessCount
    setSellerResponse(sellersResponse);

  } catch (err) {
    const errorMessage =
      err instanceof Error ? err.message : Errors.Message.SERVER_ERROR;
    setApiErrorMessage(errorMessage);
    setApiError(true);
  }
};
