import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import ReportGenerationStatus from "src/components/shared/ReportGenerationStatus";
import { routes } from "src/data/Routes";
import apiRequest from "src/utils/ApiRequest";
import { isLoadingAction } from "src/views/HomeActions";
import { ISPREPORTSTYLE } from "../ISPReportStyle";
import {
  ERROR_MESSAGE,
  ISPReportDataType,
  REFERENCE_ID,
  RESPONSE_STATUS,
} from "../ISPReportsConstants";
import { callBrassAPI } from "../ISPReportHelper";
import { handleApiError } from "src/utils/CommonHelper";

export const errorHandlingForISPReports = (
  renderReports: any,
  reports: any,
  type: string
) => {
  if (reports.status == RESPONSE_STATUS.failure) {
    return <Alert severity="error">{reports.message}</Alert>;
  } else if (reports.status == RESPONSE_STATUS.invalidInput) {
    return <Alert severity="error">{reports.message}</Alert>;
  } else if (reports.status == RESPONSE_STATUS.noReportsFound) {
    return <Alert severity="error"> {ERROR_MESSAGE.NoReportsFound} </Alert>;
  } else if (reports.status == "SUCCESS") {
    return (
      <div>
        {type == "settlement"
          ? renderReports(reports.reports)
          : renderReports(reports)}
      </div>
    );
  } else {
    console.error(reports);
    return null;
  }
};

const InventoryShipmentReports = (props: PageProps) => {
  const PAGE_TITLE = props.title;
  document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
  const dispatch = useDispatch();

  const {isLoading, brassResponse} = useSelector(
    (state: CombineReducerState) => state.home
  );
  const baseUrl = useSelector(
    (state: CombineReducerState) => state.navbar.baseUrl
  );
  const url = baseUrl + routes.isp.reports.generateInventoryShipment;

  const urlParams = new URLSearchParams(window.location.search);

  const [ispReportResponse, setIspReportResponse] =
    useState<ISPReportDataType>();
  const [referenceId, setReferenceId] = useState<string>("");
  const [apiError, setApiError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<String>("");
  const [responseLoaded, setResponseLoaded] = useState<boolean>(false);

  useEffect(() => {
    const referenceId = urlParams.get(REFERENCE_ID);
    if (referenceId) {
      setReferenceId(referenceId);
      handleSubmit(referenceId);
    }
  }, []);

  useEffect(() => {
    setResponseLoaded(true);
  }, [ispReportResponse]);

  const renderReportDownloadLink = (data: ISPReportDataType) => {
    if (
      data.reportGenerationStatus ==
      RESPONSE_STATUS.completeReportGenerationStatus
    ) {
      return (
        <div style={ISPREPORTSTYLE.downloadButton}>
          <a href={data.urlForDownload} target="_blank" className="btn btn-link btn-lg" >
            Download Report
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderDocumentId = (data: ISPReportDataType) => {
    if (
      data.reportGenerationStatus ==
      RESPONSE_STATUS.completeReportGenerationStatus
    ) {
      return <Box> <Typography>Document Id - {data.documentId}</Typography>  </Box>;
    } else {
      return null;
    }
  };

  const renderReports = (data: ISPReportDataType) => {
    return (
      <Grid>
        <Card sx={{ boxShadow: 3 }}>
          <div style={ISPREPORTSTYLE.bgColor}>
            <div style={ISPREPORTSTYLE.reportsContainer}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Box>
                  <Typography>Reference Id - {data.referenceId}</Typography>
                </Box>
                {renderDocumentId(data)}
              </Grid>
              <Box>
                <ReportGenerationStatus status={data.reportGenerationStatus} />
              </Box>
            </div>
            {renderReportDownloadLink(data)}
          </div>
        </Card>
      </Grid>
    );
  };

  const renderResult = () => {
    if (ispReportResponse) {
      if (ispReportResponse.error) {
        return <Alert severity="error">{ispReportResponse.message}</Alert>;
      }
      return errorHandlingForISPReports(renderReports, ispReportResponse, "");
    } else {
      return null;
    }
  };

  const handleSubmit = async(referenceId : string) => {
    dispatch(isLoadingAction(true));
    clearErrors();

    let currentBrassResponse;
    if (!brassResponse.isBrassAuthorized) {
        currentBrassResponse = await callBrassAPI(baseUrl, dispatch);
    }
    if (brassResponse.isBrassAuthorized || currentBrassResponse.payload.isBrassAuthorized) {
        try {
            let payload = {
                referenceId: referenceId
            };
            const response = await apiRequest(url, payload);
            if (response.ok) {
                setApiError(false);
                const jsonData = await response.json();
                setIspReportResponse(jsonData);
            } else {
                throw new Error(ERROR_MESSAGE.ServerError);
            }
        } catch (err : any) {
            handleApiError(err.message, setApiError, setApiErrorMessage);
        } finally {
            dispatch(isLoadingAction(false));
        }
    } else {
        handleApiError(brassResponse.error, setApiError, setApiErrorMessage, dispatch, isLoadingAction);
    }
};

  const clearErrors = () => {
    setApiError(false);
    setApiErrorMessage("");
  };

  let isDisabled = (referenceId ? false : true) || isLoading;

  return (
    <Grid container maxWidth="xl" sx={{ padding: 0 }}>
      <PageTitle title="Inventory Shipment Reports" />
      <Grid item sm={4}>
        <Paper elevation={4} sx={{ paddingY: 2 }}>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <FormControl size="small" fullWidth>
              <TextField
                id="referenceId"
                type="search"
                name="referenceId"
                size="small"
                value={referenceId}
                label="Reference ID"
                placeholder="Enter Reference Id"
                onChange={(e) => setReferenceId(e.target.value)}
                autoFocus
                required
              />
            </FormControl>
          </Box>
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={isDisabled}
              onClick={() => handleSubmit(referenceId)}
              fullWidth
            >
              Submit
            </Button>
          </Box>
          {isLoading && <LinearProgress />}
        </Paper>
      </Grid>
      <Grid item sm={8}>
        <Box sx={{ paddingX: 3 }}>
          {!apiError && responseLoaded ? (
            <div> {renderResult()}</div>
          ) : (
            <Alert severity="error">{apiErrorMessage}</Alert>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default InventoryShipmentReports;
