import { Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react"
import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
import { PageTitle } from "src/components/display/PageTitle";
import MerchantIdsInput from "src/components/input/MerchantIdsInput";


const EncryptDecrypt = (props: PageProps) => {
	const PAGE_TITLE = props.title;
	document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;

	return (
		<Grid container direction="column" alignItems="center" justifyContent="center">
			<PageTitle title="Encrypt/Decrypt Amazon IDs" />

			<Container maxWidth="lg">

				<Paper elevation={4} sx={{ padding: 2 }}>
					<Typography variant="h6" sx={{ mb: 1 }}>Amazon IDs (like Marketplace IDs or Merchant IDs)</Typography>

					<MerchantIdsInput
						label="IDs"
						inputId="sellerIds"
						inputPlaceholder="Comma / Line separated Ids"
						rows="12"
					/>
				</Paper>
			</Container>
		</Grid>
	)
}

export default EncryptDecrypt;