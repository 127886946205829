import { BrassResponse, HomeCardData} from "./HomeState";
import { ISP_DETAILS_INTERFACE } from "src/constants/ISPOnboarding";

export const IS_LOADING = 'IS_LOADING';
export const CARD_DATA = 'CARD_DATA';
export const BRASS_RESPONSE = 'BRASS_RESPONSE'
export const ISP_DETAILS = 'ISP_DETAILS';

export type HomeActions = IsLoadingAction | SetCardDataAction | BrassResponseAction | SetISPDetailsAction;

export interface IsLoadingAction {
    type: typeof IS_LOADING;
    payload: boolean;
}

export interface BrassResponseAction {
    type: typeof BRASS_RESPONSE;
    payload: BrassResponse;
}

export interface SetCardDataAction {
    type: typeof CARD_DATA;
    payload: Record<string, HomeCardData>;
}

export interface SetISPDetailsAction {
    type: typeof ISP_DETAILS;
    payload: ISP_DETAILS_INTERFACE[];
}

export const isLoadingAction = (isLoading: boolean): IsLoadingAction => {
    return {
        type: IS_LOADING,
        payload: isLoading
    };
};

export const setCardDataAction = (cardData: Record<string, HomeCardData>): SetCardDataAction => {
    return {
        type: CARD_DATA,
        payload: cardData
    };
};

export const isBrassAuthAction = (brassResponse: BrassResponse): BrassResponseAction => {
    return {
        type: BRASS_RESPONSE,
        payload: brassResponse
    };
};

export const SetISPDetailsAction = (ispDetails: ISP_DETAILS_INTERFACE[]): SetISPDetailsAction => {
    return {
        type: ISP_DETAILS,
        payload: ispDetails
    };
};