import {
    Box,
    FormControl,
    Grid,
    Paper,
    Alert,
    LinearProgress,
    Button,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { BASE_TITLE, DELIMITER, PageProps } from "src/components/App";
  import { PageTitle } from "src/components/display/PageTitle";
  import MerchantIdsInput from "src/components/input/MerchantIdsInput";
  import { routes } from "src/data/Routes";
  import apiRequest from "src/utils/ApiRequest";
  import { isLoadingAction } from "src/views/HomeActions";
  import { CombineReducerState } from "src/combineReducers";
  import { useDispatch, useSelector } from "react-redux";
  import {
    MERCHANT_IDS,
    REMOVE_ADD_ON_SUBSCRIPTION_REQUEST,
    REMOVE_SUBSCRIPTION_TABLE_PROPERTIES,
    DEFAULT_PAGE_SIZE,
    ERROR_MESSAGE
  } from "./SubscriptionConstant";
  import { encryptMerchantId } from "src/helpers/MerchantIdsHelper";
  import { Container } from "@mui/system";
  import { provideInferredData } from "./AddOnSubscriptionHelper";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  
  const RemoveAddOnSubscription = (props: PageProps) => {
    const PAGE_TITLE = props.title;
    document.title = PAGE_TITLE + DELIMITER + BASE_TITLE;
    const [createSubscriptionPayload, setCreateSubscriptionPayload] = useState(REMOVE_ADD_ON_SUBSCRIPTION_REQUEST);  
    const [merchantIds, setMerchantIds] = useState<Array<string>>([]);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [data, setData] = useState<Array<string>>([]);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const url =  baseUrl + routes.utilities.removeAddOnSubscription;
    const [apiError, setApiError] = useState<boolean>(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
    const [responseLoaded, setResponseLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isLoading = useSelector((state: CombineReducerState) => state.home.isLoading);

    const handleOnChange = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let key: string = e.target.name;
        let value: string = e.target.value;
        setCreateSubscriptionPayload({...createSubscriptionPayload, [key]: value });
    };  
  
    useEffect(() => {
      const encryptIds: Array<string> = merchantIds.map((id:any) => {
        let encryptedId = isNaN(id);
        return encryptedId ? id : encryptMerchantId(id);
      });

      setCreateSubscriptionPayload({ ...createSubscriptionPayload, [MERCHANT_IDS]: encryptIds.join(",")});
      
    }, [merchantIds]);
  
    useEffect(() => {
      setResponseLoaded(true);
    }, [data]);

    const isInputEmpty = () => {
      return !Object.keys(createSubscriptionPayload).every((k) => createSubscriptionPayload[k]);
    }

    const handleSubmit = async () => {
      clearErrors();
      dispatch(isLoadingAction(true));
      try {
        const response = await apiRequest(url, createSubscriptionPayload);
  
        if (response.ok) {
          setApiError(false);
          const jsonData = await response.json();
          const { merchantIds, addOnSubscriptionTier, addOnSubscriptionType } = createSubscriptionPayload;
          const filterData =  provideInferredData(jsonData, merchantIds, addOnSubscriptionType, addOnSubscriptionTier);
          if (filterData.isSucceded) {
            setData(filterData.tableRows);
          } else {
            setApiError(true);
            setApiErrorMessage(filterData.errorMessage);
          }
        } else {
            throw new Error(ERROR_MESSAGE.ServerError);
        }
      } catch (err) {
        setApiError(true);
        setApiErrorMessage(ERROR_MESSAGE.ServerError);
      }
      dispatch(isLoadingAction(false));
    }
  
    const clearErrors = () => {
      setApiError(false);
      setApiErrorMessage("");
    }
  
    return (
      <Grid container alignItems="center" justifyContent="center">
        <PageTitle title="Remove Add-on Subscription" />
        <Grid container>
          <Grid item sm={4}>
            <Container maxWidth="sm">
              <Paper elevation={4} sx={{ paddingY: 2 }}>
                <Box sx={{ paddingX: 3, paddingY: 1 }}>
                  <FormControl size="small" fullWidth>
                    <TextField
                      id="marketplace"
                      type="search"
                      name="marketplace"
                      size="small"
                      label="Marketplace"
                      placeholder="Enter MarketplaceId"
                      disabled={isLoading}
                      onChange={handleOnChange}
                      autoFocus
                      required
                    />
                  </FormControl>
                </Box>
                <Box sx={{ paddingX: 3, paddingY: 1 }}>
                  <FormControl size="small" fullWidth>
                    <TextField
                      id="domainName"
                      type="search"
                      name="domainName"
                      size="small"
                      label="Domain Name"
                      placeholder="Enter Domain Name"
                      disabled={isLoading}
                      onChange={handleOnChange}
                      autoFocus
                      required
                    />
                  </FormControl>
                </Box>
                <Box sx={{ paddingX: 3, paddingY: 1 }}>
                  <FormControl size="small" fullWidth>
                    <TextField
                      id="addOnSubscriptionType"
                      type="search"
                      name="addOnSubscriptionType"
                      size="small"
                      label="Add-on Subscription Type"
                      placeholder="Enter Add-on Subscription Type"
                      disabled={isLoading}
                      onChange={handleOnChange}
                      autoFocus
                      required
                    />
                  </FormControl>
                </Box>
                <Box sx={{ paddingX: 3, paddingY: 1 }}>
                  <FormControl size="small" fullWidth>
                    <TextField
                      id="baseSubscriptionType"
                      type="search"
                      name="baseSubscriptionType"
                      size="small"
                      label="Base Subscription Type"
                      placeholder="Enter Base Subscription Type"
                      disabled={isLoading}
                      onChange={handleOnChange}
                      autoFocus
                      required
                    />
                  </FormControl>
                </Box> 
                <Box sx={{ paddingX: 0, paddingY: 1 }}>
                    <MerchantIdsInput
                      label="Merchant IDs"
                      inputId="merchantId"
                      inputPlaceholder="Comma / Line separated Merchant Ids"
                      rows="10"
                      value={merchantIds}
                      handler={setMerchantIds}
                    />
                </Box>
                <Box sx={{ paddingX: 3, paddingY: 1 }}>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={isInputEmpty() || isLoading}
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Box>
                {isLoading && <LinearProgress />}
              </Paper>
            </Container>
          </Grid>
          <Grid item sm={8}>
            <Box style={{ width: "100%", marginTop: "16px" }}>
              {!apiError && responseLoaded && (
                <DataGrid
                  rows={data}
                  getRowId={row => row.merchantId}
                  columns={REMOVE_SUBSCRIPTION_TABLE_PROPERTIES}
                  pageSize={pageSize}
                  pagination
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  autoHeight
                  components={{ Toolbar: GridToolbar }}
                  checkboxSelection
                  density="compact"
                  loading={isLoading}
                />
              )}
              {apiError && <Alert severity="error">{apiErrorMessage}</Alert>}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  
  export default RemoveAddOnSubscription;